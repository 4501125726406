<script setup lang="ts">
import { Modal } from "jenesius-vue-modal"
import IconEdit from "@/components/icons/IconEdit.vue"

const CONFIRM_YES = true
const CONFIRM_NO = false

const props = defineProps({
  width: {
    type: String,
    default: '584px'
  },
  title: {
    type: String,
    default: "Confirm the action",
  },
  message: {
    type: String,
    default: "",
  },
  buttonConfirmationText: {
    type: String,
    default: "Update",
  },
  classCSSButtonConfirmation: {
    type: String,
    default: "primary-button",
  },
  buttonRejectionText: {
    type: String,
    default: "Cancel",
  },
  classCSSButtonRejection: {
    type: String,
    default: "cancel-button",
  },
  customButtonText: {
    type: String,
    default: null,
  },
  customButtonClassCSS: {
    type: String,
    default: "cancel-button",
  },
  customStatus: {
    type: String,
    default: null,
  },
  iconComponent: {
    type: Object,
    default: IconEdit,
  },
})

const emit = defineEmits([Modal.EVENT_PROMPT])

const confirmHandler = (result: boolean | string) => {
  emit(Modal.EVENT_PROMPT, result)
}

</script>

<template>
  <div
    :style="{'max-width': props.width}"
    class="custom-pop-up__content custom-alert__content"
  >
    <div class="custom-alert__body">
      <div
        v-if="iconComponent"
        class="custom-alert__icon"
      >
        <component :is="iconComponent" />
      </div>
      <div class="custom-alert__message">{{ props.title }}</div>

      <div class="custom-alert__check-block">
        <div class="custom-alert__check-text">
          {{ props.message }}
        </div>
      </div>
    </div>

    <div class="custom-alert__footer">
      <button
        :class="classCSSButtonRejection"
        class="custom-alert__confirm primary-button"
        type="button"
        @click="confirmHandler(CONFIRM_NO)"
      >
        {{ props.buttonRejectionText }}
      </button>

      <button
        v-if="customButtonText"
        :class="customButtonClassCSS"
        class="custom-alert__confirm primary-button"
        type="button"
        @click="confirmHandler(props.customStatus)"
      >
        {{ props.customButtonText }}
      </button>

      <button
        :class="classCSSButtonConfirmation"
        class="custom-alert__cansel primary-button"
        type="button"
        @click="confirmHandler(CONFIRM_YES)"
      >
        {{ props.buttonConfirmationText }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.cancel-button {
  background: #963C51;
  color: white;
}
</style>
