<script setup lang="ts">

import { ref } from "vue"

const ACCEPTED_EXTENSIONS = ".csv"
const invalidFileFormatMessage = "Invalid file format. Supported only .csv"
const invalidFileSizeMessage = "File size exceeds 2 MiB. Maximum of 10 MiB"

const props = defineProps<{
  label: string
  name?: string | null,
}>()

const emit = defineEmits(["upload", "uploadError"])
const error = defineModel<string>('error')

const fileName = ref("")


const uploadFile = async (event: Event) => {
  fileName.value = ""
  error.value = ""
  const target = event.target as HTMLInputElement
  const files: FileList | null = target.files;

  if (!files) {
    emit("uploadError", error.value)
    return
  }

  const type = files[0]?.type ?? null
  const size = (files[0]?.size ?? 0) / 1024 / 1024

  if (type !== 'text/csv') {
    error.value = invalidFileFormatMessage
    emit("uploadError", error.value)
    return
  }

  if (size > 10) {
    error.value = invalidFileSizeMessage
    emit("uploadError", error.value)
    return
  }

  fileName.value = files[0].name

  emit("upload", event)
}

</script>

<template>
  <div class="form-control">
    <h2>{{ props.label }}</h2>

    <input
      :id="name ?? 'uploadFile'"
      :name="name ?? 'uploadFile'"
      :accept=ACCEPTED_EXTENSIONS
      type="file"
      style="display: none;"
      @change="uploadFile"
    />

    <div class="custom-input custom-input--small input-file__name">
      {{ fileName }}
    </div>

    <label
      class="custom-alert__confirm primary-button"
      for="uploadFile"
    >
      <span>Upload</span>
    </label>
  </div>
  <div class="error">
    {{ error }}
  </div>
</template>

<style scoped>
.form-control {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;

  .input-file__name {
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40%;
  }
}

.error {
  color: #963C51;
  text-align: right;
  font-weight: bold;
  margin-bottom: 2rem;
}
</style>