<script setup lang="ts">
import { computed, reactive, ref, toRaw } from "vue"
import { SignUpInvitation } from "@/types/user.ts"
import { required } from "@vuelidate/validators"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { container } from "@/services/container.ts"
import { useRoute, useRouter } from "vue-router"
import FieldInput from "@/components/auth/FieldInput.vue"
import FieldPassword from "@/components/auth/FieldPassword.vue"
import FieldButton from "@/components/auth/FieldButton.vue"
import { passwordMatchErrorText } from "@/types/auth.ts"
import AgreementCheckbox from "@/components/auth/AgreementCheckbox.vue"
import { ResponseApiMessage } from "@/types/api.ts"

const router = useRouter()
const route = useRoute()
const wsId = route.query.ws_id as string
const invitationId = route.query.invitation_id as string

const signup = reactive<SignUpInvitation>({
  ws_id: wsId,
  first_name: "",
  last_name: "",
  password: "",
  confirm_password: "",
  invitation_id: invitationId,
})

const $externalResults = ref<ServerErrors>({
  __all__: [],
})

const sameAsPassword = () => {
  return {
    $message: passwordMatchErrorText,
    $validator: () => signup.password === signup.confirm_password,
  }
}

const rules = {
  first_name: {
    required,
    $autoDirty: true,
  },
  last_name: {
    required,
    $autoDirty: true,
  },
  password: {
    required,
    $autoDirty: true,
  },
  confirm_password: {
    sameAs: sameAsPassword(),
    required,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, signup, { $externalResults })

const submit = async () => {
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    return
  }

  const result = await container.userRepository.signupInvitation(toRaw(signup))

  if (result?.status === "ok") {
    const resultData = (<{ email: string }>result.data).email
    sessionStorage.setItem("signup_email", resultData)
    await router.push({ name: "users-email-confirmation-sent" })
  } else {
    const resultData = (<ResponseApiMessage>result.data).message as ServerErrors
    for (let key in resultData) {
      $externalResults.value['__all__'] = (resultData[key] as [])
        ?.map((error: { message: string, code: string }) => error.message)
    }
  }
}

const isValid = computed(() => {
  return validator.value.$errors.length > 0
})

</script>

<template>
  <form
    class="login__form"
    action=""
    method="post"
    @submit.prevent="submit"
  >
    <h1 class="login__title main-title">Sign Up</h1>

    <div
      v-for="(error, index) of $externalResults?.__all__"
      :key="index"
      class="validated-input__error-text"
      style="display:block;"
    >
      <div class="error-msg">
        {{ error }}
      </div>
    </div>

    <field-input
      v-model="signup.first_name"
      :errors="validator.first_name"
      name="first_name"
      placeholder="Name"
      :tabindex="1"
    />

    <field-input
      v-model="signup.last_name"
      :errors="validator.last_name"
      name="last_name"
      placeholder="Last name"
      :tabindex="2"
    />

    <field-password
      v-model="signup.password"
      :errors="validator.password.$errors"
      name="password"
      placeholder="Password"
      :tabindex="3"
    />

    <field-password
      v-model="signup.confirm_password"
      :errors="validator.confirm_password.$errors"
      name="confirm_password"
      placeholder="Confirm password"
      :tabindex="4"
    />

    <agreement-checkbox :tabindex="5" />

    <field-button
      :is-valid="isValid"
      title="Join Collextr"
      :tabindex="6"
    />

  </form>
</template>
