<script setup lang="ts">

const props = defineProps<{
  name: string
  type?: string
  placeholder?: string
  errors?: any,
  tabindex?: number
}>()

const modelValue = defineModel<string>()

</script>

<template>
  <div class="login__field">
    <div
      :class="{'error': props.errors.$errors.length }"
      class="validated-input"
    >
      <input
        :id="name"
        v-model="modelValue"
        :type="props.type"
        :name="props.name"
        :placeholder="props.placeholder"
        class="custom-input"
        required
        :tabindex="props.tabindex"
      >

      <div
        v-for="error of props.errors.$errors"
        :key="error.$uid"
        class="validated-input__error-text"
      >
        <div class="error-msg">
          {{ error.$message?.message ?? error.$message }}
        </div>
      </div>
    </div>
  </div>
</template>
