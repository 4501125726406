<script setup lang="ts">
import moment from "moment"
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import BaseNav from "@/components/BaseNav.vue"
import { BillingActions } from "@/types/billing"
import { container } from "@/services/container"
import CTable from "@/components/elements/CTable.vue"

const route = useRoute()
const ws = route.params.ws as string
const month = route.params.month as string
const skillId = route.params.skillId as string

const data = ref<{ skill_name: string; items: BillingActions[] } | null>(null)

const dateMonth = moment(month).format("YYYY MMMM")

const navItems = computed(() => {
  return [
    {
      link: `/ws/${ws}/settings`,
      label: "Manage Workspace",
    },
    {
      link: `/ws/${ws}/billing`,
      label: "Usage & Billing",
    },
    {
      link: `/ws/${ws}/billing/${month}`,
      label: dateMonth,
    },
    {
      link: null,
      label: data.value?.skill_name
    }
  ]
})

const columns = [
  {key: 'action_name', label: 'Action name'},
  {key: 'action_type', label: 'Action type'},
  {key: 'actions', label: 'Jobs'},
  {key: 'cost', label: 'Cost, USD'},
]

onMounted(async () => {
  data.value = await container.billingRepository.billingActions(month, skillId)
})
</script>

<template>
  <div v-if="data">
    <base-nav :items="navItems" icon="settings"/>
    <h2>{{ data.skill_name }}</h2>

    <c-table
      :columns="columns"
      :items="data.items"/>
  </div>
</template>
