<script setup lang="ts">
import { container } from "@/services/container"
import { onMounted, ref } from "vue"
import BaseNav from "@/components/BaseNav.vue"
import { useRoute } from "vue-router"
import { Paginator } from "@/types/api.ts"
import PagePaginator from "@/components/PagePaginator.vue"
import IconHubSpot from "@/components/icons/IconHubSpot.vue"
import { SkillIntegration } from "@/types/integration.ts"
import { SettingsIntegrationHubSpot, LigamentIntegrationHubSpotCRMWithMate } from "@/types/hubspot.ts"
import IconSave from "@/components/icons/IconSave.vue"

const route = useRoute()
const ws = route.params.ws as string
const integrationId = route.params.integrationId as string
let page = Number(route.query.page || 1)
let perPage = Number(route.query.per_page || 20)

const templateSettings = () => {
  return {
    id_integration: null,
    settings: {
      crm: {
        mapping: [],
      },
    },
  }
}

const data = ref<Paginator<SkillIntegration> | null>(null)
const settings = ref<SettingsIntegrationHubSpot>(templateSettings())
const navItems = [
  {
    link: `/ws/${ws}/settings`,
    label: "Manage Workspaces",
  },
  {
    link: `/ws/${ws}/integrations/settings`,
    label: "Integrations",
  },
  {
    link: null,
    label: "HubSpot",
  },
]

const reset = () => {
  mappingLigamentsToSkills()
}

onMounted(async () => {
  data.value = await container.skillRepository.list(ws, page, perPage)
  settings.value = await container.integrationRepository.getSettings(ws, integrationId)

  mappingLigamentsToSkills()
})

const submitForm = async () => {
  if (!settings.value?.settings?.crm?.mapping) {
    settings.value = templateSettings()
  }

  mappingSkillsToLigaments()
  filteredEmptyLigaments()

  await container.integrationRepository.setSettings(
    {
      ...settings.value,
    },
    ws,
    integrationId,
  )
}

const mappingLigamentsToSkills = () => {
  data.value?.items?.forEach(skill => {
    skill.availableCompany = false
    skill.availableContact = false

    settings.value?.settings?.crm?.mapping?.forEach((ligament: LigamentIntegrationHubSpotCRMWithMate) => {
      if (ligament.skill_id === skill.id) {
        skill.availableCompany = ligament.availableCompany
        skill.availableContact = ligament.availableContact
      }
    })
  })
}

const mappingSkillsToLigaments = () => {
  data.value?.items?.forEach(skill => {
    settings.value?.settings?.crm?.mapping
      ?.forEach((ligament: LigamentIntegrationHubSpotCRMWithMate) => {
        if (ligament.skill_id === skill.id) {
          ligament.availableCompany = skill.availableCompany
          ligament.availableContact = skill.availableContact
          skill.processed = true
        }
      })

    const isSkillProcessedOrNotNeeded =
      (skill.processed === true || (!skill.availableCompany && !skill.availableContact))

    if (isSkillProcessedOrNotNeeded) {
      return
    }

    settings.value.settings?.crm?.mapping.push({
      skill_id: skill.id,
      availableCompany: skill.availableCompany,
      availableContact: skill.availableContact,
    })
  })
}

const filteredEmptyLigaments = () => {
  settings.value.settings.crm.mapping = settings?.value?.settings?.crm?.mapping
    .filter((ligament: LigamentIntegrationHubSpotCRMWithMate) => {
      return !(!ligament.availableCompany && !ligament.availableContact)
    })
}

</script>

<template>
  <main>
    <base-nav :items="navItems" icon="settings"/>
    <div class="title">
      <icon-hub-spot />
      <h2>CONFIGURE HUBSPOT INTEGRATION</h2>
    </div>

    <div class="description">
      Select the skills that will be available in HubSpot in the CRM Card.
    </div>

    <div v-if="data">
      <table class="runs-table__table">
        <thead>
        <tr>
          <th>Skill</th>
          <th>Available for Company</th>
          <th>Available for Contact</th>
        </tr>
        </thead>
        <tr v-for="skill of data.items" :key="skill.id">
          <td>
            <div>
              {{ skill.name }}
            </div>
          </td>
          <td>
            <div>
              <input
                v-model="skill.availableCompany"
                type="checkbox"
              >
            </div>
          </td>
          <td>
            <div>
              <input
                v-model="skill.availableContact"
                type="checkbox"
              >
            </div>
          </td>
        </tr>
      </table>

      <page-paginator
        :page="page"
        :per-page="perPage"
        :page-range="data.page_range"
        :count="data.count"
      />
    </div>

    <div class="settings-block__controls indent">
      <button
        class="settings-block__control-btn secondary-button"
        @click="reset"
      >
        <span>Cancel</span>
      </button>
      <button
        class="settings-block__control-btn primary-button"
        @click="submitForm"
      >
        <icon-save />
        <span>Save</span>
      </button>
    </div>
  </main>
</template>

<style scoped>
.indent {
  margin: 1rem 0;
}

.title {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  h2 {
    margin: 0 2rem;
  }
}

.description {
  margin: 1rem 0;
}

table {
  th, td {
    text-align: center;
  }
}
</style>