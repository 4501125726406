<script setup lang="ts">

const props = defineProps<{
  hasStructuredData?: boolean
  isStructuredData?: boolean
  hasPublic?: boolean
  isPublic?: boolean
}>()

</script>

<template>
  <div class="wrap-labels">
    <template
      v-if="props.hasPublic"
    >
      <div
        v-if="props.isPublic"
        class="status-label status-label--completed"
      >
        Public
      </div>
      <div
        v-else
        class="status-label status-label--error"
      >
        Private
      </div>
    </template>

    <template
      v-if="props.hasStructuredData"
    >
      <div
        v-if="props.isStructuredData"
        class="status-label status-label--completed"
      >
        Structured Data
      </div>
      <div
        v-else
        class="status-label status-label--in-progress"
      >
        Unstructured Data
      </div>
    </template>
  </div>
</template>

<style scoped>
.wrap-labels {
  display: flex;
  gap: 10px;
}
</style>