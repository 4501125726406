<script setup lang="ts">
const props = defineProps<{
  label: string
  items: { value: string, label: string }[]
  modelValue: string | null
  placeholder?: string
  required?: boolean
  error?: string
}>()

const emit = defineEmits(["update:modelValue"])

const updateValue = (value: any) => {
  console.log("value", value)
  emit("update:modelValue", value)
}

</script>

<template>
  <div class="action-param__row">
    <div class="action-param__col action-param__col--left">
      <div class="action-param__col-name text-style">{{ props.label }}</div>
    </div>
    <div class="action-param__col action-param__col--right">
      <div class="action-param__input-wrap validated-input">
        <select
          :value="props.modelValue"
          :required="props.required"
          @change="updateValue(($event.target as HTMLInputElement).value)"
        >
          <option v-for="item of props.items" :key="item.value" :value="item.value">
            {{ item.label }}
          </option>
        </select>
        <div class="validated-input__error-text">{{ props.error ?? "Error text" }}</div>
      </div>
    </div>
  </div>
</template>
