<script setup lang="ts">

import IconArrowRight from "@/components/icons/IconArrowRight.vue"

const props = defineProps<{
  title: string,
  isValid?: boolean
  tabindex?: number,
  disabled?: boolean
}>()

</script>

<template>
  <button
    :disabled="isValid || props.disabled"
    class="login__submit primary-button primary-button--wide primary-button--big"
    type="submit"
    :tabindex="props.tabindex"
  >
    <span>{{ props.title }}</span>
    <icon-arrow-right />
  </button>
</template>
