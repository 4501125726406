<script setup lang="ts">
import { container } from "@/services/container"
import { computed, onMounted, ref } from "vue"
import SettingsControls from "@/components/settings/SettingsControls.vue"
import SettingsField from "@/components/settings/SettingsField.vue"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { useRoute, useRouter } from "vue-router"
import { Workspace, workspaceDefault } from "@/types/workspace.ts"
import useUser from "@/compositions/useUser.ts"
import BaseNav from "@/components/BaseNav.vue"

const route = useRoute()
const router = useRouter()
const ws = computed(() => route.params.ws.toString())
const { initUser } = useUser()

const navItems = [
  {
    link: `/ws/${ws.value}/settings`,
    label: "Manage Workspaces",
  },
  {
    link: null,
    label: "General",
  }
]

const disabledSaveBtn = ref(false)

const workspace = ref<Workspace & { currentName: string }>({
  ...workspaceDefault, currentName: ''
})

const $externalResults = ref<ServerErrors>({
  __all__: [],
})

const rules = {
  name: {
    required,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, workspace, { $externalResults })

onMounted(async () => {
  const result = await container.workspaceRepository.get(ws.value)
  workspace.value = {
    ...result,
    currentName: result.name,
  }
})

const cancel = () => {
  workspace.value.name = workspace.value.currentName
}

const save = async () => {
  disabledSaveBtn.value = true
  $externalResults.value = {}
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    disabledSaveBtn.value = false
    return
  }

  try {
    const result = await container.workspaceRepository.updateGeneralData(
    ws.value,
      {
        "name":
        workspace.value.name,
      },
    )

    if (result.status === "ok") {
      await initUser()

      await router.push({ name: "workspace-settings-general", params: { ws: result.data.schema_name } })
      workspace.value.currentName = workspace.value.name
    } else {
      $externalResults.value = result?.error ?? {}
    }
  } finally {
    disabledSaveBtn.value = false
  }
  
}

</script>

<template>
  <div class="skills__body">
    <base-nav :items="navItems" icon="settings"/>

    <h2>General Data</h2>

    <div>
      This influences how you see your profile, and also is used in your Email signatures, if they go out from Collextr
      platform.
    </div>

    <br>

    <div
      v-for="(error, index) of $externalResults?.__all__"
      :key="`ws-settings-error-${index}`"
      class="validated-input__error-text"
      style="display:block;"
    >
      <div class="error-msg">
        {{ error }}
      </div>
    </div>

    <div class="skills__block settings-block">
      <settings-field
        v-model="workspace.name"
        :errors="validator.name"
        :label="{title: 'Workspace Name', description: 'Workspace name'}"
      />
      <settings-controls
        :disabled="disabledSaveBtn"
        @cancel="cancel"
        @save="save"
      />
    </div>
  </div>
</template>
