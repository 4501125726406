import { ActionTypeUi, SkillAction } from "@/types/actions.ts"
import ActionCrawlerWebPage from "@/components/actions/companyCrawler/ActionCrawlerWebPage.vue"

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "CrawlerAction",
    description: "",
    copilot: false,
    module: "workflow.actions.crawler",
    params: {},
    ui: {},
    fields_source: [
      {
        name: "",
        type: "site_url",
      },
    ],
    fields_target: [
      {
        name: "",
        type: "string",
      },
    ],
  }
}

export class CrawlerWebPage implements ActionTypeUi {
  static typeName = "CrawlerAction"
  static component = ActionCrawlerWebPage
  public actionData = newSkillAction()
  public availableFields = []

  newActionData() {
    this.actionData = newSkillAction()
  }

  handlerAfterLoad() {}

  handlerBeforeSave() {
    if (this.actionData.fields_source[0].name === "") {
      throw new Error("Crawler Action field are not selected")
    }
  }

  handlerEvent() {}
}
