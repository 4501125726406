<script setup lang="ts">

import CPlan from "@/components/billing/CPlan.vue"
import useProjectSettings from "@/compositions/useProjectSettings.ts"
import { computed } from "vue"

const { productStripeSettings } = useProjectSettings()

const plans = computed(() => {
  return productStripeSettings.value?.plans
})

</script>

<template>
  <div
    v-if="productStripeSettings"
    class="plan-wrap"
  >
    <c-plan
      v-for="(plan, index) in plans"
      :key="`plan-${index}-${plan.name}`"
      :plan="plan"
      :index="index"
      :current-settings="productStripeSettings"
    />
  </div>
</template>

<style scoped>
.plan-wrap {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  align-content: center;
  gap: 40px;
}
</style>
