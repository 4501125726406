import { Workspace, WorkspaceStatusSubscription } from "@/types/workspace.ts"

const STATUS = {
  FREE: "Free",
  CANCELED: "Canceled",
  PAYMENT_FAILED: "Payment Failed",
  PAST_DUE: "Past Due",
  ACTIVE: "Active",
  DISABLED: "Disabled",
}

export const isNotFreeUser = (currentWorkspace?: Workspace) => {
  if (!currentWorkspace) {
    return false
  }

  const free = currentWorkspace.status !== WorkspaceStatusSubscription.CHURNED_CUSTOMER
    && currentWorkspace.status !== WorkspaceStatusSubscription.FREE_PLAN
    && currentWorkspace.status !== WorkspaceStatusSubscription.GRACE_PERIOD_OVER

  if (activeButPaymentFailed(currentWorkspace)) {
    return true
  }

  if (activeButUnsubscribed(currentWorkspace)) {
    return true
  }

  return free
}

export const activeButUnsubscribed = (currentWorkspace?: Workspace) => {
  if (!currentWorkspace) {
    return false
  }

  return currentWorkspace.status === WorkspaceStatusSubscription.CHURNED_CUSTOMER
    && currentWorkspace.is_allowed_period_now
}

export const activeButPaymentFailed = (currentWorkspace?: Workspace) => {
  if (!currentWorkspace) {
    return false
  }

  return currentWorkspace.status === WorkspaceStatusSubscription.GRACE_PERIOD
}

export const getStatus = (currentWorkspace?: Workspace) => {
  if (!currentWorkspace) {
    return false
  }

  const status = currentWorkspace.status ?? STATUS.FREE
  if (status === WorkspaceStatusSubscription.GRACE_PERIOD) {
    let prefix = ""
    if (currentWorkspace.date_became_grace) {
      prefix = " (Payment Failed)"
    }

    return STATUS.PAST_DUE + prefix
  } else if (status === WorkspaceStatusSubscription.GRACE_PERIOD_OVER) {
    return STATUS.DISABLED
  } else {
    return STATUS.ACTIVE
  }
}

export const getPlan = (currentWorkspace?: Workspace) => {
  if (!currentWorkspace?.plan) {
    return STATUS.FREE
  }

  if (currentWorkspace.status === WorkspaceStatusSubscription.CHURNED_CUSTOMER) {
    return `${currentWorkspace?.plan} (${STATUS.CANCELED})`
  }

  if (currentWorkspace.status === WorkspaceStatusSubscription.GRACE_PERIOD_OVER) {
    return `${currentWorkspace?.plan} (${STATUS.CANCELED})`
  }

  if (currentWorkspace.status === WorkspaceStatusSubscription.GRACE_PERIOD) {
    return `${currentWorkspace?.plan} (${STATUS.PAYMENT_FAILED})`
  }

  return currentWorkspace.plan
}