<script setup lang="ts">
import { useRoute } from "vue-router"
import { computed, ref } from "vue"
import IconLogo from "@/components/icons/IconLogo.vue"
import IconSkills from "@/components/icons/IconSkills.vue"
import useUser from "@/compositions/useUser.ts"
import IconSettings from "@/components/icons/IconSettings.vue"
import TwoLevelMenu from "@/components/TwoLevelMenu.vue"
import { ROUTE_NAMES } from "@/router/names.ts"
import SubItem from "@/components/menu/SubItem.vue"

const { firstWorkspace, isAdminCurrentWorkspace } = useUser()
const route = useRoute()
const subMenu = ref(false)

const getWsName = computed(() => {
  let wsRoute: string | null = route.params?.ws?.toString() ?? null
  let wsFirstWorkspace: string | null = firstWorkspace.value?.schema_name ?? null
  const wsDefault = "workspace"

  if (!wsRoute || wsRoute === "") {
    wsRoute = null
  }

  if (!wsFirstWorkspace || wsFirstWorkspace === "") {
    wsFirstWorkspace = null
  }

  return wsRoute ?? wsFirstWorkspace ?? wsDefault
})

const ROUTE_PATHS = {
  SKILLS: ROUTE_NAMES.SKILLS,
  WORKSPACE_SETTINGS: getWsName.value + "/settings",
  WORKSPACE_SETTINGS_INTEGRATIONS: getWsName.value + "/integrations/settings",
  WORKSPACE_USAGE_BILLING: getWsName.value + "/billing",
  WORKSPACE_USERS: getWsName.value + "/users",
}

const isMenuSkills = computed(() => {
  return Boolean(~route.fullPath.indexOf(ROUTE_PATHS.SKILLS))
})

const isMenuManageWorkspace = () => {
  const isSubItemActive =
    isSubMenuManageWorkspaceSettings.value ||
    isSubMenuManageWorkspaceIntegrations.value ||
    isSubMenuManageWorkspaceUsage.value ||
    isSubMenuManageWorkspaceUsers.value

  if (isSubItemActive) {
    subMenu.value = true
    return true
  }

  return false
}

const isSubMenuManageWorkspaceSettings = computed(() => {
  return Boolean(~route.fullPath.indexOf(ROUTE_PATHS.WORKSPACE_SETTINGS))
})

const isSubMenuManageWorkspaceIntegrations = computed(() => {
  return Boolean(
    ~route.fullPath.indexOf(ROUTE_PATHS.WORKSPACE_SETTINGS_INTEGRATIONS),
  )
})

const isSubMenuManageWorkspaceUsage = computed(() => {
  return Boolean(~route.fullPath.indexOf(ROUTE_PATHS.WORKSPACE_USAGE_BILLING))
})

const isSubMenuManageWorkspaceUsers = computed(() => {
  return Boolean(~route.fullPath.indexOf(ROUTE_PATHS.WORKSPACE_USERS))
})

const activatedSubMenu = () => {
  subMenu.value = !subMenu.value
}
</script>

<template>
  <aside class="main__menu">
    <div class="main__logo">
      <div>
        <icon-logo />
      </div>
    </div>
    <menu class="main__menu-inner main-menu">
      <ul class="main-menu__list">
        <li class="main-menu__item">
          <router-link
            :class="{ active: isMenuSkills }"
            :to="{ name: ROUTE_NAMES.SKILLS, params: { ws: getWsName } }"
            class="main-menu__button"
          >
            <icon-skills />
            <span>Skills</span>
          </router-link>
        </li>

        <li class="main-menu__item">
          <ul class="two-level-menu__list">
            <li :class="{ active: subMenu }" class="two-level-menu__item">
              <button
                :class="{ active: isMenuManageWorkspace() }"
                class="main-menu__button"
                @click="activatedSubMenu"
              >
                <icon-settings width="16" height="16" />
                <span>Manage Workspace</span>
              </button>
              <div class="two-level-menu__inner">
                <ul class="two-level-menu__sub-list">
                  <sub-item
                    v-if="isAdminCurrentWorkspace"
                    :is-active="isSubMenuManageWorkspaceSettings"
                    :route-name="ROUTE_NAMES.WORKSPACE_SETTINGS_GENERAL"
                    :route-params="{ ws: getWsName }"
                    title="General"
                  />

                  <sub-item
                    :is-active="isSubMenuManageWorkspaceIntegrations"
                    :route-name="ROUTE_NAMES.WORKSPACE_SETTINGS_INTEGRATIONS"
                    :route-params="{ ws: getWsName }"
                    title="Integrations"
                  />

                  <sub-item
                    v-if="isAdminCurrentWorkspace"
                    :is-active="isSubMenuManageWorkspaceUsers"
                    :route-name="ROUTE_NAMES.WORKSPACE_USERS"
                    :route-params="{ ws: getWsName }"
                    title="Users"
                  />

                  <sub-item
                    :is-active="isSubMenuManageWorkspaceUsage"
                    :route-name="ROUTE_NAMES.WORKSPACE_USAGE_BILLING"
                    :route-params="{ ws: getWsName }"
                    title="Usage & Billing"
                  />
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </menu>

    <div class="main__menu-footer">
      <two-level-menu />
    </div>
  </aside>
</template>
