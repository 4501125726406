<script setup lang="ts">

const props = defineProps<{ status: string | null }>()

</script>


<template>
  <div
    v-if="props.status === 'CREATED'"
    class="skills__status status-label status-label--unpublish"
  >
    Queued
  </div>
  <div
    v-else-if="props.status === 'RUNNING'"
    class="skills__status status-label status-label--in-progress"
  >
    In progress
  </div>
  <div
    v-else-if="props.status === 'FAULTED'"
    class="skills__status status-label status-label--error"
  >
    Error
  </div>
  <div
    v-else-if="props.status === 'WAITING'"
    class="skills__status status-label status-label--waiting"
  >
    Waiting for user
  </div>
  <div
    v-else-if="props.status === 'STOPPED'"
    class="skills__status status-label status-label--waiting"
  >
    Paused
  </div>
  <div
    v-else-if="props.status === 'COMPLETE'"
    class="skills__status status-label status-label--completed"
  >
    Completed
  </div>
  <div
    v-else-if="props.status === 'CREATED'"
    class="skills__status status-label status-label--unpublish"
  >
    Queued
  </div>
  <div v-else class="skills__status status-label status-label--completed">
    Published
  </div>
</template>
