<script setup lang="ts">
import { onMounted, ref } from "vue"
import { openModal } from "jenesius-vue-modal"
import { Price } from "@/types/price"
import { container } from "@/services/container"
import BaseNav from "@/components/BaseNav.vue"
import PriceCreateModal from "@/components/modals/PriceCreateModal.vue"
import CButton from "@/components/elements/CButton.vue"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import { userStore } from "@/store/user"

const store = userStore()


const data = ref<Price[] | null>(null)

const navItems = [
  {
    link: null,
    label: "Manage Pricing",
  },
]

const fetchPrices = async () => {
  if (!store.getUserProfile.admin) {
    //TODO: remove hard reload
    window.location.href = "/"
  }
  
  data.value = await container.priceRepository.prices()
}

const create = async () => {
  const price = {
    id: 0,
    action_type: "",
    unit: "",
    price: 0,
    effective_date: "",
    effective_until: "",
  }
  const modal = await openModal(PriceCreateModal, { price: price })
  modal.on("confirm", async (price: Price) => {
    await container.priceRepository.create(price)
    await fetchPrices()
  })
}

const edit = async (price: Price) => {
  const modal = await openModal(PriceCreateModal, { price: price })
  modal.on("confirm", async (price: Price) => {
    await container.priceRepository.edit(price)
    await fetchPrices()
  })
}

const deletePrice = async (actionType: string, priceId: number) => {
  const title = `Delete the price «${actionType}»?`
  const modal = await openModal(DeleteModal, { title: title })
  modal.on("confirm", async () => {
    await container.priceRepository.delete(priceId)
    await fetchPrices()
  })
}

onMounted(async () => {
  await fetchPrices()
})
</script>

<template>
  <base-nav :items="navItems" icon="settings"/>
  <h2>Manage Pricing</h2>

  <div class="price-buttons">
    <button class="secondary-button" @click="create">+ Add Action Type</button>
  </div>
  
  <table class="runs-table__table">
    <thead>
      <tr>
        <th>Action type</th>
        <th>Unit</th>
        <th>Price, USD</th>
        <th>Effective date</th>
        <th>Effective until</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tr v-for="price in data" :key="price.id">
      <td>{{ price.action_type }}</td>
      <td>{{ price.unit }}</td>
      <td>{{ price.price }}</td>
      <td>{{ price.effective_date }}</td>
      <td>{{ price.effective_until }}</td>
      <td>
        <c-button icon="settings" @click="edit(price)"/>
      </td>
      <td>
        <c-button icon="delete" @click="deletePrice(price.action_type, price.id)"/>
      </td>
    </tr>
  </table>
</template>

<style>
  .price-buttons {
    text-align: right;
    margin-bottom: 10px;
  }
</style>