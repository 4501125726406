<script setup lang="ts">

const props = withDefaults(defineProps<{
  width?: string,
  height?: string,
}>(), {
  width: "143",
  height: "22",
})

</script>

<template>
  <svg :width="props.width"
       :height="props.height"
       :viewBox='`0 0 ${props.width} ${props.height}`'
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M143.5 21.1383H138.334L134.326 15.349H134.089H129.903V21.1383H125.093V0.356201H134.089C139.64 0.356201 143.114 3.23601 143.114 7.89713C143.114 11.0144 141.541 13.3005 138.839 14.4583L143.5 21.1383ZM138.245 7.89713C138.245 5.6111 136.761 4.27511 133.821 4.27511H129.903V11.5192H133.821C136.761 11.5192 138.245 10.1535 138.245 7.89713Z"
      fill="white" />
    <path d="M111.045 21.1383V4.27511H104.395V0.356201H122.505V4.27511H115.855V21.1383H111.045Z" fill="white" />
    <path
      d="M103.146 21.1383H97.5938L92.5764 13.9239L87.6481 21.1383H82.126L89.8154 10.5691L82.5119 0.356201H87.9747L92.7545 7.09554L97.4454 0.356201H102.641L95.3968 10.391L103.146 21.1383Z"
      fill="white" />
    <path
      d="M69.5659 17.2788H80.8773V21.1383H64.786V0.356201H80.4913V4.21573H69.5659V8.72842H79.2147V12.4692H69.5659V17.2788Z"
      fill="white" />
    <path d="M56.3618 21.4947V0H61.1714V17.4414V21.4947H56.3618Z" fill="url(#paint0_linear_418_146)" />
    <path d="M47.9377 21.4947V0H52.7473V17.4414V21.4947H47.9377Z" fill="url(#paint1_linear_418_146)" />
    <path
      d="M32.9522 21.4946C26.391 21.4946 21.5814 16.9226 21.5814 10.7473C21.5814 4.57206 26.391 0 32.9522 0C39.4837 0 44.323 4.54237 44.323 10.7473C44.323 16.9523 39.4837 21.4946 32.9522 21.4946ZM32.9522 17.3976C36.6633 17.3976 39.454 14.6959 39.454 10.7473C39.454 6.79872 36.6633 4.09704 32.9522 4.09704C29.2411 4.09704 26.4504 6.79872 26.4504 10.7473C26.4504 14.6959 29.2411 17.3976 32.9522 17.3976Z"
      fill="white" />
    <path
      d="M11.752 21.4946C5.30957 21.4946 0.5 17.0116 0.5 10.7473C0.5 4.483 5.30957 0 11.7817 0C15.374 0 18.3726 1.3063 20.3321 3.6814L17.2444 6.53152C15.8491 4.92833 14.0974 4.09704 12.0192 4.09704C8.13 4.09704 5.36895 6.8284 5.36895 10.7473C5.36895 14.6662 8.13 17.3976 12.0192 17.3976C14.0974 17.3976 15.8491 16.5663 17.2444 14.9334L20.3321 17.7835C18.3726 20.1883 15.374 21.4946 11.752 21.4946Z"
      fill="white" />
    <defs>
      <linearGradient id="paint0_linear_418_146" x1="58.7666" y1="0" x2="58.7666" y2="21.4947"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#752BEF" />
        <stop offset="1" stop-color="#3084E9" />
      </linearGradient>
      <linearGradient id="paint1_linear_418_146" x1="50.3425" y1="0" x2="50.3425" y2="21.4947"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#752BEF" />
        <stop offset="1" stop-color="#3084E9" />
      </linearGradient>
    </defs>
  </svg>
</template>