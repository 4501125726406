<script setup lang="ts">
import { onMounted, onUnmounted, ref, toRaw, watch } from "vue"
import draggable from "vuedraggable"
import { notify } from "@kyvg/vue3-notification"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillNav from "@/components/skills/SkillNav.vue"
import useSkill from "@/compositions/useSkill.ts"
import IconSave from "@/components/icons/IconSave.vue"
import IconPlus from "@/components/icons/IconPlus.vue"
import IconEdit from "@/components/icons/IconEdit.vue"
import IconDelete from "@/components/icons/IconDelete.vue"
import IconTrigger from "@/components/icons/IconTrigger.vue"
import IconDragMove from "@/components/icons/IconDragMove.vue"
import { useRoute, useRouter } from "vue-router"
import { SkillAction } from "@/types/actions.ts"
import { Modal, openModal } from "jenesius-vue-modal"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import NewActionModal from "@/components/modals/NewActionModal.vue"
import { container } from "@/services/container.ts"
import { container as WidgetContainerModal } from "jenesius-vue-modal"
import { ROUTE_NAMES } from "@/router/names.ts"
import { useRequiresSaving } from "@/services/requires-saving.ts"

const { skill, initSkill } = useSkill()
const route = useRoute()
const router = useRouter()
const { savingChangesIsRequired, subscribeRequiresSaving, unsubscribeRequiresSaving } = useRequiresSaving(save)

const ws = route.params.ws as string
const skillId = route.params.skillId as string

const isModified = ref(false)
const actions = ref<SkillAction[]>([])

watch(isModified, () => {
  savingChangesIsRequired.value = !!isModified.value
})

const sort = () => {
  actions.value = actions.value.sort(
    (firstAction: SkillAction, secondAction: SkillAction) => {
      const positionFirstAction = firstAction?.position ?? 0
      const positionSecondAction = secondAction?.position ?? 0

      if (positionFirstAction > positionSecondAction) {
        return 1
      }

      if (positionFirstAction < positionSecondAction) {
        return -1
      }

      return 0
    },
  )
}

onMounted(async () => {
  await initSkill()
  subscribeRequiresSaving()
  const currentActionSkill = <SkillAction[]>(skill.value?.actions ?? [])
  const actionsRaw = toRaw(currentActionSkill)

  actions.value = structuredClone(actionsRaw)
  sort()
})

onUnmounted(() => {
  unsubscribeRequiresSaving()
})

const deleteAction = async (action: SkillAction, index: number) => {
  const title = `Delete the action «${action.title || action.name}»?`
  const modal: Modal = await openModal(DeleteModal, { title: title })

  modal.on("confirm", async () => {
    actions.value.splice(index, 1)
    sort()
    isModified.value = true
  })
}

const addAction = async () => {
  const modal: Modal = await openModal(NewActionModal, { title: "" })

  modal.on("confirm", async (params: { actionType: string }) => {
    await router.push({
      name: "action-create",
      params: { ws: ws, skillId: skillId },
      query: { actionType: params.actionType },
    })
  })
}

async function save() {
  actions.value.forEach((action, index) => {
    action.position = index
  })

  const actionsRaw = toRaw(actions.value)

  const result = await container.skillActionRepository.saveActions(
    ws,
    skillId,
    actionsRaw,
  )

  if (result.status === "ok") {
    isModified.value = false
    notify({ text: result.data.message.toString(), type: "success" })
  } else {
    const errorMessage = result.data.message as string[]
    for (let message of errorMessage) {
      notify({ text: message, type: "error", duration: 30000 })
    }
    throw new Error(result.status)
  }
}
</script>

<template>
  <main v-if="skill">
    <skill-nav :skill="skill" type="actions" />
    <skill-header :skill="skill" />
    <div class="skills__body">
      <skill-tabs :skill="skill" type="skill">
        <!--        <button-->
        <!--          class="skills__control-btn secondary-button"-->
        <!--          disabled-->
        <!--        >-->
        <!--          <icon-validate />-->
        <!--          <span>Validate</span>-->
        <!--        </button>-->
        <button
          :disabled="!isModified"
          class="skills__control-btn primary-button"
          id="skill-page-save-btn"
          @click="save"
        >
          <icon-save />
          <span>Save</span>
        </button>
      </skill-tabs>

      <div class="skills__block">
        <section class="skills__card-list">
          <draggable
            v-model="actions"
            item-key="id"
            @update="isModified = true">
            <template #item="{element, index}">
              <article
                class="skills__action-card action-card"
              >
                <div class="action-card__header">
                  <h3 class="action-card__title">
                    <span class="action-card__title-icon">
                      <icon-trigger v-if="index === 0" />
                      <icon-drag-move v-else />
                    </span>
                    <span>{{ element.type }}</span>
                  </h3>

                  <div class="action-card__controls">
                    <router-link
                      :to="{
                        name: ROUTE_NAMES.ACTION_EDIT,
                        params: {
                          ws: ws,
                          skillId: skillId,
                          actionId: element.id,
                        },
                      }"
                      class="action-card__control-btn icon-button"
                    >
                      <icon-edit />
                    </router-link>

                    <button
                      class="action-card__control-btn icon-button"
                      id="skill-page-delete-action-btn"
                      @click="deleteAction(element, index)"
                    >
                      <icon-delete />
                    </button>
                  </div>
                </div>

                <div class="action-card__body">
                  <div class="action-card__sub-title">
                    <span class="sub-title">
                      {{ element.title || element.name }}
                    </span>
                    <span v-if="element.copilot" class="action-card__label">
                      Co-pilot
                    </span>
                  </div>
                  <div class="action-card__text">
                    {{ element.description }}
                  </div>
                  <div class="action-card__error">
                    <!--  error detail -->
                  </div>
                </div>
              </article>
            </template>
          </draggable>
        </section>

        <button
          class="skills__add-to-list outline-button outline-button--wide"
          id="skill-page-add-action-btn"
          @click="addAction"
        >
          <icon-plus />
          <span>Add Action</span>
        </button>
      </div>
    </div>

    <widget-container-modal />
  </main>
</template>

<style scoped>
.error-message {
  margin: 20px 0;
}

.error-message div {
  margin: 10px 0;
}
</style>