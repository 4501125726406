<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted } from "vue"

const route = useRoute()

onMounted(async () => {

})

</script>

<template>
  <nav aria-label="Breadcrumb" class="breadcrumbs @@mixClass">
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item">
        <span class="breadcrumbs__link breadcrumbs__current">
          <svg width="24" height="24">
            <use xlink:href="#ic_settings"></use>
          </svg>
          USER SETTINGS
        </span>
      </li>
    </ul>
  </nav>

  <div class="skills__header @@modClass">
    <div class="skills__info">
      <div class="skills__title-box">
        <h2 class="skills__title">
          USER SETTINGS
        </h2>
      </div>
    </div>
  </div>

  <div class="skills__body">
    <div class="skills__tabs">
      <div class="tabs">
        <router-link
          :class="{'active': route.name === 'users-profile-settings-general'}"
          :to="{'name':'users-profile-settings-general'}"
          class="tabs__item"
        >
          General
        </router-link>
        <router-link
          :class="{'active': route.name === 'users-profile-settings-password'}"
          :to="{'name':'users-profile-settings-password'}"
          class="tabs__item"
        >
          Password
        </router-link>
      </div>
    </div>

    <router-view></router-view>
  </div>

</template>
