<script setup lang="ts">
import { useRoute } from "vue-router"
import { ref } from "vue"
import { openModal } from "jenesius-vue-modal"
import { Skill, SkillFormData } from "@/types/skill"
import BaseNav from "@/components/BaseNav.vue"
import SkillForm from "@/components/forms/SkillForm.vue"
import SkillTemplates from "@/components/modals/SkillTemplates.vue"
import CFormControls from "@/components/elements/CFormControls.vue"
import CButton from "@/components/elements/CButton.vue"
import { container } from "@/services/container"

const route = useRoute()

const ws = route.params.ws as string

const navItems = [
  {
    link: `/ws/${ws}/skills`,
    label: "Skill",
  },
  {
    link: null,
    label: "New",
  },
]

const skillNew = {
  name: "",
  description: "",
  fieldsInput: [],
}

const disabledCreateBtn = ref(false)

const updateTemplate = ref(false)

const skill = ref<SkillFormData>(skillNew)

const createSkill = async (evt: Event) => {
  evt.preventDefault()
  disabledCreateBtn.value = true
  try {
    const createdSkill = await container.skillRepository.create(ws, skill.value)
    window.location.href = `/ws/${ws}/skills/${createdSkill.id}`
  } catch {
    disabledCreateBtn.value = false
  }
}

const cancel = () => {
  window.location.href = `/ws/${ws}/skills`
}

const loadTemplate = async () => {
  const modal = await openModal(SkillTemplates)
  modal.on("confirm", async (skillTemplatePromise: Promise<Skill>) => {
    updateTemplate.value = true
    const skillTemplate = await skillTemplatePromise

    skill.value.name = skillTemplate.name
    skill.value.description = skillTemplate.description
    skill.value.actions = skillTemplate.actions
    if (skillTemplate.fields_input) {
      skill.value.fieldsInput = skillTemplate.fields_input
    }
    updateTemplate.value = false
  })
}
</script>

<template>
  <div>
    <base-nav :items="navItems" icon="mates" />
    <div class="skills__header skills__header--border">
      <div class="skills__info">
        <div class="skills__title-box">
          <h2 class="skills__title js-mate-name">New Skill</h2>
        </div>
      </div>
    </div>
    <div class="skills__body">
      <form class="skills__block settings-block" @submit="createSkill">
        <div class="tempalte-btn-block">
          <c-button id="btn-load-template" @click="loadTemplate"
            >Load from Tempalte</c-button
          >
        </div>

        <template v-if="!updateTemplate">
          <skill-form :skill="skill" />
        </template>
        <c-form-controls :disabled="disabledCreateBtn" @cancel="cancel" />
      </form>
    </div>
  </div>
</template>

<style scoped>
.tempalte-btn-block {
  text-align: right;
  margin-right: 25px;
}
</style>
