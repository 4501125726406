import { userStore } from "@/store/user"
import { container } from "@/services/container"
import { computed, ref } from "vue"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { useRoute } from "vue-router"


export default function useUser() {
  const store = userStore()
  const route = useRoute()

  const initUser = async () => {
    const profile = await container.userRepository.profile()
    store.$patch({
      userProfile: profile,
      isAuthorized: profile?.is_authorized ?? false,
    })
  }

  const user = computed(() => store.userProfile)

  const firstWorkspace = computed(() => {
    return store.userProfile?.workspaces[0]
  })

  const currentWorkspace = computed(() => {
    return store.userProfile?.workspaces.find(ws => ws?.schema_name === route.params?.ws)
  })

  const isAdminCurrentWorkspace = computed(() => {
    if (route.params?.ws) {
      return currentWorkspace.value?.created_by_id === user.value.id
    }

    return true
  })

  const $externalResults = ref<ServerErrors>({})
  const validator = useVuelidate({
      last_name: {
        required,
        $autoDirty: true,
      },
      first_name: {
        required,
        $autoDirty: true,
      },
    },
    user,
    { $externalResults },
  )


  const awaitInitUser = async (): Promise<void> => {
    return new Promise((resolve) => {
      if (user.value && user.value.id > 0) {
        resolve()
      } else {
        setTimeout(async () => {
          await awaitInitUser()
          resolve()
        }, 1000)
      }
    })
  }

  const interviewInitUser = async (maxCount = 5, time = 1000): Promise<void> => {
    let id: null | number = null
    let count = 1

    id = setInterval(async () => {
      if (count >= maxCount) {
        if (id) {
          clearTimeout(id)
        }
      }

      await initUser()
      count++
    }, time)
  }

  return {
    initUser,
    user,
    firstWorkspace,
    currentWorkspace,
    isAdminCurrentWorkspace,
    validator,
    $externalResults,
    awaitInitUser,
    interviewInitUser,
  }
}
