<script setup lang="ts">

import { ACTION_TYPES, SkillActionTemplate } from "@/types/actions.ts"
import { onMounted, ref } from "vue"
import { container } from "@/services/container.ts"
import { promptModal } from "jenesius-vue-modal"
import ActionTemplatesForm from "@/components/actions/templates/ActionTemplatesForm.vue"
import ConfirmModal from "@/components/modals/ConfirmModal.vue"
import { Paginator } from "@/types/api.ts"
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "@/utils/pagination.ts"
import ActionTemplate from "@/components/actions/templates/ActionTemplate.vue"
import { confirmPublicTemplate } from "@/components/actions/templates/global-template-action.ts"
import { GlobalTemplateAction } from "@/types/templates.ts"

const data = ref<Paginator<GlobalTemplateAction> | null>(null)
const emit = defineEmits(["confirm"])

onMounted(async () => {
  await loadData(
    "",
    DEFAULT_PAGE,
    DEFAULT_PER_PAGE,
  )
})

const loadData = async (
  searchText: string,
  page: number,
  perPage: number,
) => {
  const result = await container.globalTemplateActionRepository.list(
    ACTION_TYPES.OPEN_AI_ACTION,
    page,
    perPage,
    searchText,
  )

  data.value = result.data
}

const overwrite = async (action: SkillActionTemplate, template: GlobalTemplateAction) => {
  const templateName = action.name
  template.name = templateName

  const result = await promptModal(ConfirmModal, {
    title: `Are you sure to replace Global Action Template ${templateName}? `,
    buttonConfirmationText: "Yes",
    classCSSButtonRejection: "secondary-button",
    iconComponent: null,
  })

  if (!result) {
    return
  }

  const isPublic = await confirmPublicTemplate(templateName)

  if (isPublic === null) {
    return
  }

  emit("confirm", {
    template: template,
    isPublic: isPublic,
  })
}

</script>

<template>
  <action-templates-form
    :paginate="{
      count: data?.count,
      page_range: data?.page_range,
    }"
    @update-data="loadData"
  >
    <template v-slot:body>
      <div class="add-action-form__list template-list">
        <action-template
          v-for="(template, index) in data?.items"
          :key="`action-template-${index}-${template.id}`"
          :action="template.content"
          :template="template"
          :button-use="{
            text: 'Overwrite'
          }"
          @use-template="overwrite"
        />
      </div>
    </template>
  </action-templates-form>
</template>

<style scoped>
.template-list {
  flex: 1 1 auto;
  height: calc(100% + 2px * 2);
  margin: -2px -10px -2px -2px;
  overflow-y: auto;
}
</style>
