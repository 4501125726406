import { container } from "@/services/container"
import { useRoute } from "vue-router"
import { skillStore } from "@/store/skill"
import { computed, ref } from "vue"
import { FilterOperators, SkillActionField } from "@/types/actions"
import { FieldsHubSpot, TypeEntityHubSpot } from "@/types/hubspot"
import { SkillAvailableActions } from "@/types/skill"

export default function useSkill() {
  const route = useRoute()
  const store = skillStore()

  const skillId = route.params.skillId as string
  const ws = route.params.ws as string

  async function initSkill() {
    const skillData = await container.skillRepository.get(ws, skillId)

    store.$patch({
      skill: skillData,
    })
  }

  const availableFields = ref<SkillActionField[]>([])
  const operators = ref<FilterOperators | null>(null)
  const fieldsHubSpot = ref<FieldsHubSpot | null>(null)
  const actionTypes = ref<SkillAvailableActions[]>([])

  const fetchAvailableFields = async (actionId: string | null = null) => {
    const actionFields = await container.skillRepository.fields(ws, skillId)

    const skill_action_fields = actionFields.skill_action_fields.filter(
      (field) => field.id === actionId,
    )
    if (skill_action_fields.length > 0) {
      availableFields.value = skill_action_fields[0].input_fields
    }
  }

  const fetchOperators = async () => {
    operators.value = await container.skillActionRepository.filterOperators(ws)
  }

  const fetchFieldsHubSpot = async (type?: TypeEntityHubSpot) => {
    fieldsHubSpot.value = await container.skillActionRepository.fieldsHubSpot(
      ws,
      type,
    )
  }

  const fetchActionTypes = async () => {
    if (actionTypes.value.length === 0) {
      actionTypes.value = await container.skillActionRepository.listAvailableActions(ws)
    }
  }

  const skill = computed(() => store.skill)

  return {
    skill,
    availableFields,
    operators,
    fieldsHubSpot,
    initSkill,
    fetchAvailableFields,
    fetchOperators,
    fetchFieldsHubSpot,
    actionTypes,
    fetchActionTypes,
  }
}
