<script setup lang="ts">
import { onMounted } from "vue"
import { userStore } from "@/store/user"
import useUser from "@/compositions/useUser"
import { useRouter } from "vue-router"
import { ROUTE_NAMES } from "@/router/names"

const router = useRouter()
const store = userStore()
const { awaitInitUser } = useUser()

onMounted(async () => {
    await awaitInitUser()
    const ws = store.getUserProfile.workspaces[0].schema_name
    router.push({name: ROUTE_NAMES.SKILLS, params: {ws: ws}})
})
</script>

<template>
</template>