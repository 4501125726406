import { ActionTypeUi, SkillAction, SkillActionField } from "@/types/actions"
import ActionFormCreateNoteHubSpot from "@/components/actions/hubspot/ActionFormCreateNoteHubSpot.vue"
import { toRaw } from "vue"

const notSelectedRequiredFieldMessage = "HubSpot Create Note Action fields are not selected"

const newMateAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "HubSpotCreateNoteAction",
    description: "",
    copilot: false,
    module: "workflow.actions.hubspot_create_note",
    params: {
      record_field_contact: null,
      record_field_company: null,
      content_field_note: null,
    },
    ui: {
      record_field_contact: null,
      record_field_company: null,
      content_field_note: null,
    },
    fields_source: [],
    fields_target: [],
  }
}

export class HubspotCreateNote implements ActionTypeUi {
  public actionData: SkillAction = newMateAction()
  public availableFields: SkillActionField[] = []
  public static typeName = "HubSpotCreateNoteAction"
  public static component = ActionFormCreateNoteHubSpot

  public newActionData() {
    this.actionData = newMateAction()
  }

  public handlerAfterLoad() {
  }

  public handlerBeforeSave() {
    this.actionData.fields_source = []
    this.actionData.fields_target = []
    this.actionData.params.record_field_company = null
    this.actionData.params.record_field_contact = null

    const company = toRaw(this.actionData.ui.record_field_company)
    const contact = toRaw(this.actionData.ui.record_field_contact)

    const isCompanyNameEmpty = (company?.name ?? "") === ""
    const isContactNameEmpty = (contact?.name ?? "") === ""

    if (
      (isCompanyNameEmpty && isContactNameEmpty)
      || this.actionData.ui.content_field_note.name === ""
    ) {
      throw new Error(notSelectedRequiredFieldMessage)
    }

    const isCompanyNameNotEmpty = (company?.name ?? "") !== ""
    const isContactNameNotEmpty = (contact?.name ?? "") !== ""

    if (isCompanyNameNotEmpty) {
      this.actionData.params.record_field_company = company
      this.actionData.fields_source.push(company)
    }

    if (isContactNameNotEmpty) {
      this.actionData.params.record_field_contact = contact
      this.actionData.fields_source.push(contact)
    }

    this.actionData.params.content_field_note = this.actionData.ui.content_field_note
    this.actionData.fields_source.push(this.actionData.params.content_field_note)
  }

  public handlerEvent() {
  }
}
