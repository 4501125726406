<script setup lang="ts">

import { Ligament } from "@/types/hubspot.ts"

const props = defineProps<{
  attributes: any | Ligament[]
}>()

const emit = defineEmits(["delete", "add"])

</script>

<template>
  <div class="wrap-mapping">

    <div class="settings-block__row">
      <div class="settings-block__col settings-block__col--left">
        <div class="settings-block__attr sub-title">
          Attributes Mapping
        </div>
        <div class="settings-block__hint hint-text">
          Please select how the attributes will be mapped
        </div>
      </div>
    </div>

    <div
      v-for="(ligament, index) in props.attributes"
      :key="index"
      class="condition-settings__row"
    >
      <div class="condition-settings__inner">

        <slot
          :attribute="ligament"
          :index="index"
        />

      </div>

      <button
        v-if="index !== 0"
        style="min-width: 43px;"
        class="condition-settings__del-btn icon-button"
        type="button"
        @click="emit('delete', index)"
      >
        <svg width="24" height="24">
          <use xlink:href="#ic_delete"></use>
        </svg>
      </button>
      <div v-else class="plug"></div>
    </div>

    <div
      class="action-param__row action-param__row--pv0"
    >
      <button
        class="action-param__add-btn outline-button outline-button--wide"
        type="button"
        @click="emit('add')"
      >
        <svg width="16" height="16">
          <use xlink:href="#ic_plus"></use>
        </svg>
        <span>Add attribute</span>
      </button>
    </div>

  </div>
</template>

<style scoped>
.wrap-mapping {
  margin: 0 0 5rem 0
}

.plug {
  width: 43px;
  height: 43px;
}

.condition-settings__inner {
  gap: 20px
}
</style>