<script setup lang="ts">

import { RESPONSE_TYPE, SkillActionTemplate } from "@/types/actions.ts"
import { computed } from "vue"
import { useRoute } from "vue-router"
import ActionTemplateStatus from "@/components/actions/templates/ActionTemplateStatus.vue"
import { GlobalTemplateAction } from "@/types/templates.ts"

const route = useRoute()
const ws = route.params.ws as string

const emit = defineEmits(["useTemplate"])

const linkToSkill = (skillId: string | number | undefined) => {
  return `/ws/${ws}/skills/${skillId}`
}

const props = defineProps<{
  action: SkillActionTemplate
  template?: GlobalTemplateAction
  buttonUse?: {
    text?: string
  }
}>()

const useTemplate = (action: SkillActionTemplate) => {
  emit("useTemplate", action, props.template)
}

const isStructuredData = (responseType: string) => {
  return responseType !== RESPONSE_TYPE.TEXT
}

const getButtonUseText = computed(() => {
  return props?.buttonUse?.text ?? "Use"
})

</script>

<template>
  <div class="wrap">
    <div class="content">
      <div class="title">
        <action-template-status
          :is-structured-data="isStructuredData(props.action.ui?.response_type)"
          has-structured-data
        />

        <a
          v-if="props.action?.skill?.id"
          class="link"
          :href="linkToSkill(props.action?.skill?.id)"
          target="_blank"
        >
          {{ props.action.name }}
        </a>
        <span v-else>
          <b>{{ props.action.name }}</b>
        </span>

        <div
          v-if="props.action?.skill?.name"
          class="sub-title"
        >
          Skill: {{ props.action?.skill?.name }}
        </div>
      </div>

      <div class="description">
        {{ props.action.description }}
      </div>

      <div
        class="button"
      >
        <button
          class="primary-button"
          @click="useTemplate(props.action)"
        >
          {{ getButtonUseText }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  padding: 2px 10px 20px 2px;

  .content {
    display: flex;
    justify-content: space-between;
    cursor: default;
    padding: 16px;
    border-radius: 8px;
    transition: all .3s ease;
    border: 1px solid white;
    background: white;
    box-shadow: 0 0 2px 0 rgba(26, 32, 36, 0.16), 0 1px 2px 0 rgba(91, 104, 113, 0.16);

    &:hover {
      box-shadow: 0 0 5px 0 rgba(0, 24, 70, 0.16), 0 1px 2px 0 rgba(0, 24, 70, 0.16);
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      width: 100%;
      max-width: 200px;

      .link {
        font-weight: 600
      }

      .sub-title {
        text-transform: none;
        font-weight: inherit;
        font-size: 14px;
        color: #BEBDBF
      }
    }

    .description {
      max-width: 488px;
      font-size: 16px;
      color: black;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .button {
      margin-top: 8px;
    }
  }
}
</style>
