<script setup lang="ts">

const email = sessionStorage.getItem('signup_email');

</script>

<template>
  <div class="email__body">
    <div class="email__form">

      <h1 class="email__title main-title">The confirmation email has been sent!</h1>

      <div class="email__field">
        <div class="email__field__text" style="display: block; color: black">
          A confirmation email has been sent to your
          <a href="mailto:{{ email }}">
            {{ email }}
          </a> address.
          <br><br>
          Please check your email and click on the confirmation link to complete the registration.
          <br><br>
          If the email has not arrived, check the spam folder.
        </div>
      </div>

      <div class="email__to-login">
        <span>Already have an account? </span>
        <router-link
          to="/users/login"
          class="login__login-link custom-link"
          data-tab-id="mates-runs"
        >
          Log In
        </router-link>
      </div>

    </div>
  </div>
</template>
