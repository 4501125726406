<script setup lang="ts">
const props = defineProps<{
  label: string
  placeholder?: string | null
  modelValue?: string | null
  name: string
  id?: string
  error?: string,
  required?: boolean
}>()

const emit = defineEmits(["update:modelValue"])
</script>

<template>
  <div class="action-param__row">
    <div class="action-param__col action-param__col--left">
      <div class="action-param__col-name text-style">{{ props.label }}</div>
    </div>
    <div class="action-param__col action-param__col--right">
      <div class="action-param__input-wrap validated-input">
        <input class="custom-input custom-input--small"
               :id="props.id"
               :name="props.name"
               :placeholder="props?.placeholder ?? ''"
               :required="props.required"
               :value="props.modelValue"
               @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        >
        <div class="validated-input__error-text">{{ props.error ?? 'Error text' }}</div>
      </div>
    </div>
  </div>
</template>
