<script setup lang="ts">
// TODO: optimize component

import { computed, onMounted, onUnmounted, onUpdated, ref, watch, nextTick } from "vue"
import { getTextRelativeNearestTriggerCharacter, getCaretCoordinates } from "@/utils/mentionable.ts"
import { MentionItem, SYMBOL_TRIGGER } from "@/types/mentionable.ts"

const props = defineProps<{ items: MentionItem[] }>()

let cancelKeyUp: string | null = null

// Element the text field (textarea or input)
let input: HTMLElement
// An element of the entire component Mentionable
const el = ref<HTMLDivElement | null>(null)
// List Item tags
const mentionList = ref<HTMLDivElement | null>(null)

const currentSymbolTrigger = ref<string>("")
let currentSymbolTriggerIndex: number

const searchText = ref<string>("")
const selectedIndex = ref(0)
const caretPosition = ref<{ top: number, left: number, height: number } | null>(null)

const displayedItems = computed(() => {
  if (!searchText.value) {
    return props.items
  }

  const finalSearchText = searchText.value.toLowerCase()

  return props.items.filter((item: MentionItem) => {
    const label = item.label.toLowerCase()
    return label.indexOf(finalSearchText) === 0
  })
})

watch(displayedItems, () => {
  selectedIndex.value = 0
  updateScroll()
}, {
  deep: true,
})


onMounted(() => {
  input = getInput()
  attach()
})

onUpdated(() => {
  const newInput = getInput()
  if (newInput !== input) {
    detach()
    input = <HTMLInputElement>newInput
    attach()
  }
})

onUnmounted(() => {
  detach()
})

function getInput() {
  return <HTMLInputElement>el.value?.querySelector("input")
    ?? <HTMLTextAreaElement>el.value?.querySelector("textarea")
}

function attach() {
  if (input) {
    input.addEventListener("input", onInput)
    input.addEventListener("keydown", onKeyDown)
    input.addEventListener("keyup", onKeyUp)
    input.addEventListener("scroll", onScroll)
    input.addEventListener("blur", onBlur)
    input.addEventListener("click", onClick)
  }
}

function detach() {
  if (input) {
    input.removeEventListener("input", onInput)
    input.removeEventListener("keydown", onKeyDown)
    input.removeEventListener("keyup", onKeyUp)
    input.removeEventListener("scroll", onScroll)
    input.removeEventListener("blur", onBlur)
    input.removeEventListener("click", onClick)
  }
}

function onInput() {
  checkKey()
}

function onClick() {
  checkKey()
}

function onScroll() {
  updateCaretPosition()
}

function onBlur() {
  closeMenu()
}

function onKeyUp(e: KeyboardEvent) {
  if (cancelKeyUp && e.key === cancelKeyUp) {
    cancelEvent(e)
  }
  cancelKeyUp = null
}

/**
 * Click control.
 *
 * When you press a Left or Right, the characters are recalculated relative to the cursor.
 * When you press an Up or Down, the element is selected and the scroll is updated.
 * When you click on Enter or Tab, the selected item from the list is embedded.
 */
function onKeyDown(e: KeyboardEvent) {
  // Offset to the left, you need to add the index of the current position
  if (e.key === "ArrowLeft") {
    checkKey(1)
  }

  // Offset to the right, you need to subtract the index of the current position
  if (e.key === "ArrowRight") {
    checkKey(-1)
  }

  if (!currentSymbolTrigger.value) {
    return
  }

  const displayedItemsLength = displayedItems.value.length

  if (e.key === "ArrowDown") {
    selectedIndex.value++
    if (selectedIndex.value >= displayedItemsLength) {
      selectedIndex.value = 0
    }
    cancelEvent(e)
  }

  if (e.key === "ArrowUp") {
    selectedIndex.value--
    if (selectedIndex.value < 0) {
      selectedIndex.value = displayedItemsLength - 1
    }
    cancelEvent(e)
  }

  if ((e.key === "Enter" || e.key === "Tab") && displayedItemsLength > 0) {
    applyMention(selectedIndex.value)
    cancelEvent(e)
  }

  if (e.key === "Escape") {
    closeMenu()
    cancelEvent(e)
  }
}

/**
 * Identifying a valid index and opening a window with tags
 *
 * @param offsetIndex Offset from the index of the trigger symbol
 */
function checkKey(offsetIndex = 0) {
  const indexInputText = (input as HTMLInputElement).selectionStart
  const index = Number(indexInputText) - offsetIndex

  if (index >= 0) {
    const textInput = getValue()
    const keyIndex = textInput.lastIndexOf(SYMBOL_TRIGGER, index - 1)
    const text = getTextRelativeNearestTriggerCharacter(textInput, index, keyIndex)

    if (!(keyIndex < 1 || /\s/.test(textInput[keyIndex - 1]) || !text)) {
      closeMenu()
      return false
    }

    if (text != null) {
      openMenu(SYMBOL_TRIGGER, keyIndex)
      searchText.value = text
      return true
    }
  }

  closeMenu()
  return false
}

function cancelEvent(e: KeyboardEvent) {
  e.preventDefault()
  e.stopPropagation()
  cancelKeyUp = e.key
  updateScroll()
}

function updateScroll() {
  nextTick(() => {
    const mentionSelect = <HTMLElement>(mentionList.value?.querySelector(".mention-selected"))
    const offsetTop = mentionSelect?.offsetTop ?? 0
    mentionList.value?.scroll(0, offsetTop)
  })
}

function mouseOver(index: number) {
  selectedIndex.value = index
}

function getValue() {
  const value = (input as HTMLInputElement).value

  return String(value)
}

function setValue(value: string) {
  (input as HTMLInputElement).value = value
  input.dispatchEvent(new Event("input"))
}

/**
 * Updates the position of the component for the window output, relative to the cursor position in the text
 */
function updateCaretPosition() {
  if (!currentSymbolTrigger.value) {
    return
  }

  caretPosition.value = getCaretCoordinates(
    <HTMLInputElement | HTMLTextAreaElement>input,
    currentSymbolTriggerIndex,
  )

  if (!caretPosition.value) {
    return
  }

  caretPosition.value.top -= input.scrollTop
  if (isNaN(caretPosition.value.height)) {
    caretPosition.value.height = 16
  }
}

function openMenu(key: string, keyIndex: number) {
  const isZoneChangeTriggerSymbol = currentSymbolTrigger.value !== key || currentSymbolTriggerIndex !== keyIndex
  if (isZoneChangeTriggerSymbol) {
    currentSymbolTrigger.value = key
    currentSymbolTriggerIndex = keyIndex
    updateCaretPosition()
    selectedIndex.value = 0
    nextTick(() => {
      // @todo Pop-up window positioning update bug
      window.dispatchEvent(new Event("resize"))
    })
  }
}

function closeMenu() {
  currentSymbolTrigger.value = ""
}

function applyMention(itemIndex: number) {
  const item = displayedItems.value[itemIndex]
  const value = currentSymbolTrigger.value + item?.label + " "
  const currentCaretPosition = currentSymbolTriggerIndex + value.length

  const textInput = getValue()
  const textLight = textInput.slice(0, currentSymbolTriggerIndex)
  const textRight = textInput.slice(currentSymbolTriggerIndex + searchText.value.length + 1, textInput.length)
  const textReplace = textLight + value + textRight

  setValue(textReplace)
  nextTick(() => {
    (input as HTMLInputElement).selectionEnd = currentCaretPosition
  })
  closeMenu()
}

</script>

<template>
  <div
    ref="el"
    class="mentionable"
  >
    <slot />

    <div
      class="wrap-mention"
      :style="caretPosition ? {
        top: `${caretPosition.top}px`,
        left: `${caretPosition.left}px`,
        height: `${caretPosition.height}px`,
      } : {}"
    >
      <div
        v-if="!!currentSymbolTrigger"
        ref="mentionList"
        class="mention-list"
      >
        <div v-if="!displayedItems.length">
          No result
        </div>
        <template v-else>
          <div
            v-for="(item, index) of displayedItems"
            :key="`mentionable-${index}`"
            :class="{'mention-selected': selectedIndex === index}"
            class="mention-item"
            @mouseover="mouseOver(index)"
            @mousedown="applyMention(index)"
          >
            {{ item.label || item.value }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mentionable {
  position: relative;
}

.wrap-mention {
  position: absolute;
  flex-direction: column-reverse;
  display: flex
}

.mention-list {
  max-height: 250px;
  overflow: auto;
  position: absolute;
  background: white;
  z-index: 99999 !important;
  border: 1px solid #e8e8e8;
  bottom: 27px;
  min-width: 100px;
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #edf4fd;
  cursor: pointer;
}
</style>