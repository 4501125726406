<script setup lang="ts">
import { computed, ref } from "vue"
import BaseNav from "@/components/BaseNav.vue"
import SettingsField from "@/components/settings/SettingsField.vue"
import { container } from "@/services/container.ts"

const linkedin_url = ref<string>("")
const url = ref<string>("")
const response_data = ref({})
const disabled = ref(false)

const navItems = [
  {
    link: null,
    label: "Manage Workspaces",
  },
]

const send = async () => {
  disabled.value = true
  try {
    response_data.value = await container.userAdminRepository.companiesRequest(
      linkedin_url.value,
      url.value,
    )
  } finally {
    disabled.value = false
  }
}

const getResponse = computed(() => {
  return JSON.stringify(response_data.value, null, 1)
})

</script>

<template>
  <div>
    <base-nav :items="navItems" icon="settings" />

    <h2>Company service</h2>

    <div class="skills__block settings-block">
      <settings-field
        v-model="linkedin_url"
        :label="{title: 'Linkedin url', description: ''}"
      />

      <settings-field
        v-model="url"
        :label="{title: 'url', description: ''}"
      />

      <div class="settings-block__controls">
        <button
          class="settings-block__control-btn primary-button"
          :disabled="disabled"
          @click="send"
        >
          <span>Send</span>
        </button>
      </div>
    </div>

    <div>
      <h2>
        Response from service Company
      </h2>
      <pre>{{ getResponse }}</pre>
    </div>
  </div>

</template>
<style scoped>
pre {
  max-height: 500px;
  overflow: auto;
}
</style>