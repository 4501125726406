<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted, ref } from "vue"
import { SkillFormData } from "@/types/skill"
import BaseNav from "@/components/BaseNav.vue"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import SkillForm from "@/components/forms/SkillForm.vue"
import CFormControls from "@/components/elements/CFormControls.vue"
import { container } from "@/services/container"
import useSkill from "@/compositions/useSkill"

const route = useRoute()
const { skill, initSkill } = useSkill()

const ws = route.params.ws as string

const navItems = [
  {
    link: `/ws/${ws}/skills`,
    label: "Skill",
  },
  {
    link: null,
    label: "Edit",
  },
]

const skillNew = {
    name: "",
    description: "",
    fieldsInput: []
}

const disabledSaveBtn = ref(false)

const skillEdit = ref<SkillFormData>(skillNew)

const editSkill = async (evt: Event) => {
  evt.preventDefault()
  disabledSaveBtn.value = true
  try {
    await container.skillRepository.edit(ws, skillEdit.value)
    window.location.href = `/ws/${ws}/skills/${skill.value?.id}`
  } finally {
    disabledSaveBtn.value = false
  }
}

const cancel = () => {
  window.location.href = `/ws/${ws}/skills/${skill.value?.id}`
}

onMounted(async () => {
  await initSkill()

  if (skill.value) {
    skillEdit.value.id = skill.value.id
    skillEdit.value.name = skill.value.name
    skillEdit.value.description = skill.value.description
    
    if (skill.value.fields_input) {
      skillEdit.value.fieldsInput = skill.value.fields_input
    }
  }
})

</script>

<template>
  <div v-if="skill && skillEdit.id">
    <base-nav :items="navItems" icon="mates"/>
    <skill-header :skill="skill" />
    <div class="skills__body">
      <skill-tabs
        :skill="skill"
        type="settings"
      />
      <form class="skills__block settings-block" @submit="editSkill">
        <skill-form
          :skill="skillEdit"
        />
        <c-form-controls :disabled="disabledSaveBtn" @cancel="cancel"/>
      </form>
    </div>
  </div>
</template>
