import { App } from "vue"
import { ActionTypeUi, SkillAction, SkillActionField } from "@/types/actions"
import { FilterActionTypeUi } from "@/components/actions/filter/filter"
import { OpenAiActionTypeUi } from "@/components/actions/openAI/openai"
import { CompanyBasicInfo } from "@/components/actions/companyBasicInfo/company-basic-info"
import { HubSpotCompanyInfo } from "@/components/actions/hubspot/hubspot-company-info.ts"
import { HubspotUpdateCompanyInfo } from "@/components/actions/hubspot/hubspot-update-company-info.ts"
import { CrawlerWebPage } from "@/components/actions/companyCrawler/crawler-web-page.ts"
import { HubSpotContactInfo } from "@/components/actions/hubspot/hubspot-contact-info.ts"
import { HubspotUpdateContactInfo } from "@/components/actions/hubspot/hubspot-update-contact-info.ts"
import { HubspotCreateNote } from "@/components/actions/hubspot/hubspot-create-note.ts"
import { Html2text } from "@/components/actions/html2Text/html2text.ts"
import { MinifyHtml } from "@/components/actions/MinifyHtml/minify-html.ts"
import { DeleteColumns } from "@/components/actions/deleteColumns/delete-columns.ts"

export const actions: any[] = [
  FilterActionTypeUi,
  OpenAiActionTypeUi,
  CompanyBasicInfo,
  CrawlerWebPage,
  Html2text,
  MinifyHtml,
  DeleteColumns,
  HubSpotCompanyInfo,
  HubspotUpdateCompanyInfo,
  HubSpotContactInfo,
  HubspotUpdateContactInfo,
  HubspotCreateNote,
]

export const initComponents = {
  install: (app: App) => {
    for (const action of actions) {
      app.component(action.typeName + "Component", action.component)
    }
  },
}

export const getActionClass = (actionType: string): any => {
  for (const action of actions) {
    if (action.typeName === actionType) {
      return action
    }
  }

  throw new Error(`Action type ${actionType} not found`)
}

export const getActionByType = (
  actionType: string,
  availableFields: SkillActionField[],
  actionData: SkillAction | null = null,
): ActionTypeUi => {
  for (const action of actions) {
    if (action.typeName === actionType) {
      const actionObject = new action()
      actionObject.availableFields = availableFields

      if (actionData) {
        actionObject.actionData = structuredClone(actionData)
        actionObject.handlerAfterLoad()
      }
      return actionObject
    }
  }

  throw new Error(`Action type ${actionType} not found`)
}
