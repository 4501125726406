<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted, ref } from "vue"

const route = useRoute()
const type = ref<any>("")
const email = ref<string|null>("")

onMounted(async () => {
  type.value = route.query?.type ?? null
  email.value = sessionStorage.getItem("forgot_email")
})

</script>

<template>
  <div class="email__body">
    <div class="email__form">

      <h1 class="email__title main-title">
        <template v-if="type === 'send'">
          Please check your email
        </template>
        <template v-else>
          Password reset error
        </template>
      </h1>

      <div class="email__field">
        <div class="email__field-text message">

          <template v-if="type === 'error_not_valid'">
            Link expired or doesn’t correct. Could you please try again
          </template>

          <template v-else-if="type === 'error_not_user'">
            We didn’t find this email in the database. Check it please and try again
          </template>

          <template v-else-if="type === 'send'">
            We have sent an email to
            <br><br>
            <a href="mailto:${email}">
              {{ email }}
            </a>
            <br><br>
            Please check your inbox and follow the instructions.
          </template>

        </div>
      </div>

      <div class="email__to-login">
        <router-link
          to="/users/login"
          class="login__login-link custom-link"
          data-tab-id="mates-runs"
        >
          Log In
        </router-link>
      </div>

    </div>
  </div>
</template>
<style scoped>
.message {
  text-align: center;
  font-weight: bold;
  margin: 2rem 0;
  display: block;
  color: black
}
</style>