<script setup lang="ts">

import { useRoute } from "vue-router"
import { computed, onMounted, ref } from "vue"
import IconPlus from "@/components/icons/IconPlus.vue"
import { container } from "@/services/container.js"
import { Paginator } from "@/types/api.js"
import { Skill } from "@/types/skill.ts"
import PagePaginator from "@/components/PagePaginator.vue"
import SkillCard from "@/components/skills/SkillCard.vue"

const route = useRoute()
const ws = computed(() => {
  return route.params?.ws.toString() ?? ""
})

let page = Number(route.query.page || 1)
let perPage = Number(route.query.per_page || 20)
const data = ref<Paginator<Skill> | null>(null)

onMounted(async () => {
  data.value = await container.skillRepository.list(ws.value, page, perPage, true)
})

</script>

<template>
  <section class="main__content skills-list">
    <div class="skills-list__actions">
      <a
        class="primary-button"
        type="button"
        :href="`/ws/${ws}/skills/create`"
      >
        <icon-plus />
        <span>New Skill</span>
      </a>
    </div>

    <section class="skills-list__list">
      <skill-card
        v-for="(skill, index) of data?.items"
        :key="`list-skills-${index}-${skill.id}`"
        :skill="skill"
        class="skills-list__item"
      />
    </section>

    <template v-if="data?.items?.length">
      <page-paginator
        :page="page"
        :per-page="perPage"
        :page-range="data.page_range"
        :count="data.count"
      />
    </template>
  </section>
</template>
