import { Skill } from "./skill"

export interface SkillIntegration extends Skill {
  availableCompany?: boolean | null
  availableContact?: boolean | null
  processed?: boolean | null
}

export enum IntegrationType {
  HUBSPOT = "HUBSPOT",
}

export enum IntegrationStatus {
  DISCONNECTED = "disconnected",
  CONNECTED = "connected",
}

export interface Integration {
  type: IntegrationType
  id: string
  info?: {
    id: string | number
    user: string
  }
  auth_url: string
  status: IntegrationStatus
  error?: {
    message: string | null
  }
}

export interface SettingsIntegration {
  id_integration: string | null,
}