export const localSettingsMock: ProductSettings = {
  pricingTableId: "prctbl_1Oy0HNJJPENgwonWrQdipVnh",
  publishableKey: "pk_test_51OZBJ5JJPENgwonWW2MQ9G2cKyWrndILhVOKhTL37Jii6wV6mHcKYYfq7D1LGI9wxbtvOl4TOi0kbwSpQ03TFNsK00Hf0sen5u",
  linkToBuyAdditionalOperations: "https://buy.stripe.com/test_fZeaIkc9babv05adR4",
  plans: [
    {
      type: "base",
      icon: "IconPlanLightning",
      name: "Basic plan",
      description: "For smaller teams",
      list: [
        "Create digital skills",
        "HubSpot Integration",
        "Web Scraping",
        "AI Data",
        "Email & Chat Support",
      ],
      input: null,
      btn: null,
      links: [
        {
          id: 500,
          link: "buy_btn_1PDPGsJJPENgwonW2bAopEaV",
          price: 49,
        },
        {
          id: 1000,
          link: "buy_btn_1PDh4LJJPENgwonWBeNgc7ty",
          price: 98,
        },
        {
          id: 1500,
          link: "buy_btn_1PGekfJJPENgwonWfOX5g07p",
          price: 147,
        },
        {
          id: 2000,
          link: "buy_btn_1PDgvCJJPENgwonWz56Etwks",
          price: 196,
        },
      ],
    },
    {
      type: "pro",
      icon: "IconPlanBlock",
      name: "Pro plan",
      description: "For larger # of operations",
      list: [
        "Create digital skills",
        "HubSpot Integration",
        "Web Scraping",
        "AI Data",
        "Dedicated Support",
      ],
      input: null,
      btn: null,
      links: [
        {
          id: 2500,
          link: "buy_btn_1PGelcJJPENgwonWi7MyZdA8",
          price: 240,
        },
        {
          id: 5000,
          link: "buy_btn_1PDh3zJJPENgwonW8CpmkMBl",
          price: 480,
        },
        {
          id: 7500,
          link: "buy_btn_1PGelGJJPENgwonWvsoDKZI8",
          price: 720,
        },
        {
          id: 10000,
          link: "buy_btn_1PDh3cJJPENgwonW2m3Y4hO4",
          price: 960,
        },
      ],
    },
    {
      type: "enterprise",
      icon: "IconPlanBlock",
      name: "Enterprise plan",
      description: "To fit your custom needs",
      list: [
        "All in Pro",
        "Custom # of Operations",
        "Custom Skills",
        "SSO",
        "Prompt Engineering",
      ],
      links: null,
      input: {
        value: "Custom",
      },
      btn: {
        name: "Let’s talk",
        link: "https://meetings-eu1.hubspot.com/evghenii-cucias",
      },
    },
  ],
}


export interface ProjectSettings {
  env: ENV | null,
  productStripeSettings: ProductSettings | null
}

export enum ENV {
  LOCAL = "local",
  DEV = "dev",
  PROD = "prod",
  REVIEW = "review"
}

export interface Plan {
  type: string,
  icon: string,
  name: string,
  description: string,
  list: string[],
  input: null | {
    value: string
  },
  btn: null | {
    name: string,
    link: string
  },
  links: null | {
    id: number,
    link: string,
    price: number,
  }[],
}

export interface ProductSettings {
  pricingTableId: string,
  linkToBuyAdditionalOperations: string,
  publishableKey: string,
  plans: Plan[]
}
