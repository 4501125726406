<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted, reactive } from "vue"
import useSkill from "@/compositions/useSkill.ts"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillNav from "@/components/skills/SkillNav.vue"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import { container } from "@/services/container.ts"
import { formatTimeShort } from "@/compositions/useApp.ts"
import { ActionJob, ActionLog, LastJob, SkillJobItem } from "@/types/jobs.ts"
import { SkillAction } from "@/types/actions.ts"

const route = useRoute()
const { skill, initSkill } = useSkill()

const context = reactive({
  params: {
    ws: route.params.ws as string,
    skillId: route.params.skillId as string,
    jobId: route.params.jobId as string,
    actionLogs: null as ActionLog[] | null,
    actions: null as SkillAction[] | null,
    actionJobs: null as ActionJob[] | null,
    skillJob: null as SkillJobItem | null,
    lastJob: null as LastJob | null,
  }
})


async function getJobsDetail() {
  const data = await container.skillJobRepository.getDetail(
    context.params.ws,
    context.params.skillId,
    context.params.jobId,
  )

  context.params.skillJob = data.skillJob
  context.params.lastJob = data.lastJob
  context.params.actionJobs = data.actionJobs
  context.params.actionLogs = data.actionLogs
  context.params.actions = data.actions
}

async function init() {
  await initSkill()
  await getJobsDetail()
}

const isResult = (status: string) => {
  return status === "COMPLETE" || status === "CONFIRMED" || status === "WAITING"
}

const getActions = (actions: SkillAction[] | null = null) => {
  if (actions) {
    return JSON.stringify(actions, null, 1)
  }
}

onMounted(async () => {
  await init()
})
</script>

<template>
  <main v-if="skill">
    <skill-nav :skill="skill" type="actions" />
    <skill-header :skill="skill" />
    <div v-if="context.params.skillJob" class="skills__body">
      <skill-tabs
        :skill="skill"
        :action-name="`${context.params.skillJob?.process_name} - detail`"
        type="action"
      />

      <div class="row">
        <div class="col-12">
          <h1>Skill job {{ context.params.skillJob?.number }}</h1>
        </div>
      </div>

      <div
        v-if="context.params.skillJob?.error?.message"
      >
        <h2>Error</h2>
        <pre>{{ context.params.skillJob?.error?.message }}</pre>
      </div>

      <section class="jobs-about__table jobs-table">
        <h2>Actions:</h2>
        <table class="jobs-table__table">
          <thead>
          <tr>
            <th>number</th>
            <th>name</th>
            <th>type</th>
            <th>date</th>
            <th>status</th>
            <th>duration</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(actionJob, index) in context.params.actionJobs"
            :key="`job_detail_action_job-${index}-${actionJob.id}`"
          >
            <td>
              {{ actionJob.number }}
            </td>
            <td>
              {{ formatTimeShort(actionJob.createdAt) }}
            </td>
            <td>
              {{ actionJob.name }}
            </td>
            <td>
              {{ actionJob.type }}
            </td>
            <td>
              {{ actionJob.status }}
            </td>
            <td>
              {{ actionJob.duration }}
            </td>
            <td
              v-if="isResult(actionJob.status)"
            >
              <router-link
                :to="{name:'job-result', params: {ws: context.params.ws, jobId: context.params.jobId, actionJobId: actionJob.id}}"
                class="custom-link"
              >
                result
              </router-link>
            </td>
          </tr>

          </tbody>
        </table>
      </section>

      <section class="jobs-about__table jobs-table">
        <h2>Log:</h2>
        <table class="jobs-table__table">
          <thead>
          <tr>
            <th>name</th>
            <th>date</th>
            <th>status</th>
            <th class="message">message</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(actionLog, index) in context.params.actionLogs"
            :key="`job_detail_action_log-${index}-${actionLog.id}`"
          >
            <td>
              {{ actionLog.name }}
            </td>
            <td>
              {{ formatTimeShort(actionLog.createdAt) }}
            </td>
            <td>
              {{ actionLog.status }}
            </td>
            <td>
              <pre>{{ actionLog.messages }}</pre>
            </td>
          </tr>

          </tbody>
        </table>
      </section>

      <div>
        <h2>Actions template:</h2>
        <pre>{{ getActions(context.params.actions) }}</pre>
      </div>

      <div>
        <h2>Input data</h2>
        <pre>{{ context.params.skillJob?.inputData }}</pre>
      </div>

    </div>
  </main>
</template>

<style scoped>
tr {
  vertical-align: top;

  pre {
    max-width: 800px;
  }
}

pre {
  max-height: 500px;
  overflow: auto;
}
</style>