// @ts-ignore
import { parse, unparse } from "papaparse"

export const fileReadAsText = (file: File): Promise<string> => {
  const reader = new FileReader()

  return new Promise((resolve) => {
    reader.addEventListener("load", () => {
      const value = reader.result as string
      resolve(value)
    })

    reader.readAsText(file)
  })
}

interface ParseResult {
  data: string[],
  errors: string[],
  meta: object
}

/**
 * Parse csv into a js object
 * @link https://github.com/mholt/PapaParse
 * @param stringData string data file
 * @param config config {...} => https://www.papaparse.com/docs#config
 * @return object => https://www.papaparse.com/docs#results
 */
export const parseCsv = (
  stringData: string,
  config: object,
): ParseResult => {
  return parse(stringData, config)
}

/**
 * Parse data into a string in csv format
 * @param data Data in the format ParseResult
 */
export const unParseCsv = (
  data: ParseResult,
): string => {
  return unparse(data.data)
}