import { ActionTypeUi, SkillAction, SkillActionField } from "@/types/actions.ts"
import ActionDeleteColumns from "@/components/actions/deleteColumns/ActionDeleteColumns.vue"

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "DeleteColumnsAction",
    description: "",
    copilot: false,
    module: "workflow.actions.delete_columns",
    params: {},
    ui: {
      columns_leave: [
        {
          name: "",
          type: "",
        },
      ],
    },
    fields_source: [],
    fields_leave: [],
    fields_target: [],
  }
}

export class DeleteColumns implements ActionTypeUi {
  static typeName = "DeleteColumnsAction"
  static component = ActionDeleteColumns
  public actionData = newSkillAction()
  public availableFields = []

  newActionData() {
    this.actionData = newSkillAction()
  }

  handlerAfterLoad() {
  }

  handlerBeforeSave() {
    this.actionData.fields_leave = this.actionData.ui.columns_leave.map((field: SkillActionField) => field.name)
  }

  handlerEvent(event: string, value: any) {
    if (event === "add-column") {
      const field = { name: "", type: "" }
      this.actionData.ui.columns_leave.push(field)
    }
    if (event === "delete-column") {
      this.actionData.ui.columns_leave.splice(value, 1)
    }
  }
}
