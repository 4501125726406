<script setup lang="ts">
import { computed, onMounted } from "vue"
import { SkillAction, SkillActionField, TypesActionField } from "@/types/actions.ts"
import { HubSpotActionField, HubSpotField, TypeEntityHubSpot } from "@/types/hubspot"
import useSkill from "@/compositions/useSkill.ts"
import InputMultiselect from "@/components/actions/elements/InputMultiselect.vue"
import HubspotMapping from "@/components/actions/hubspot/elements/HubspotMapping.vue"
import LabelForm from "@/components/actions/elements/LabelForm.vue"
import FormHubspotInput from "@/components/actions/hubspot/elements/FormHubspotInput.vue"

const props = defineProps<{
  actionData: SkillAction,
  availableFields: SkillActionField[],
}>()

const { fieldsHubSpot, fetchFieldsHubSpot } = useSkill()

const emit = defineEmits(["formEvent"])

const fieldsHubSpotList = computed(() => {
  return fieldsHubSpot.value?.map((field: HubSpotField) => {
    return {
      name: field.name,
      label: field.label,
      type: field.type,
      field_type: field.field_type,
    }
  }) ?? []
})

const types = computed(() => {
  return Object.keys(TypesActionField)
})

onMounted(async () => {
  await fetchFieldsHubSpot(TypeEntityHubSpot.COMPANY)
})

const fieldsTitle = (({ name, label }: HubSpotActionField) => {
  return `${label} ${name ? "(" + name + ")" : ""}`
})

const addLigament = () => {
  emit("formEvent", "add-ligament")
}

const deleteLigament = (index: number) => {
  emit("formEvent", "delete-ligament", index)
}

const requiredCheck = (value: SkillActionField) => {
  return value?.name === ""
}

</script>

<template>
  <div class="action-form  settings-block param-list__item ">

    <label-form
      description="Select the Field that contains the ID of the Company in HubSpot"
      label="HUBSPOT COMPANY ID"
    >
      <input-multiselect
        v-model="actionData.ui.record_field"
        :required="true"
        :options="props.availableFields"
        :required-check="requiredCheck"
        :allow-empty="false"
        :preselect-first="true"
        display-label="name"
        track-by="name"
        placeholder="Select one Skills attribute"
      />
    </label-form>

    <hubspot-mapping
      v-slot="{attribute}"
      :attributes="actionData.ui.mapping"
      @add="addLigament"
      @delete="deleteLigament"
    >

      <input-multiselect
        v-model="attribute.field_hubspot"
        :required="true"
        :options="fieldsHubSpotList"
        :custom-label="fieldsTitle"
        :required-check="requiredCheck"
        :allow-empty="false"
        :preselect-first="true"
        display-label="name"
        track-by="name"
        label="HubSpot attribute"
        placeholder="Select one HubSpot attribute"
      />

      <form-hubspot-input
        v-model="attribute.field_collextr.name"
      />

      <input-multiselect
        v-model="attribute.field_collextr.type"
        :required="true"
        :options="types"
        :allow-empty="false"
        :preselect-first="true"
        label="Collextr Data Type"
      />
    </hubspot-mapping>
  </div>
</template>
