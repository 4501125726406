<script setup lang="ts">
import { computed, reactive, ref } from "vue"
import { Login } from "@/types/user.ts"
import { email, required } from "@vuelidate/validators"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { container } from "@/services/container.ts"
import FieldInput from "@/components/auth/FieldInput.vue"
import FieldPassword from "@/components/auth/FieldPassword.vue"
import FieldButton from "@/components/auth/FieldButton.vue"


const disabledLoginBtn = ref(false)

const login = reactive<Login>({
  email: "",
  password: "",
})

const $externalResults = ref<ServerErrors>({
  __all__: [],
})

const rules = {
  email: {
    required,
    email,
    $autoDirty: true,
  },
  password: {
    required,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, login, { $externalResults })

const submit = async () => {
  disabledLoginBtn.value = true
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    disabledLoginBtn.value = false
    return
  }

  try {
    const result = await container.userRepository.login({
      email: login.email,
      password: login.password,
    })
    if (result?.status === "ok") {
      //TODO: remove hard reload
      window.location.href = "/"
    } else {
      for (let key in result.error) {
        $externalResults.value[key] = result.error[key]
          ?.map((error: { message: string, code: string }) => error.message)
      }
    }
  } finally {
    disabledLoginBtn.value = false
  }
}

const isValid = computed(() => {
  return validator.value.$errors.length > 0
})

</script>

<template>
  <form
    class="login__form"
    action=""
    method="post"
    @submit.prevent="submit"
  >
    <h1 class="login__title main-title">Log in</h1>

    <div
      v-for="(error, index) of $externalResults?.__all__"
      :key="index"
      class="validated-input__error-text"
      style="display:block;"
    >
      <div class="error-msg">
        {{ error }}
      </div>
    </div>

    <field-input
      v-model="login.email"
      :errors="validator.email"
      name="email"
      placeholder="Email"
      :tabindex="1"
    />

    <field-password
      v-model="login.password"
      :errors="validator.password.$errors"
      name="password"
      placeholder="Password"
      :tabindex="2"
    />

    <field-button
      :is-valid="isValid"
      :disabled="disabledLoginBtn"
      title="Log in"
      :tabindex="3"
    />

    <div class="login__to-login">
      <span>You don't have an account?</span>
      <router-link
        to="/users/signup"
        class="login__login-link custom-link"
        data-tab-id="mates-runs"
        :tabindex="4"
      >
        Sign Up
      </router-link>
    </div>

    <div class="login__to-login">
      <router-link
        to="/users/forgot/password/request"
        class="login__login-link custom-link"
        data-tab-id="mates-runs"
        :tabindex="5"
      >
        Forgot password?
      </router-link>
    </div>

  </form>
</template>
