<script setup lang="ts">
const props = defineProps<{
  tabindex: string | number
}>()
</script>

<template>
  <div class="login__agreement">
    <label class="custom-checkbox">
      <input
        type="checkbox"
        class="custom-checkbox__input"
        :tabindex="props.tabindex"
        required
      >
      <span class="custom-checkbox__text">
        <span>I agree with the </span>
        <a
          href="https://collextr.com/terms"
          class="login__agreement-link custom-link"
          target="_blank"
        >
          Terms & Conditions</a>
        <span>of Clarity</span>
      </span>
    </label>
  </div>
</template>
