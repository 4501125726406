<script setup lang="ts">
import SkillStatus from "@/components/skills/SkillStatus.vue"
import ProgressBar from "@/components/jobs/ProgressBar.vue"
import { container } from "@/services/container.ts"
import { useRoute } from "vue-router"
import { formatTimeShort } from "@/compositions/useApp.ts"

const route = useRoute()

const props = defineProps<{
  action: any,
  ws: string,
}>()

const emit = defineEmits(["update:action"])

const ticker = () => {
  getRunAction()
}

async function getRunAction() {
  const ws = route.params.ws as string
  const skillId = route.params.skillId as string
  const jobId = route.params.jobId as string

  const data = await container.skillJobRepository.getActionJob(ws, skillId, jobId, props.action.id)
  emit("update:action", data)
}

</script>

<template>
  <tr
    data-href="./runs-about.html#row-1"
    data-process-id="row-1"
  >
    <td>
      {{ action.actionName }}
    </td>
    <td>
      <span v-if="action?.startDate">
        {{ formatTimeShort(action.startDate) }}
      </span>
      <span v-else></span>
    </td>
    <td>
      <skill-status :status="action.status" />

      <div
        v-if="action.numberWarnings > 0"
        class="status-label status-label--waiting"
      >
        {{ action.numberWarnings }}
      </div>
    </td>
    <td>
      <a
        v-if="action.status == 'COMPLETE' || action.status == 'STOPPED' || action.status == 'WAITING'"
        :href="`/ws/${ws}/skills/result/download/${action.id}`"
        class="runs-table__link js-runs-table-not-open-run"
      >
        {{ action.actionName }}.csv
      </a>

      <progress-bar
        v-else-if="action.status == 'RUNNING'"
        :current-line-executed="action?.execution?.current_line_executed"
        :number-lines="action?.execution?.number_lines"
        @tick="ticker"
      />

      <span v-if="action.status == 'EMPTY'">
        No data after filtration
      </span>
    </td>
  </tr>
</template>
