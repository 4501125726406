<script setup lang="ts">
const props = defineProps<{
  statusCssClass: string
  statusName: string
  iconComponent?: object
}>()

</script>

<template>
  <template v-if="props.iconComponent">
    <component :is="props.iconComponent" />
  </template>

  <div
    :class="props.statusCssClass"
    class="status-label"
  >
    {{ props.statusName }}
  </div>
</template>


<style>

</style>