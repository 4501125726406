<script setup lang="ts">

import { useRoute } from "vue-router"
import { computed } from "vue"
import { container } from "@/services/container.js"
import { Skill } from "@/types/skill.ts"
import SkillStatus from "@/components/skills/SkillStatus.vue"
import { ROUTE_NAMES } from "@/router/names.ts"

const route = useRoute()
const ws = computed(() => {
  return route.params?.ws.toString() ?? ""
})

const props = defineProps<{
  skill: Skill
}>()


const skillSwitchPublication = async (skill: Skill) => {
  skill.published = !skill.published
  await container.skillRepository.setPublication(
    ws.value,
    skill.id,
    skill.published,
  )
}

const currentSkillStatus = computed(() => {
  return props.skill.lastJob?.status ?? null
})

</script>

<template>
  <div class="skills-list__item">
    <article class="skill-card">
      <div class="skill-card__header">
        <div class="skill-card__header-inner">
          <h3 class="skill-card__title">
            {{ props.skill.name }}
          </h3>
          <div class="skill-card__switch">
            <label class="custom-checkbox custom-checkbox--switch">
              <input
                :checked="props.skill.published"
                type="checkbox"
                class="custom-checkbox__input"
                @change="skillSwitchPublication(props.skill)"
              >
              <span class="custom-checkbox__text"></span>
            </label>
          </div>
        </div>
        <skill-status :status="currentSkillStatus" />
      </div>
      <p class="skill-card__caption">
        <!--            caption text-->
      </p>
      <p class="skill-card__text">
        {{ props.skill.description }}
      </p>

      <router-link
        :to="{ name: ROUTE_NAMES.SKILL, params: { ws: ws, skillId: props.skill.id } }"
        class="skill-card__link"
      >
      </router-link>
    </article>
  </div>
</template>
