<script setup lang="ts">

const props = withDefaults(defineProps<{
  width?: string,
  height?: string,
}>(), {
  width: "16",
  height: "16",
})

</script>

<template>
  <svg :width="props.width" :height="props.height">
    <use xlink:href="#ic_save"></use>
  </svg>
</template>