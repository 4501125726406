<script setup lang="ts">

const props = defineProps<{
  label: string
}>()

</script>

<template>
  <div class="action-param__row">
    <div class="action-param__col action-param__col--left">
      <div class="action-param__col-name text-style">{{ props.label }}</div>
    </div>
    <div class="action-param__col action-param__col--right">
      <slot />
    </div>
  </div>
</template>