import { container } from "@/services/container"
import { computed, ComputedRef } from "vue"
import { projectSettingsStore } from "@/store/settings.ts"
import { ENV, localSettingsMock } from "@/types/settings.ts"

export default function useProjectSettings() {
  const store = projectSettingsStore()

  const initProjectSettings = async () => {
    const settings = await container.userRepository.projectSettings()
    store.$patch(settings)
  }

  const env: ComputedRef<ENV | null> = computed(() => store.env)

  const isProd = () => env.value === ENV.PROD
  const isLocal = () => env.value === ENV.LOCAL
  const isDev = () => env.value === ENV.DEV

  const productStripeSettings = computed(() => store.productStripeSettings ?? localSettingsMock)

  return {
    initProjectSettings,
    env,
    isProd,
    isLocal,
    isDev,
    productStripeSettings
  }
}
