<script setup lang="ts">
import moment from "moment"
import { onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import BaseNav from "@/components/BaseNav.vue"
import { BillingSkills } from "@/types/billing"
import { container } from "@/services/container"
import CTable from "@/components/elements/CTable.vue"

const route = useRoute()
const router = useRouter()
const ws = route.params.ws as string
const month = route.params.month as string

const title = moment(month).format("YYYY MMMM")

const navItems = [
  {
    link: `/ws/${ws}/settings`,
    label: "Manage Workspace",
  },
  {
    link: `/ws/${ws}/billing`,
    label: "Usage & Billing",
  },
  {
    link: null,
    label: title,
  },
]

const data = ref<BillingSkills[] | null>(null)

const toBillingActions = (billing: BillingSkills) => {
  router.push({
    name: "billing-actions",
    params: { ws: ws, month: month, skillId: billing.skill_id },
  })
}

const columns = [
  {key: 'skill_name', label: 'Skill name'},
  {key: 'jobs', label: 'Total Skills used'},
  {key: 'actions', label: 'number of Actions'},
  {key: 'cost', label: 'Cost, USD'},
]

onMounted(async () => {
  data.value = await container.billingRepository.billingSkills(month)
})
</script>

<template>
  <div v-if="data">
    <base-nav :items="navItems" icon="settings"/>
    <h2>{{ title }}</h2>
    <c-table
      :columns="columns"
      :items="data"
      @click="toBillingActions"/>
  </div>
</template>
