<script setup lang="ts">
import SettingsField from "@/components/settings/SettingsField.vue"
import SettingsControls from "@/components/settings/SettingsControls.vue"
import { reactive, ref, toRaw } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { container } from "@/services/container.ts"
import { useRouter } from "vue-router"
import { passwordMatchErrorText } from "@/types/auth.ts"

const disabledSaveBtn = ref(false)

const state = reactive({
  current_password: '',
  new_password: '',
  confirm_password: '',
})

const $externalResults = ref<ServerErrors>({})
const router = useRouter()

const sameAsPassword = () => {
  return {
    $message: passwordMatchErrorText,
    $validator: () => state.new_password === state.confirm_password,
  }
}

const rules = {
  current_password: {
    required,
    $autoDirty: true,
  },
  confirm_password: {
    sameAs: sameAsPassword(),
    required,
    $autoDirty: true,
  },
  new_password: {
    required,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, state, { $externalResults })

const cancel = () => {
}
const save = async () => {
  disabledSaveBtn.value = true
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    disabledSaveBtn.value = false
    return
  }

  try {
    const result = await container.userRepository.updatePasswordData(toRaw(state))

    if (result.status === "ok") {
      await router.push({ name: "login" })
    } else {
      $externalResults.value = result.data.message as ServerErrors
    }
  } finally {
    disabledSaveBtn.value = false
  }
}

</script>

<template>
  <div>
    <h2>Change password</h2>

    <div class="skills__block settings-block">
      <settings-field
        v-model="state.current_password"
        :errors="validator.current_password"
        :label="{title: 'CURRENT PASSWORD', description: 'Your current password'}"
        type="password"
      />

      <settings-field
        v-model="state.new_password"
        :errors="validator.new_password"
        :label="{title: 'NEW PASSWORD', description: 'Your new password'}"
        type="password"
      />

      <settings-field
        v-model="state.confirm_password"
        :errors="validator.confirm_password"
        :label="{title: 'CONFIRM PASSWORD', description: 'confirm password'}"
        type="password"
      />

      <settings-controls
        :disabled="disabledSaveBtn"
        @cancel="cancel"
        @save="save"
      />
    </div>
  </div>
</template>
