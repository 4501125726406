<script setup lang="ts">
import InputRadioParameter from "@/components/actions/elements/InputRadioParameter.vue"
import TextareaParameter from "@/components/actions/elements/TextareaParameter.vue"
import InputParameter from "@/components/actions/elements/InputParameter.vue"
import { Property } from "@/types/actions.ts"
import InputSelect from "@/components/actions/elements/InputSelect.vue"
import { MentionItem } from "@/types/mentionable.ts"
import LabelFormParameter from "@/components/actions/elements/LabelFormParameter.vue"
import CInputTextMention from "@/components/elements/CInputTextMention.vue"

const props = defineProps<{
  parameter: Property,
  index: number,
  lengthList: number,
  fieldsMentionable: MentionItem[]
}>()

const emit = defineEmits(["delete", "add"])

const outputFiledType = [
  "string",
  "boolean",
  "array",
  "enum",
  "number",
]

const itemTypes = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
]

const setItemsEnum = (value: string) => {
  props.parameter.items = {
    type: "string",
    enum: value.split("\n"),
  }
}

const getItemsEnum = () => {
  return props.parameter?.items?.enum?.join("\n") ?? ""
}


</script>

<template>
  <div class="param-list__item action-param">
    <div class="action-param__title sub-title">
      <span>ACTION output Parameter</span>
      <button
        v-if="index > 0"
        class="action-param__del-btn icon-button"
        type="button"
        @click="emit('delete', index)"
      >
        <svg width="24" height="24">
          <use xlink:href="#ic_delete"></use>
        </svg>
      </button>
    </div>

    <input-parameter
      v-model="parameter.name"
      placeholder="parameter name"
      label="Output Field name"
      :name="'filed_name_' + index"
      :required="true"
    />

    <InputRadioParameter
      :id="'filed_type_' + index"
      v-model="parameter.type"
      label="Output Filed Type"
      :name="'filed_type_' + index"
      :required="true"
      :options="outputFiledType.entries()"
    />

    <template v-if="parameter.type === 'enum' && props.parameter.items">
      <textarea-parameter
        :id="'items_enum_' + index"
        placeholder="items enum"
        label="Value"
        :name="'items_enum_' + index"
        :required="false"
        :model-value="getItemsEnum()"
        @update:model-value="e => setItemsEnum(e)"
      />
    </template>

    <input-select
      v-if="parameter.type === 'array' && props.parameter.items"
      label="Item type"
      description=""
      :items="itemTypes"
      v-model="props.parameter.items.type"
    />

    <label-form-parameter
      label="Output Field Description"
    >
      <c-input-text-mention
        :id="'description_' + index"
        v-model="parameter.description"
        placeholder="description"
        :name="'description_' + index"
        :required="true"
        :rows="5"
        :fields-mentionable="fieldsMentionable"
      />
    </label-form-parameter>

    <div class="action-param__row action-param__row--pv0">
      <button
        v-if="index === lengthList"
        class="action-param__add-btn outline-button outline-button--wide"
        type="button"
        @click="emit('add')"
      >
        <svg width="16" height="16">
          <use xlink:href="#ic_plus"></use>
        </svg>
        <span>Add Action Output Parameter</span>
      </button>
    </div>
  </div>
</template>
