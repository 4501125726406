import { ACTION_TYPES, ActionTypeUi, Property, RESPONSE_TYPE, SkillAction, SkillActionField } from "@/types/actions"
import ActionFormOpenAI from "@/components/actions/openAI/ActionFormOpenAI.vue"
import { toRaw } from "vue"
import OpenAiTest from "@/components/actions/openAI/OpenAiTest.vue"
import ActionTemplatesFormOpenAi from "@/components/modals/ActionTemplatesFormOpenAi.vue"

const isNumeric = (value: string) => /^-?\d+$/.test(value)

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: ACTION_TYPES.OPEN_AI_ACTION,
    description: "",
    copilot: false,
    module: "workflow.actions.open_ai",
    params: {
      model: "gpt-4-turbo-2024-04-09",
      prompt: "",
      system: "",
    },
    ui: {
      output_field_name: "",
      response_type: "Text",
      parameters: [],
      description_parameters: "",
    },
    fields_source: [],
    fields_target: [],
  }
}

const templateParameter = {
  name: "",
  type: "string",
  description: "",
  items: {
    enum: [],
    type: "string",
  },
} as Property

const isolate_fields = (text: string) => {
  const re = /(?:^|\W)#(\w+)(?!\w)/g
  const fields: SkillActionField[] = []
  let match
  while ((match = re.exec(text))) {
    fields.push({
      name: match[1],
      type: "string",
    })
  }

  return fields
}

export class OpenAiActionTypeUi implements ActionTypeUi {
  public actionData: SkillAction = newSkillAction()
  public availableFields: SkillActionField[] = []
  public static typeName = ACTION_TYPES.OPEN_AI_ACTION
  public static component = ActionFormOpenAI
  public static componentTest = OpenAiTest
  public static componentTemplates = ActionTemplatesFormOpenAi

  public newActionData() {
    this.actionData = newSkillAction()
  }

  public handlerAfterLoad() {
  }

  public handlerBeforeSave() {
    this.actionData.fields_source = []

    this.promptToFieldsSource()
    this.systemToFieldsSource()

    if (this.actionData.ui.response_type === RESPONSE_TYPE.STRUCTURED_DATA) {
      this.parametersToResponseJson()
      this.parametersToFieldsTarget()
    } else {
      this.actionData.params.response_json = null
      this.actionData.fields_target = [
        {
          name: this.actionData.ui.output_field_name,
          type: "string",
        },
      ]
    }

    const names = this.actionData.fields_source.map(field => field.name)
    const filteredExistedNames = this.actionData.fields_source.filter(
      (field, index) => !names.includes(field.name, index + 1)
    )
    this.actionData.fields_source = filteredExistedNames
  }

  public handlerEvent(event: string, value: any) {
    if (event === "add-action-parameter") {
      this.addActionParameter()
    }
    if (event === "delete-action-output-parameter") {
      this.deleteActionOutputParameter(value)
    }
    if (event === "change-response-type") {
      if (!this.actionData.params.response_json) {
        if (this.actionData.ui.parameters.length === 0) {
          this.addActionParameter()
        }
      } else {
        this.actionData.params.response_json = null
      }
    }
  }

  private addActionParameter() {
    const newItem = structuredClone(templateParameter)
    const properties = structuredClone(toRaw(this.actionData.ui.parameters))
    properties.push(newItem)
    this.actionData.ui.parameters = properties
  }

  private deleteActionOutputParameter(index: number) {
    const properties = this.actionData.ui.parameters
    // const properties = structuredClone(this.actionData.ui.parameters)
    properties.splice(index, 1)
    this.actionData.ui.parameters = properties
  }

  private promptToFieldsSource() {
    const prompt = isolate_fields(this.actionData.params.prompt)
    this.actionData.fields_source = this.actionData.fields_source.concat(prompt)
  }

  private systemToFieldsSource() {
    const system = isolate_fields(this.actionData.params.system)
    this.actionData.fields_source = this.actionData.fields_source.concat(system)
  }

  private parametersToResponseJson() {
    const properties = {} as Record<string, any>
    const required: string[] = []
    this.actionData.ui.parameters?.forEach((parameter: any) => {
      if (parameter?.name) {
        properties[parameter.name] = {
          type: parameter.type,
          description: parameter.description,
        }
        required.push(parameter.name)
        delete properties[parameter.name].name

        const description_field = isolate_fields(parameter.description)
        this.actionData.fields_source = this.actionData.fields_source.concat(description_field)

        if (parameter.type === "array") {
          properties[parameter.name].items = { type: parameter.items.type }
        }

        if (parameter.type === "enum") {
          let typeEnumIsString = false

          const itemsList = parameter.items.enum
          itemsList.forEach((value: string) => {
            if (!isNumeric(value)) {
              typeEnumIsString = true
            }
          })
          if (typeEnumIsString) {
            properties[parameter.name].enum = parameter.items.enum
            properties[parameter.name].type = "string"
          } else {
            properties[parameter.name].enum = parameter.items.enum.map(Number)
            properties[parameter.name].type = "number"
          }
        }
      }
    })

    const description = isolate_fields(this.actionData.ui.description_parameters)
    this.actionData.fields_source = this.actionData.fields_source.concat(description)

    this.actionData.params.response_json = {
      schema: {
        type: "object",
        required: required,
        properties: properties,
      },
      description: this.actionData.ui.description_parameters,
    }
  }

  private parametersToFieldsTarget() {
    const propertiesList = Object.entries(
      this.actionData.params.response_json?.schema?.properties,
    )
    this.actionData.fields_target =
      propertiesList?.map((parameter: any) => {
        let type = parameter[1].type
        if (type === "enum") {
          type = parameter[1].items.type
        }

        if (type === "array") {
          return {
            name: parameter[0] ?? "",
            type: type,
            sub_type: parameter[1].items.type,
          }
        }

        return {
          name: parameter[0] ?? "",
          type: type,
        }
      }) ?? []
  }
}
