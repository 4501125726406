<script setup lang="ts">
const props = defineProps<{
    label: string
    description: string | null
}>()

</script>

<template>
    <div class="settings-block__row">
        <div class="settings-block__col settings-block__col--left">
            <div class="settings-block__attr sub-title">{{ props.label }}</div>
            <div class="settings-block__hint hint-text">{{ props.description }}</div>
        </div>
        <div class="settings-block__col settings-block__col--right">
            <slot/>
        </div>
    </div>
</template>