export interface TokenKind {
  name: string
  regex: string
}

export interface Token {
  kind: TokenKind
  text: string
  pos: number
}

export const TOKEN_FIELD: TokenKind = {
  name: "field",
  regex: "\\b[a-z]\\b",
}
export const TOKEN_SPACE: TokenKind = {
  name: "space",
  regex: "[\\s]*",
}
export const TOKEN_LPAR: TokenKind = {
  name: "lpar",
  regex: "\\(",
}
export const TOKEN_RPAR: TokenKind = {
  name: "rpar",
  regex: "\\)",
}
export const TOKEN_AND: TokenKind = {
  name: "and",
  regex: "(and)\\s",
}
export const TOKEN_OR: TokenKind = {
  name: "or",
  regex: "or",
}

export const TOKEN_KIND_LIST = [
  TOKEN_AND,
  TOKEN_OR,
  TOKEN_FIELD,
  TOKEN_SPACE,
  TOKEN_LPAR,
  TOKEN_RPAR,
]

export const TOKEN_KIND_OPERATORS = [
  TOKEN_AND,
  TOKEN_OR,
]
