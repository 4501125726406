<script setup lang="ts">
import moment from "moment"
import { computed, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import BaseNav from "@/components/BaseNav.vue"
import CTable from "@/components/elements/CTable.vue"
import { BillingByMonth } from "@/types/billing"
import { container } from "@/services/container"
import useUser from "@/compositions/useUser.ts"
import BillingPlan from "@/components/billing/BillingPlan.vue"
import { getPlan } from "@/utils/subscription.ts"

const route = useRoute()
const router = useRouter()
const ws = route.params.ws as string
const { currentWorkspace } = useUser()

const navItems = [
  {
    link: `/ws/${ws}/settings`,
    label: "Manage Workspace",
  },
  {
    link: null,
    label: "Usage & Billing",
  },
]

const formatDate = (value: string) => moment(value).format("YYYY MMMM")

const data = ref<BillingByMonth[]>([])

const toBillingSkills = (billing: BillingByMonth) => {
  router.push({
    name: "billing-skills",
    params: { ws: ws, month: billing.date },
  })
}

const columns = [
  { key: "date", label: "Month", value: formatDate },
  { key: "skills", label: "Total Skills used" },
  { key: "jobs", label: "number of Jobs" },
  { key: "cost", label: "Cost, USD" },
]

const getPlanText = computed(() => {
  return getPlan(currentWorkspace.value)
})

onMounted(async () => {
  data.value = await container.billingRepository.billing()
})
</script>

<template>
  <div>
    <base-nav :items="navItems" icon="settings" />

    <div>
      <h2>MANAGE YOUR PLAN - {{ getPlanText }}</h2>
      <billing-plan />
    </div>

    <div>
      <h2>USAGE & BILLING</h2>
      <c-table
        :columns="columns"
        :items="data"
        @click="toBillingSkills" />
    </div>
  </div>
</template>

<style scoped>
h2 {
  text-transform: uppercase;
}
</style>