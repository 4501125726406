export const ROUTE_NAMES = {
  HOME: "home",
  WELCOME: "welcome",
  SKILLS: "skills",
  SKILL: "skill",
  SKILL_CREATE: "skill-create",
  ACTION_EDIT: "action-edit",
  WORKSPACE_SETTINGS_GENERAL: "workspace-settings-general",
  WORKSPACE_SETTINGS_INTEGRATIONS: "workspace-settings-integrations",
  INTEGRATIONS_CONFIGURE: "integrations-configure",
  WORKSPACE_USAGE_BILLING: "billing",
  ADMIN_GLOBAL_TEMPLATE_ACTION: "admin-global-template-actions",
  TEMPLATE_ACTION_EDIT: "admin-global-template-action-edit",
  WORKSPACE_USERS: "workspace-users",
}
