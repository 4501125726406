<script setup lang="ts">

import IconSearch from "@/components/icons/IconSearch.vue"
import PagePaginator from "@/components/PagePaginator.vue"
import { computed, ref } from "vue"
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "@/utils/pagination.ts"
import { ACTION_TYPES } from "@/types/actions.ts"

const TIMEOUT_SEARCH = 500

const emit = defineEmits(["updateData", "switchingTab"])

const props = defineProps<{
  tab?: string,
  paginate: {
    count?: number,
    page_range?: number[] | undefined,
  }
}>()

const searchText = defineModel({
  type: String,
  default: "",
  set() {
    search()
  },
})

let page = ref(1)
let perPage = ref(10)
let timeoutId = ref<number | undefined>(undefined)


const search = async () => {
  clearTimeout(timeoutId.value)
  timeoutId.value = setTimeout(async () => {
    page.value = 1
    emit("updateData", searchText.value, page.value, perPage.value)
  }, TIMEOUT_SEARCH)
}

const changePage = async (paginateData: {
  pageNumber: string | null,
  perPage: number | undefined,
}) => {
  const pageNumberCurrent = paginateData.pageNumber ? Number(paginateData.pageNumber) : DEFAULT_PAGE
  const perPageCurrent = paginateData.perPage ? Number(paginateData.perPage) : DEFAULT_PER_PAGE

  page.value = pageNumberCurrent
  perPage.value = perPageCurrent
  emit("updateData", searchText.value, page.value, perPage.value)
}

const getPageRange = computed(() => {
  return props.paginate?.page_range ?? []
})

const getCount = computed(() => {
  return props.paginate?.count ?? 0
})

const switchingTab = (currentTab: string) => {
  if (currentTab === props.tab) {
    return
  }

  emit("switchingTab", currentTab)
}

</script>

<template>
  <div
    class="custom-pop-up__content custom-alert__content"
  >
    <div class="custom-alert__body">
      <div class="custom-pop-up__content template-action">
        <div class="header">
          <div
            v-if="props.tab"
            class="tabs"
          >
            <div
              :class="{'active': props.tab === ACTION_TYPES.DEFAULT}"
              class="tabs__item"
              @click="switchingTab(ACTION_TYPES.DEFAULT)"
            >
              Global Templates
            </div>
            <div
              :class="{'active': props.tab === ACTION_TYPES.OPEN_AI_ACTION}"
              class="tabs__item"
              @click="switchingTab(ACTION_TYPES.OPEN_AI_ACTION)"
            >
              My OpenAI Actions
            </div>
          </div>

          <div class="search-input">
            <icon-search class="search-input__icon" />
            <input
              v-model="searchText"
              placeholder="Start Typing to search"
              class="search-input__input"
            >
          </div>
        </div>

        <div class="body">
          <slot name="body" />
        </div>

        <div class="wrap-paginator">
          <page-paginator
            :page="page"
            :per-page="perPage"
            :page-range="getPageRange"
            :count="getCount"
            is-not-hard-page-reload
            @change-page="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.custom-alert__content {
  padding: 32px 32px 24px;
  max-width: 1030px;

  .custom-alert__body {
    padding: 0
  }
}

.template-action {
  display: flex;
  flex-direction: column;
  width: 1030px;
  max-width: 100%;
  height: 700px;
  max-height: calc(100vh - 240px);

  .header {
    display: flex;
    align-items: start;
    padding: 0 0 24px;
    flex-direction: column;

    .tabs {
      margin-bottom: 16px;
    }
  }

  .body {
    display: flex;
    align-items: start;
    height: calc(100% - 240px);
    flex: 1;
  }

  .search-input__icon {
    position: absolute;
    top: 16px;
    right: 16px;
    left: unset;
  }
}
</style>
