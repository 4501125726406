<script setup lang="ts">
import { onMounted, ref } from "vue"
import BaseNav from "@/components/BaseNav.vue"
import { container } from "@/services/container.ts"
import ApiToken from "@/types/admin.ts"

const settings = ref("")
const tokens = ref<ApiToken[]>([])
const errorText = ref("")
const disabled = ref(false)

const navItems = [
  {
    link: null,
    label: "Manage Workspaces",
  },
]

onMounted(async () => {
  const result = await container.userAdminRepository.getGlobalSettings()
  settings.value = JSON.stringify(result.data.settings, null, 4)
  tokens.value = result.data.api_tokens
})

const updateSettings = async () => {
  errorText.value = ""
  disabled.value = true

  try {
    const data = JSON.stringify(JSON.parse(settings.value))
    const result = await container.userAdminRepository.updateGlobalSettings(data)

    if (result.status === 'error') {
      errorText.value = result.message
    }

  } catch (error) {
    errorText.value = String(error)
  } finally {
    disabled.value = false
  }
}

</script>

<template>
  <div>
    <base-nav :items="navItems" icon="settings" />

    <h2>Global settings</h2>

    <div class="skills__block settings-block">
      <div class="settings-block__controls">
        <button
          class="settings-block__control-btn primary-button"
          :disabled="disabled"
          @click="updateSettings"
        >
          <span>Save</span>
        </button>
      </div>
      <div>
        <div class="error">
          {{ errorText }}
        </div>
        <textarea
          v-model="settings"
        />
      </div>

      <div v-if="tokens">
        <table class="runs-table__table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Token</th>
            <th>Expiration date</th>
          </tr>
          </thead>
          <tr
            v-for="(token, index) of tokens"
            :key="`global-settings-tokens-${index}`"
          >
            <td>{{ token.name }}</td>
            <td>{{ token.token }}</td>
            <td>{{ token.expiration_date }}</td>
          </tr>
        </table>
      </div>

    </div>
  </div>

</template>
<style scoped>
pre {
  max-height: 500px;
  overflow: auto;
}

textarea {
  margin: 1rem 0;
  height: 500px;
  width: 800px;
}

.error {
  color: red;
}
</style>