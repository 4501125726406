<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted, reactive } from "vue"
import useSkill from "@/compositions/useSkill.ts"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillNav from "@/components/skills/SkillNav.vue"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import { container } from "@/services/container.ts"
import ActionJobTr from "@/components/jobs/ActionJobTr.vue"
import { SkillJob, ActionJob } from "@/types/jobs.ts"

const route = useRoute()
const { skill, initSkill } = useSkill()

const context = reactive({
  params: {
    ws: route.params.ws as string,
    skillId: route.params.skillId as string,
    jobId: route.params.jobId as string,
    skillJob: {
      processName: null,
    } as any,
    lastJob: {
      processName: null,
    } as any,
    actionJobs: [],
  } as any,
})


const updateAction = async (action: ActionJob) => {
  if (action.status === "RUNNING") {
    context.params.actionJobs = context.params.actionJobs
      .map((currentAction: ActionJob) => currentAction.id === action.id ? action : currentAction)
  } else {
    await init()
  }
}

const jobStart = async () => {
  await container.skillJobRepository.jobStart(context.params.ws, context.params.skillId, context.params.jobId)
  await init()
}

const jobStop = async () => {
  await container.skillJobRepository.jobStop(context.params.ws, context.params.skillId, context.params.jobId)
  await init()
}

async function getJobs() {
  const data:SkillJob = await container.skillJobRepository.get(context.params.ws, context.params.skillId, context.params.jobId)

  context.params.skillJob = data?.skillJob ?? null
  context.params.lastJob = data?.lastJob ?? null
  context.params.actionJobs = data?.action_jobs ?? null
}

async function init() {
  await initSkill()
  await getJobs()
}

onMounted(async () => {
  await init()
})
</script>

<template>
  <main v-if="skill">
    <skill-nav :skill="skill" type="actions" />
    <skill-header :skill="skill" />
    <div v-if="context.params.skillJob" class="skills__body">
      <skill-tabs :skill="skill" type="action" :action-name="context.params.skillJob.processName" />

      <div class="jobs-about__flex-inner">
        <table class="jobs-about__info">
          <tr class="jobs-about__info-row">
            <td class="jobs-about__info-name">Job name</td>
            <td class="jobs-about__info-value jobs-about__info-value--done-all">
              <div>
                <span>{{ context.params.skillJob.processName }}</span>
                <svg
                  v-if="context.params.skillJob.status == 'COMPLETE'"
                  width="24" height="24"
                >
                  <use xlink:href="#ic_done_all"></use>
                </svg>
              </div>
            </td>
          </tr>
          <tr class="jobs-about__info-row">
            <td class="jobs-about__info-name">Started</td>
            <td class="jobs-about__info-value">
            <span>
              {{ context.params.skillJob.createdAt }}
            </span>
            </td>
          </tr>
          <tr class="jobs-about__info-row">
            <td class="jobs-about__info-name">Job details</td>
            <td class="jobs-about__info-value">
              <router-link
                :to="{name:'job-detail', params: {ws: context.params.ws, jobId: context.params.jobId}}"
                class="custom-link"
              >
                <span v-if="context.params.skillJob.error == null">open</span>
                <span v-else>error</span>
              </router-link>
            </td>
          </tr>
        </table>

        <button
          v-if="context.params.skillJob.status == 'RUNNING'"
          class="primary-button"
          @click="jobStop"
        >
          <svg width="24" height="24">
            <use xlink:href="#ic_delete"></use>
          </svg>
          <span>Stop  Execution</span>
        </button>

        <button
          v-else-if="context.params.skillJob.status == 'STOPPED' || context.params.skillJob.status == 'WAITING'"
          class="primary-button"
          @click="jobStart"
        >
          <svg width="24" height="24">
            <use xlink:href="#ic_next"></use>
          </svg>
          <span>Continue Execution</span>
        </button>

        <button
          v-else
          class="primary-button"
          disabled
        >
          <svg width="24" height="24">
            <use xlink:href="#ic_next"></use>
          </svg>
          <span>Continue Execution</span>
        </button>
      </div>

      <section class="jobs-about__table jobs-table">
        <table class="jobs-table__table">
          <thead>
          <tr>
            <th>Action Name</th>
            <th>Started on</th>
            <th>Status</th>
            <th>Result</th>
          </tr>
          </thead>
          <tbody>

          <action-job-tr
            v-for="[index, actionJob] of context.params.actionJobs.entries()"
            :key="index"
            :ws="context.params.ws"
            :action="actionJob"
            @update:action="updateAction"
          />

          </tbody>
        </table>
      </section>

    </div>
  </main>
</template>
