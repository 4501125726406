import { ActionTypeUi, SkillAction, SkillActionField } from "@/types/actions"
import ActionFormFilter from "@/components/actions/filter/ActionFormFilter.vue"
import { Lexer } from "@/components/actions/filter/condition/lexer"
import { Parser } from "@/components/actions/filter/condition/parser"
import { Condition } from "@/components/actions/filter/condition/condition"

export const conditionNameList = "abcdefghijklmnopqrstuvwxyz".split("")

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "FilterAction",
    description: "",
    copilot: false,
    module: "workflow.actions.filter",
    params: {
      condition: {},
    },
    ui: {
      conditionFormula: "",
      conditionAnd: {
        type: "OrCondition",
        params: {
          values: [],
        },
      },
    },
    fields_source: [],
    fields_target: [],
  }
}

const newCondition = () => ({
  type: "ComparisonCondition",
  params: {
    value_1: {
      type: "FieldValueCondition",
      params: {
        field_name: "",
        field_type: "",
      },
    },
    value_2: {
      type: "ValueCondition",
      params: {
        value: "",
        type_name: "string",
      },
    },
    operator: "EQUALS",
  },
})

export class FilterActionTypeUi implements ActionTypeUi {
  public actionData: SkillAction = newSkillAction()
  public availableFields: SkillActionField[] = []

  public static typeName = "FilterAction"
  public static component = ActionFormFilter

  public newActionData() {
    this.actionData = newSkillAction()
  }

  public handlerAfterLoad() {
    this.operatorIsToIsEmpty()
  }

  public handlerBeforeSave() {
    this.operatorIsEmptyToIs()
    this.parseFormula()
    this.updateFieldsSource()
  }

  public handlerEvent(event: string, _: any) {
    if (event === "add-condition") {
      const newItem = newCondition()
      this.actionData.ui.conditionAnd.params.values.push(newItem)
    }
    if (event === "add-condition" || event === "delete-condition") {
      this.updateConditionFormula()
    }
  }

  /** Convert operator IS_EMPTY to IS */
  private operatorIsEmptyToIs() {
    const values = this.actionData.ui.conditionAnd.params.values as any[]
    for (const condition of values) {
      if (["IS_EMPTY", "IS_NOT_EMPTY"].includes(condition.params.operator)) {
        condition.params.value_2.params.type_name = "special"
        if (condition.params.operator === "IS_EMPTY") {
          condition.params.value_2.params.value = "EMPTY"
        }
        if (condition.params.operator === "IS_NOT_EMPTY") {
          condition.params.value_2.params.value = "NOT_EMPTY"
        }
        condition.params.operator = "IS"
      }
    }
  }

  /** Convert operator IS to IS_EMPTY */
  private operatorIsToIsEmpty() {
    const values = this.actionData.ui.conditionAnd.params.values as any[]
    for (const condition of values) {
      if (condition.params.operator === "IS") {
        if (condition.params.value_2.params.value === "EMPTY") {
          condition.params.operator = "IS_EMPTY"
        }
        if (condition.params.value_2.params.value === "NOT_EMPTY") {
          condition.params.operator = "IS_NOT_EMPTY"
        }
        condition.params.value_2.params.type_name = "string"
        condition.params.value_2.params.value = ""
      }
    }
  }

  private updateConditionFormula() {
    let formula = ""
    const values = this.actionData.ui.conditionAnd.params.values as any[]
    for (const index in values) {
      if (index !== "0") {
        formula += " and "
      }
      formula += conditionNameList[index]
    }
    this.actionData.ui.conditionFormula = formula
  }

  private updateFieldsSource() {
    const values = this.actionData.ui.conditionAnd.params.values
    for (const condition of values) {
      const fieldName = condition.params.value_1.params.field_name
      const alreadyAddedField = this.actionData.fields_source.find(
        (field) => field.name === fieldName,
      )
      if (alreadyAddedField) {
        continue
      }

      const availableField = this.availableFields.find(
        (availableField) => availableField.name === fieldName,
      )
      if (availableField) {
        const field = {
          name: availableField.name,
          type: availableField.type,
        }
        this.actionData.fields_source.push(field)
      }
    }
  }

  private parseFormula() {
    const listCondition: { key: string; value: Condition }[] = []
    const values = this.actionData.ui.conditionAnd.params.values as any[]
    for (const index in values) {
      listCondition.push({
        key: conditionNameList[index],
        value: values[index],
      })
    }

    const lexer = new Lexer(this.actionData.ui.conditionFormula)
    lexer.analysis()
    const parser = new Parser(
      lexer.tokens,
      this.actionData.ui.conditionFormula,
      listCondition,
    )
    this.actionData.params.condition = parser.parseFormula()
  }
}
