import { DefineComponent } from "vue"

export const RESPONSE_TYPE = {
  STRUCTURED_DATA: "Structured data",
  TEXT: "Text",
}

export const ACTION_TYPES = {
  DEFAULT: "default",
  OPEN_AI_ACTION: "OpenAiAction",
}

/**
 * The options model (params) of the OpenAI action
 */
export interface ModelOptions {
  model: string
  prompt: string
  system: string
  fieldsSource: SkillActionField[]
  fieldsTarget: SkillActionField[]
  response_json?: ResponseJson
}

/**
 * The response_json model of the OpenAI action
 */
export interface ResponseJson {
  schema: {
    type: string
    required: string[]
    properties: Property[]
  } | null
  description: string
}

/**
 * The properties model in response_json of the OpenAI action
 */
export interface Property {
  description: string
  type: string
  name?: string | null
  items?: {
    enum: string[]
    type: string
  } | null
}

export interface SkillActionBaseForm {
  name: string
  description: string
  mode: string
}

export interface SkillActionField {
  name: string
  type: string
  sub_type?: string
}

export interface SkillAction {
  id: string
  name: string
  title?: string
  type: string
  description: string
  copilot: boolean
  module: string
  params: any
  ui: any
  fields_source: SkillActionField[]
  fields_target: SkillActionField[]
  fields_leave?: string[]
  position?: string | number
}

export interface SkillActionTemplate extends SkillAction {
  skill?: { name: string, id: string | number }
}

export interface SkillActionFields {
  skill_action_fields: { id: string; input_fields: SkillActionField[] }[]
}

export abstract class ActionTypeUi {
  static typeName: string

  // TODO: to type Generics in DefineComponent
  static component: DefineComponent<any, any, any>
  static componentTest: DefineComponent<any, any, any> | null = null
  static componentTemplates: DefineComponent<any, any, any> | null = null

  abstract actionData: SkillAction
  abstract availableFields: SkillActionField[]
  abstract newActionData: () => void
  abstract handlerAfterLoad: () => void
  abstract handlerBeforeSave: () => void
  abstract handlerEvent: (event: string, value: any) => void
}

export interface FilterOperators {
  [propName: string]: string[]
}

export interface SkillActionItem {
  id: string
}

export enum TypesActionField {
  string = "string",
  linkedin_url = "linkedin_url",
  site_url = "site_url",
  number = "number",
  boolean = "boolean",
  json = "json",
  file = "file",
  array = "array",
}

export interface TestRunActionResult {
  status: string,
  message?: string,
  data: { property: string | number }[]
}

export const testedErrorMessage = "An error happened during the test. Please try again or contact support"

export enum ActionTypeModeForm {
  ACTION_ADD = "ACTION_ADD",
  ACTION_EDIT = "ACTION_EDIT"
}