import { ref, Ref } from "vue"
import { promptModal } from "jenesius-vue-modal"
import ConfirmModal from "@/components/modals/ConfirmModal.vue"
import { useRouter } from "vue-router"

export const DO_NOT_SAVE = "DO_NOT_SAVE"

export const useRequiresSaving = (
  fnSave = async () => {
  },
) => {
  const savingChangesIsRequired = ref(false)
  const router = useRouter()
  const listener = init(savingChangesIsRequired)

  router.beforeEach(async (_to, _from, next) => {
    if (savingChangesIsRequired.value) {
      const promptRequiresSaving = await requiresSavingPrompt()

      if (promptRequiresSaving === DO_NOT_SAVE) {
        next()
      } else if (promptRequiresSaving) {
        try {
          await fnSave()
          savingChangesIsRequired.value = false
          next()
        } catch (error) {
          next(false)
        }
      } else if (!promptRequiresSaving) {
        next(false)
      }
    }

    next()
  })

  function init(savingChangesIsRequired: Ref<boolean>) {
    return (event: Event) => {
      if (!savingChangesIsRequired.value) return

      event.preventDefault()
      return "There are unsaved changes. Are we leaving anyway?"
    }
  }

  function subscribeRequiresSaving() {
    window.addEventListener("beforeunload", listener)
  }

  function unsubscribeRequiresSaving() {
    savingChangesIsRequired.value = false
    window.removeEventListener("beforeunload", listener)
  }

  return {
    savingChangesIsRequired: savingChangesIsRequired,
    subscribeRequiresSaving: subscribeRequiresSaving,
    unsubscribeRequiresSaving: unsubscribeRequiresSaving,
  }
}

async function requiresSavingPrompt() {
  return await promptModal(ConfirmModal, {
    title: `The skill was changed. Please do not forget to save it`,
    buttonConfirmationText: "Save and leave",
    buttonRejectionText: "Cancel",
    classCSSButtonRejection: "secondary-button",
    customButtonText: "Do not save and leave",
    customStatus: DO_NOT_SAVE,
    iconComponent: null,
  })
}
