import { BillingActions, BillingByMonth, BillingSkills } from "@/types/billing"
import { AxiosInstance } from "axios"

export class BillingRepository {
  constructor(private axios: AxiosInstance) {}

  async billing(): Promise<BillingByMonth[]> {
    const res = await this.axios.get("/users/api/billing")
    return res.data.data
  }

  async billingSkills(month: string): Promise<BillingSkills[]> {
    const res = await this.axios.get(`/users/api/billing/${month}`)
    return res.data.data
  }

  async billingActions(month: string, skillId: string): Promise<{skill_name: string, items: BillingActions[]}> {
    const res = await this.axios.get(`/users/api/billing/${month}/${skillId}`)
    return res.data.data
  }
}