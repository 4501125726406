import { Paginator } from "@/types/api.ts"
import { SkillTemplate } from "@/types/skill"
import { AxiosInstance } from "axios"

export class SkillAdminRepository {
    constructor(private axios: AxiosInstance) {
    }

    async addGlobalTemplate(template: SkillTemplate): Promise<void> {

        await this.axios.post(`/admin/api/skill-global-templates/add`, template)
    }

    async replaceGlobalTemplate(templateId: number, template: SkillTemplate): Promise<void> {
        await this.axios.post(`/admin/api/skill-global-templates/${templateId}/replace`, template)
    }

    async globalTemplates(search: string, page = 1, perPage = 5): Promise<Paginator<SkillTemplate>> {
        const params: Record<string, string> = {
            search: search,
            page: page.toString(),
            per_page: perPage.toString()
        }
        const query = (new URLSearchParams(params)).toString()
        const res = await this.axios.get(`/admin/api/skill-global-templates?${query}`)

        return res.data.data
    }

    async getGlobalTemplate(templateId: number): Promise<SkillTemplate> {
        const res = await this.axios.post(`/admin/api/skill-global-templates/${templateId}`)

        return res.data.data
    }

    async deleteGlobalTemplate(templateId: number): Promise<void> {
        await this.axios.post(`/admin/api/skill-global-templates/${templateId}/delete`)
    }
}