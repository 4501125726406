<script setup lang="ts">

const props = defineProps<{
  items: {value: string, label: string}[],
  active?: string
}>()

const emit = defineEmits(["click"])

</script>


<template>
    <div class="skills__tabs">
        <div class="tabs">
            <div v-for="item of props.items"
                class="tabs__item"
                :class="{'active': props?.active && item.value === props.active}"
                :key="item.value"
                @click="emit('click', item.value)">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>