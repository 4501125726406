export const PAGINATION_BUTTON_PREV_KEY = "prev"
export const PAGINATION_BUTTON_NEXT_KEY = "next"
export const PAGINATION_DOTS_KEY = "."
export const DEFAULT_PER_PAGE = 10
export const DEFAULT_PAGE = 1


export function getPaginationItems(
  active: number,
  total: number,
  perPage: number,
) {
  const result = []

  const pagesCount = Math.ceil(total / perPage)

  const left = Math.max(Math.min(active - 2, pagesCount - 3), 1)
  const right = Math.min(Math.max(4, active + 2), pagesCount)

  const hasLeftDots = left > 3
  const from = hasLeftDots ? left : 1

  const hasRightDots = right < pagesCount - 2
  const to = hasRightDots ? right : pagesCount

  if (hasLeftDots) {
    result.push(1, ".")
  }

  for (let i = from; i <= to; i += 1) {
    result.push(i)
  }

  if (hasRightDots) {
    result.push(".")
  }

  if (hasRightDots && Number.isFinite(pagesCount)) {
    result.push(pagesCount)
  }

  const resultWithControls = [
    PAGINATION_BUTTON_PREV_KEY,
    ...result,
    PAGINATION_BUTTON_NEXT_KEY,
  ]

  return resultWithControls
}
