<script setup lang="ts">
import { SkillActionField } from "@/types/actions.ts"
import { computed } from "vue"
import InputMultiselect from "@/components/actions/elements/InputMultiselect.vue"

const props = defineProps<{
  options: { name: string; type: string }[]
}>()

const model = defineModel<SkillActionField>()

const options = computed(() => {
  if (model.value?.name && model.value.name !== "") {
    return props.options.concat([
      { name: model.value.name, type: model.value.type },
    ])
  } else {
    return props.options
  }
})

const optionsValue = computed(() => options.value.map((item) => item))
</script>

<template>
  <input-multiselect
    v-model="model"
    :options="optionsValue"
    required
    preselect-first
    display-label="name"
    track-by="name"
  />
</template>
