import { SkillJob, ActionJob, SkillJobItem as SkillJobItem, ActionLog, ActionResult, LastJob } from "@/types/jobs"
import { Paginator, ResponseApi, ResponseApiMessage } from "@/types/api.ts"
import { AxiosError, AxiosInstance } from "axios"
import { notify } from "@kyvg/vue3-notification"
import { SkillAction } from "@/types/actions.ts"

export class SkillJobRepository {
  constructor(private axios: AxiosInstance) {
  }

  async jobs(
    ws: string,
    skillId: string,
    page: number = 1,
    perPage: number = 20,
  ): Promise<Paginator<SkillJobItem>> {
    const params: any = {
      page: page,
      per_page: perPage,
    }
    const query = new URLSearchParams(params).toString()

    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs?${query}`,
    )

    return res.data.data
  }

  async delete(ws: string, skillId: string, jobId: string): Promise<void> {
    const title = "Delete job"
    let type = "error"
    let message = "Job delete error"

    try {
      const result = await this.axios.post(
        `/ws/${ws}/skills/api/${skillId}/jobs/${jobId}/delete`,
      )

      if (result.data.status === "ok") {
        type = "info"
        message = "Job deleted"
      }

    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async create(ws: string, skillId: string, data: string): Promise<ResponseApi<ResponseApiMessage>> {
    const title = "Start job"
    let type = "error"
    let message = "Job launch error"

    try {
      const result = await this.axios.post(
        `/ws/${ws}/skills/api/${skillId}/jobs/create`,
        data, {
          headers: { "Content-Type": "text/plain" },
        },
      )

      if (result.data.status === "ok") {
        type = "info"
        message = "The job is started"
      }

      return result.data
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const result = error as AxiosError<ResponseApi<ResponseApiMessage>>
        if (result.response?.data) {
          return result.response.data
        }
      }

      throw error
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async get(ws: string, skillId: string, runId: string): Promise<SkillJob> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs/${runId}`,
    )

    return res.data.data
  }

  async getDetail(ws: string, skillId: string, jobId: string): Promise<{
    actionJobs: ActionJob[]
    actionLogs: ActionLog[]
    skillJob: SkillJobItem,
    actions: SkillAction[],
    lastJob: LastJob
  }> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs/${jobId}/detail`,
    )

    return res.data.data
  }

  async getResult(
    ws: string,
    skillId: string,
    jobId: string,
    actionJobId: string,
  ): Promise<{
    actionJob: ActionJob
    skillJob: SkillJobItem,
    table: ActionResult
  }> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs/${jobId}/result/${actionJobId}`,
    )

    return res.data.data
  }

  async getActionJob(
    ws: string,
    skillId: string,
    jobId: string,
    actionRunId: string,
  ): Promise<ActionJob> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs/${jobId}/actions/${actionRunId}`,
    )

    return res.data.data
  }

  async jobStart(ws: string, skillId: string, jobId: string): Promise<any> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs/${jobId}/start`,
    )

    return res.data.data
  }

  async jobStop(ws: string, skillId: string, jobId: string): Promise<any> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/jobs/${jobId}/stop`,
    )

    return res.data.data
  }
}
