<script setup lang="ts">
import { useRoute, useRouter } from "vue-router"
import { openModal } from "jenesius-vue-modal"
import ActionFormBase from "@/components/actions/ActionFormBase.vue"
import { onMounted, ref, toRaw } from "vue"
import { container } from "@/services/container.ts"
import ActionControls from "@/components/actions/elements/ActionControls.vue"
import { ActionTypeModeForm, ActionTypeUi, SkillActionField } from "@/types/actions.ts"
import { getActionByType, getActionClass } from "@/components/actions"
import { updateGlobalTemplate } from "@/components/actions/templates/global-template-action.ts"
import BaseNav from "@/components/BaseNav.vue"
import { ROUTE_NAMES } from "@/router/names.ts"
import { GlobalTemplateAction } from "@/types/templates.ts"

const route = useRoute()
const router = useRouter()

const availableFields: SkillActionField[] = []

const templateActionId = route.params.templateActionId as string

const disabledSaveBtn = ref(false)
const templateAction = ref<GlobalTemplateAction | null>(null)
const actionComponent = ref<ActionTypeUi | null>(null)
const actionClass = ref<any | null>(null)

const navItems = [
  {
    link: "./",
    label: "Global actions templates",
  },
  {
    link: null,
    label: "Edit global actions template",
  },
]

async function getAction() {
  const result = await container.globalTemplateActionRepository.get(templateActionId)
  templateAction.value = result.data

  const action = toRaw(templateAction.value.content)
  actionClass.value = getActionClass(action.type)
  actionComponent.value = getActionByType(action.type, availableFields, action)
}

onMounted(async () => {
  await getAction()
})

const saveAction = async (evt: Event) => {
  evt.preventDefault()
  disabledSaveBtn.value = true

  if (actionComponent.value?.actionData && templateAction.value) {
    actionComponent.value?.handlerBeforeSave()
    const result = await updateGlobalTemplate(actionComponent.value.actionData, templateAction.value.id)

    if (result && result.status === "ok") {
      await router.push({
        name: ROUTE_NAMES.ADMIN_GLOBAL_TEMPLATE_ACTION,
      })
    }
  }

  disabledSaveBtn.value = false
}

const showTest = async () => {
  await openModal(actionClass.value?.componentTest, {
    actionData: actionComponent.value?.actionData,
  })
}

const showLoadTemplates = async () => {
  await openModal(actionClass.value?.componentTemplates, {
    actionData: actionComponent.value?.actionData,
    actionFormMode: ActionTypeModeForm.ACTION_EDIT,
  })
}

const getComponentName = (type: string) => {
  return type + "Component"
}

</script>

<template>
  <main v-if="actionComponent">
    <div class="skills__body">
      <base-nav :items="navItems" icon="settings" />

      <h2>Edit global actions template</h2>

      <form class="skills__block settings-block" @submit="saveAction">
        <action-form-base
          :action-data="actionComponent?.actionData"
          :has-templates="!!actionClass?.componentTemplates"
          @load-templates="showLoadTemplates"
        />
        <component
          :is="getComponentName(actionComponent?.actionData.type)"
          :action-data="actionComponent?.actionData"
          :available-fields="availableFields"
          @form-event="(event: string, value: any) => actionComponent?.handlerEvent(event, value)"
        />
        <action-controls
          :disabled="disabledSaveBtn"
          :has-test="!!actionClass.componentTest"
          @test="showTest"
        />
      </form>
    </div>
  </main>
</template>
