import { ActionTypeUi, SkillAction } from "@/types/actions.ts"
import ActionHtml2Text from "@/components/actions/html2Text/ActionHtml2Text.vue"

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "Html2TextAction",
    description: "",
    copilot: false,
    module: "workflow.actions.html_text",
    params: {},
    ui: {},
    fields_source: [
      {
        name: "",
        type: "string",
      },
    ],
    fields_target: [
      {
        name: "",
        type: "string",
      },
    ],
  }
}

export class Html2text implements ActionTypeUi {
  static typeName = "Html2TextAction"
  static component = ActionHtml2Text
  public actionData = newSkillAction()
  public availableFields = []

  newActionData() {
    this.actionData = newSkillAction()
  }

  handlerAfterLoad() {}

  handlerBeforeSave() {}

  handlerEvent() {}
}
