<script setup lang="ts">
import IconDelete from "@/components/icons/IconDelete.vue"

export interface TableColumn {
  key: string
  label: string
  valueHandler?: (value: string) => string
}

const props = defineProps<{
  items: { [key: string]: string | number | null | any }[]
  columns?: TableColumn[]
  deleteHandler?: (row: object) => void
}>()

const itemHasValue = (index: number, key: string) => Object.keys(props.items[index]).includes(key)

const getItemValue = (index: number, key: string) => {
  if (itemHasValue(index, key)) {
    return props.items[index][key]
  }

  return null
}

const emit = defineEmits(["click"])
</script>

<template>
  <table class="jobs-table__table">
    <thead v-if="columns">
    <tr>
      <th
        v-for="(column, index) of props.columns"
        :key="`collextr_table_row_${index}`"
      >
        {{ column.label }}
      </th>
      <th
        v-if="props.deleteHandler"
      >
        Actions
      </th>
    </tr>
    </thead>
    <tr
      v-for="(item, index) in props.items"
      :key="index"
      class="table-tr"
      @click="emit('click', item)"
    >
      <template v-for="column of props.columns">
        <td>
          <slot :name="`cell(${column.key})`" :item="props.items[index]">
            {{ getItemValue(index, column.key) }}
          </slot>
        </td>
      </template>

      <td
        v-if="props.deleteHandler"
        class="runs-table__td--btn"
      >
        <button
          class="runs-table__del-row icon-button"
          @click="props.deleteHandler(item)"
        >
          <icon-delete />
        </button>
      </td>
    </tr>
  </table>
</template>
