<script setup lang="ts">
import Multiselect from "vue-multiselect"
import { SkillActionField } from "@/types/actions.ts"
import { computed } from "vue"

const props = defineProps<{
  attribute: SkillActionField
  options: { name: string; type: string }[]
}>()

const options = computed(() => {
  if (props.attribute.name !== "") {
    return props.options.concat([
      { name: props.attribute.name, type: props.attribute.type },
    ])
  } else {
    return props.options
  }
})

const optionsValue = computed(() => options.value.map((item) => item.name))
const updateValue = (value: string) => {
  const option = props.options.find((option) => option.name === value)
  if (option) {
    props.attribute.name = option.name
    props.attribute.type = option.type
  } else {
    props.attribute.name = ""
    props.attribute.type = ""
  }
}
</script>

<template>
  <multiselect
    v-model="props.attribute.name"
    :options="optionsValue"
    required
    @select="updateValue"
  />
</template>
