<script setup lang="ts">
import CInputText from "@/components/elements/CInputText.vue"
import InputRadio from "@/components/actions/elements/InputRadio.vue"
import { SkillAction } from "@/types/actions"

const props = defineProps<{
  actionData: SkillAction
  hasTemplates?: boolean
}>()

const actionModeOptions = [
  { label: "Autopilot", value: "false" },
  { label: "Co-pilot", value: "true" },
]

const emit = defineEmits(["loadTemplates"])

const loadTemplates = () => {
  emit("loadTemplates")
}
</script>

<template>
  <div class="action-form">

    <div class="settings-block__controls">
      <button
        v-if="props.hasTemplates"
        class="settings-block__control-btn secondary-button"
        @click.prevent="loadTemplates"
      >
        <span>Load from Template</span>
      </button>
    </div>

    <br>

    <c-input-text
      v-model="props.actionData.name"
      label="Action name"
      description="You will see this name in the Skill action List"
      placeholder="Action name"
      :required="true"
    />

    <input-radio
      :model-value="props.actionData.copilot.toString()"
      label="Action mode"
      description="Choose Co-Pilot if you want the Skill to stop after executing the Action"
      placeholder="Action mode"
      type="Button"
      :required="true"
      :options="actionModeOptions"
      @input="(event: any) => props.actionData.copilot = event.target.value === 'true'"
    />

    <c-input-text
      v-model="props.actionData.description"
      label="Description"
      description="Type in a friendly description of the Action"
      placeholder="Description"
      :required="true"
      :rows="3"
    />
  </div>
</template>
