<script setup lang="ts">
import { Notifications } from "@kyvg/vue3-notification"
import { computed } from "vue"
import IconStatusError from "@/components/icons/IconStatusError.vue"
import IconStatusSuccess from "@/components/icons/IconStatusSuccess.vue"
import IconStatusWarning from "@/components/icons/IconStatusWarning.vue"
import CNotificationsStatus from "@/components/notifications/CNotificationsStatus.vue"
import CNotifyTemplate from "@/components/notifications/CNotifyTemplate.vue"
import { DEFAULT_CLASS, ERROR_CLASS, SUCCESS_CLASS, WARNING_CLASS } from "@/types/notifications.ts"

const animation = computed(() => {
  return {
    enter(element: HTMLElement) {
      let height = element.clientHeight
      return {
        height: [height, 0],
        opacity: [0, 3],
      }
    },
    leave: {
      height: 0,
      opacity: 0,
    },
  }
})

</script>

<template>
  <notifications
    :animation="animation"
    position="bottom right"
  >
    <template #body="props">
      <div
        @click="props.close"
      >
        <c-notify-template
          :text="props.item.text"
          :title="props.item.title"
          :class="props.class[1]"
        >
          <div class="status">
            <c-notifications-status
              v-if="props.class[1] === DEFAULT_CLASS"
              :status-css-class="props.class[1]"
              status-name="Information"
            />
            <c-notifications-status
              v-else-if="props.class[1] === WARNING_CLASS"
              :status-css-class="props.class[1]"
              status-name="Warning"
              :icon-component="IconStatusWarning"
            />
            <c-notifications-status
              v-else-if="props.class[1] === ERROR_CLASS"
              :status-css-class="props.class[1]"
              status-name="Error"
              :icon-component="IconStatusError"
            />
            <c-notifications-status
              v-else-if="props.class[1] === SUCCESS_CLASS"
              :status-css-class="props.class[1]"
              status-name="Success"
              :icon-component="IconStatusSuccess"
            />
          </div>
        </c-notify-template>
      </div>
    </template>
  </notifications>
</template>

<style scoped>
.vue-notification-group {
  width: calc(99% - 285px) !important;
  padding: 12px 32px 12px 32px;
  z-index: 9999 !important;
}
</style>