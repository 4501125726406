<script setup lang="ts">
import { ref } from "vue"
import IconSave from "@/components/icons/IconSave.vue"
import { container } from "@/services/container.ts"
import { closeModal } from "jenesius-vue-modal"
import { useRoute } from "vue-router"

const emit = defineEmits(["confirm"])

const currentInputText = ref("")
const route = useRoute()
const ws = route.params.ws as string

const save =  async (evt: Event) => {
  evt.preventDefault()

  const result = await container.workspaceRepository.updateGeneralData(
    ws,
    {
      "company_url": currentInputText.value,
    },
  )

  if (result.status === "ok") {
    await closeModal()
  }

  emit("confirm", currentInputText.value)
}

</script>

<template>
  <form
    class="custom-pop-up__content custom-alert__content"
    @submit="save"
  >
    <div class="custom-alert__body">
      <div class="custom-alert__icon js-custom-alert-icon">
        <icon-save />
      </div>
      <div class="custom-alert__message">
        Please enter the website of your company:
      </div>
      <div class="custom-alert__check-block">
        <div class="custom-alert__check-text">
          It helps Collextr to know more about you
        </div>
        <div class="validated-input">
          <input
            v-model="currentInputText"
            placeholder="url"
            required
            class="custom-alert__check-input custom-input"
          />
        </div>
      </div>
    </div>
    <div class="custom-alert__footer js-custom-alert-footer">
      <input
        class="custom-alert__confirm primary-button js-custom-alert-confirm"
        type="submit"
        value="Save"
      />
    </div>
  </form>
</template>
