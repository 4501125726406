<script setup lang="ts">
import { useRoute } from "vue-router"
import IconSave from "@/components/icons/IconSave.vue"

const props = defineProps<{
  hasTest: boolean,
  hasSaveGlobalTemplate?: boolean,
  disabled?: boolean
}>()

const emit = defineEmits(["test", "click-save-global-template"])

const route = useRoute()

const clickCancel = () => {
  const skillId = route.params.skillId as string
  const ws = route.params.ws as string

  //TODO: remove hard reload
  window.location.href = `/ws/${ws}/skills/${skillId}`
}

const clickTest = () => {
  emit("test")
}

const clickSaveGlobalTemplate = () => {
  emit("click-save-global-template")
}
</script>

<template>
  <div class="settings-block__controls">
    <button
      v-if="props.hasTest"
      id="controls-btn-test"
      class="settings-block__control-btn secondary-button"
      @click.prevent="clickTest"
    >
      <span>Test</span>
    </button>

    <button
      v-if="props.hasSaveGlobalTemplate"
      id="controls-btn-save-global-template"
      class="settings-block__control-btn secondary-button"
      @click.prevent="clickSaveGlobalTemplate"
    >
      <span>Save as global template</span>
    </button>

    <button
      id="controls-btn-cancel"
      class="settings-block__control-btn secondary-button"
      @click.prevent="clickCancel"
    >
      <span>Cancel</span>
    </button>

    <button
      id="controls-btn-save"
      class="settings-block__control-btn primary-button"
      :disabled="props.disabled"
    >
      <icon-save />
      <span>Save</span>
    </button>
  </div>
</template>
