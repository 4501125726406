<script setup lang="ts">
import LabelForm from "@/components/actions/elements/LabelForm.vue"
import { SkillAction, SkillActionField } from "@/types/actions.ts"
import CInputText from "@/components/elements/CInputText.vue"
import InputMultiselect from "@/components/actions/elements/InputMultiselect.vue"

const props = defineProps<{
  actionData: SkillAction
  availableFields: SkillActionField[]
}>()

</script>

<template>
  <div class="action-form settings-block">
    <label-form
      id="html-text"
      label="html content"
      description="Select the field containing the page content to receive the text"
    >
      <input-multiselect
        v-model="props.actionData.fields_source[0]"
        :options="props.availableFields"
        required
        preselect-first
        display-label="name"
        track-by="name"
      />
    </label-form>

    <c-input-text
      v-model="props.actionData.fields_target[0].name"
      label="save to"
      description="Type the filed name to save the text"
      required
    />

  </div>
</template>
