import { ActionTypeUi, SkillAction } from "@/types/actions.ts"
import ActionMinifyHtml from "@/components/actions/MinifyHtml/ActionMinifyHtml.vue"

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "MinifyHtmlAction",
    description: "",
    copilot: false,
    module: "workflow.actions.minify_html",
    params: {},
    ui: {},
    fields_source: [
      {
        name: "",
        type: "string",
      },
    ],
    fields_target: [
      {
        name: "",
        type: "string",
      },
    ],
  }
}

export class MinifyHtml implements ActionTypeUi {
  static typeName = "MinifyHtmlAction"
  static component = ActionMinifyHtml
  public actionData = newSkillAction()
  public availableFields = []

  newActionData() {
    this.actionData = newSkillAction()
  }

  handlerAfterLoad() {}

  handlerBeforeSave() {}

  handlerEvent() {}
}
