<script setup lang="ts">
import { useRoute, useRouter } from "vue-router"
import { openModal } from "jenesius-vue-modal"
import ActionFormBase from "@/components/actions/ActionFormBase.vue"
import { computed, onMounted, ref } from "vue"
import { container } from "@/services/container.ts"
import useSkill from "@/compositions/useSkill.ts"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillNav from "@/components/skills/SkillNav.vue"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import ActionControls from "@/components/actions/elements/ActionControls.vue"
import { ACTION_TYPES, ActionTypeModeForm, ActionTypeUi } from "@/types/actions.ts"
import { getActionByType, getActionClass } from "@/components/actions"
import { saveGlobalTemplate } from "@/components/actions/templates/global-template-action.ts"
import { userStore } from "@/store/user.ts"
import { ROUTE_NAMES } from "@/router/names.ts"

const route = useRoute()
const router = useRouter()
const store = userStore()
const { skill, availableFields, initSkill, fetchAvailableFields, actionTypes, fetchActionTypes } = useSkill()

const skillId = route.params.skillId as string
const ws = route.params.ws as string
const actionType = route.query.actionType as string

const disabledSaveBtn = ref(false)

const actionComponent = ref<ActionTypeUi | null>(null)
const actionClass = ref<any | null>(null)

const createAction = async (evt: Event) => {
  evt.preventDefault()

  if (actionComponent.value) {
    actionComponent.value.handlerBeforeSave()

    try {
      disabledSaveBtn.value = true
      const result = await container.skillActionRepository.create(ws, skillId, actionComponent.value.actionData)

      if (result.status === "ok") {
        await router.push({
          name: ROUTE_NAMES.SKILL,
          params: {
            ws: ws,
            skillId: skillId,
          },
        })
      }
    } finally {
      disabledSaveBtn.value = false
    }
  }
}

const showTest = async () => {
  actionComponent.value?.handlerBeforeSave()
  await openModal(actionClass.value?.componentTest, {
    actionData: actionComponent.value?.actionData,
  })
}

const showSaveGlobalTemplate = async () => {
  if (actionComponent.value?.actionData) {
    actionComponent.value?.handlerBeforeSave()
    await saveGlobalTemplate(actionComponent.value.actionData)
  }
}

const showLoadTemplates = async () => {
  await openModal(actionClass.value?.componentTemplates, {
    actionData: actionComponent.value?.actionData,
    actionFormMode: ActionTypeModeForm.ACTION_ADD,
  })
}

onMounted(async () => {
  await initSkill()
  await fetchAvailableFields()
  await fetchActionTypes()
  actionComponent.value = getActionByType(actionType, availableFields.value)

  const type = actionTypes.value.find((type) => type.type === actionType)
  if (type) {
    actionComponent.value.actionData.name = type.name
    actionComponent.value.actionData.description = type.description
  }

  actionClass.value = getActionClass(actionType)
})

const getComponentName = (type: string) => {
  return type + "Component"
}

const isShowButtonSaveGlobalTemplate = computed(() => {
  return store.getUserProfile.admin && actionComponent.value?.actionData.type === ACTION_TYPES.OPEN_AI_ACTION
})

</script>

<template>
  <main v-if="skill && actionComponent">
    <skill-nav :skill="skill" type="actions" />
    <skill-header :skill="skill" />
    <div class="skills__body">
      <skill-tabs
        :skill="skill"
        :action-name="actionComponent?.actionData.type"
        type="action"
      />
      <form class="skills__block settings-block" @submit="createAction">
        <action-form-base
          :action-data="actionComponent?.actionData"
          :has-templates="!!actionClass?.componentTemplates"
          @load-templates="showLoadTemplates"
        />
        <component
          :is="getComponentName(actionComponent?.actionData.type)"
          :action-data="actionComponent?.actionData"
          :available-fields="availableFields"
          @form-event="(event: string, value: any) => actionComponent?.handlerEvent(event, value)"
        />
        <action-controls
          :disabled="disabledSaveBtn"
          :has-test="!!actionClass.componentTest"
          :has-save-global-template="isShowButtonSaveGlobalTemplate"
          @test="showTest"
          @click-save-global-template="showSaveGlobalTemplate"
        />
      </form>
    </div>
  </main>
</template>
