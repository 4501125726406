<script setup lang="ts">
const props = defineProps<{
  label: string
  description: string | null
  placeholder?: string
  modelValue: string
  id?: string
  required?: boolean
  rows?: number
  error?: string
}>()

const emit = defineEmits(["update:modelValue"])
</script>

<template>
  <div class="settings-block__row">
    <div class="settings-block__col settings-block__col--left">
      <div class="settings-block__attr sub-title">{{ props.label }}</div>
      <div class="settings-block__hint hint-text">{{ props.description }}</div>
    </div>
    <div class="settings-block__col settings-block__col--right">
      <div class="settings-block__input-wrap validated-input">
        <textarea
          v-if="props.rows"
          class="custom-textarea custom-textarea--small"
          :rows="props.rows"
          :value="props.modelValue"
          :required="props.required"
          :id="props.id"
          @input="
            emit('update:modelValue', ($event.target as HTMLInputElement).value)
          "
        />
        <input
          v-else
          class="custom-input custom-input--small"
          :placeholder="props.placeholder"
          :value="props.modelValue"
          :required="props.required"
          :id="props.id"
          @input="
            emit('update:modelValue', ($event.target as HTMLInputElement).value)
          "
        />
        <div class="validated-input__error-text">{{ props.error }}</div>
      </div>
    </div>
  </div>
</template>
