<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed } from "vue"
import { replaceQueryParams } from "@/compositions/useApp.ts"
import { getPaginationItems } from "@/utils/pagination"
import {
  PAGINATION_BUTTON_NEXT_KEY,
  PAGINATION_BUTTON_PREV_KEY,
  PAGINATION_DOTS_KEY,
} from "@/utils/pagination"

const CHANGE_PAGE_EVENT = "changePage"

const router = useRouter()

const emit = defineEmits([CHANGE_PAGE_EVENT])
const props = defineProps<{
  page: number
  perPage: number
  count: number
  pageRange: number[],
  isNotHardPageReload?: boolean
  hidePerPage?: boolean
}>()

const perPageList = [5, 10, 25, 50, 100]

const setPerPage = (perPage: string) => {
  // TODO: reset to first page

  emit(CHANGE_PAGE_EVENT, {
    pageNumber: null,
    perPage: perPage,
  })

  if (!props.isNotHardPageReload) {
    replaceQueryParams("per_page", perPage)
  }
}

const setPage = (pageNumber: number | string) => {
  emit(CHANGE_PAGE_EVENT, {
    pageNumber: pageNumber,
    perPage: null,
  })

  if (!props.isNotHardPageReload) {
    router.push({ query: { page: pageNumber } })
    replaceQueryParams("page", String(pageNumber))
  }
}

const prevPage = () => {
  setPage(props.page - 1)
}
const nextPage = () => {
  setPage(props.page + 1)
}

const pages = computed(() => {
  return getPaginationItems(props.page, props.count, props.perPage)
})
</script>

<template>
  <div class="skills__paginator paginator">
    <div v-if="!props.hidePerPage" class="paginator__volume volume-menu">
      <span class="paginator__volume-label">Per page</span>
      <select
        class="paginator__volume-select"
        @change="setPerPage(($event.target as HTMLInputElement).value)"
      >
        <option
          v-for="(perPageNumber, index) in perPageList"
          :key="`paginator-pager_${perPageNumber}-${index}`"
          :selected="perPageNumber === props.perPage"
        >
          {{ perPageNumber }}
        </option>
      </select>
    </div>
    <div v-if="props.pageRange.length > 1" class="paginator__pager pager">
      <div class="pager__inner">
        <div
          v-for="(pageNumber, index) of pages"
          :key="`paginator-pagination_${pageNumber}-${index}`"
        >
          <div
            v-if="pageNumber === PAGINATION_BUTTON_PREV_KEY"
            class="pager__arrow"
            :class="{ disabled: props.page === 1 }"
            @click="prevPage()"
          >
            <svg width="24" height="24">
              <use xlink:href="#ic_chevron_left"></use>
            </svg>
          </div>
          <div
            v-else-if="pageNumber === PAGINATION_BUTTON_NEXT_KEY"
            class="pager__arrow"
            :class="{ disabled: props.page === props.pageRange.length }"
            @click="nextPage()"
          >
            <svg width="24" height="24">
              <use xlink:href="#ic_chevron_right"></use>
            </svg>
          </div>
          <div
            v-else-if="pageNumber === PAGINATION_DOTS_KEY"
            class="pager__page dots"
            :class="{ current: Number(pageNumber) === props.page }"
          >
            ...
          </div>
          <div
            v-else
            class="pager__page"
            :class="{ current: pageNumber === page }"
            @click="setPage(pageNumber)"
          >
            {{ pageNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
