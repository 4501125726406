<script setup lang="ts">
import Multiselect from "vue-multiselect"
import { computed } from "vue"

const props = defineProps<{
  options: any[]
  placeholder?: string
  required?: boolean
  requiredText?: string
  requiredCheck?: (modelValue: any) => boolean
  label?: string
  displayLabel?: string
  trackBy?: string
  customLabel?: (option: any) => string
  allowEmpty?: boolean
  preselectFirst?: boolean
}>()

const modelValue = defineModel<any>()

const isRequired = computed(() => {
  return (
    props?.required &&
    (props?.requiredCheck
      ? props?.requiredCheck(modelValue.value)
      : modelValue.value === "")
  )
})
</script>

<template>
  <div class="wrap">
    <div v-if="props.label" class="label">
      {{ props.label }}
    </div>
    <div :class="{ error__input: isRequired }">
      <multiselect
        v-model="modelValue"
        :options="props.options"
        :preselect-first="props.preselectFirst"
        :allow-empty="props.allowEmpty"
        :placeholder="props.placeholder"
        :custom-label="props?.customLabel"
        :label="props?.displayLabel"
        :track-by="props?.trackBy"
      >
      </multiselect>
      <!-- TODO: temp solution, fix v-if on option -->
      <select class="select-hidden" :required="required">
        <option></option>
        <option
          v-for="option of props.options"
          v-if="modelValue && props?.trackBy"
          :key="option.name"
          :value="option"
          :selected="option[props?.trackBy] == modelValue[props?.trackBy]"
        >
          {{ option }} ({{ option[props?.trackBy] }} /
          {{ modelValue[props?.trackBy] }})
        </option>
        <option
          v-for="option of props.options"
          v-else
          :key="`${option.name}-withoutTrack`"
          :value="option"
          :selected="option == modelValue"
        >
          {{ option }}
        </option>
      </select>
    </div>
    <!-- <div
      v-if="props.required"
      v-show="isRequired"
      class="error"
    >
      {{ requiredText ?? "Required" }}
    </div> -->
  </div>
</template>

<style scoped>
.wrap {
  flex: 1;
  word-break: break-all;
  position: relative;
}

.label {
  margin-bottom: 0.5rem;
}

/* .error__input {
  border: 1px solid red;
} */

.error {
  position: absolute;
  font-size: 14px;
  color: red;
}
.wrap > div {
  position: relative;
}

.select-hidden {
  position: absolute;
  top: 5px;
  left: 0px;
  z-index: -1;
  width: 1px;
}
</style>
