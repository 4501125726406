<script setup lang="ts">
import { computed, reactive, ref, toRaw } from "vue"
import { SignUp } from "@/types/user.ts"
import { email, required } from "@vuelidate/validators"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { container } from "@/services/container.ts"
import { useRouter } from "vue-router"
import FieldInput from "@/components/auth/FieldInput.vue"
import FieldPassword from "@/components/auth/FieldPassword.vue"
import FieldButton from "@/components/auth/FieldButton.vue"
import { passwordMatchErrorText } from "@/types/auth.ts"
import AgreementCheckbox from "@/components/auth/AgreementCheckbox.vue"

const router = useRouter()

const disabledSignUpBtn = ref(false)

const signup = reactive<SignUp>({
  first_name: "",
  last_name: "",
  email: "",
  workspace: "",
  password: "",
  confirm_password: "",
})

const $externalResults = ref<ServerErrors>({
  __all__: [],
})

const sameAsPassword = () => {
  return {
    $message: passwordMatchErrorText,
    $validator: () => signup.password === signup.confirm_password,
  }
}

const rules = {
  first_name: {
    required,
    $autoDirty: true,
  },
  last_name: {
    required,
    $autoDirty: true,
  },
  email: {
    required,
    email,
    $autoDirty: true,
  },
  workspace: {
    required,
    $autoDirty: true,
  },
  password: {
    required,
    $autoDirty: true,
  },
  confirm_password: {
    sameAs: sameAsPassword(),
    required,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, signup, { $externalResults })

const submit = async () => {
  disabledSignUpBtn.value = true
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    disabledSignUpBtn.value = false
    return
  }

  try {
    const result = await container.userRepository.signup(toRaw(signup))

    if (result?.status === "ok") {
      sessionStorage.setItem("signup_email", signup.email)
      await router.push({ name: "users-email-confirmation-sent" })
    } else {
      for (let key in result.error) {
        $externalResults.value[key] = result.error[key]
          ?.map((error: { message: string, code: string }) => error.message)
      }
    }
  } finally {
    disabledSignUpBtn.value = false
  }
}

const isValid = computed(() => {
  return validator.value.$errors.length > 0
})

</script>

<template>
  <form
    class="login__form"
    action=""
    method="post"
    @submit.prevent="submit"
  >
    <h1 class="login__title main-title">Sign Up</h1>

    <div
      v-for="(error, index) of $externalResults?.__all__"
      :key="index"
      class="validated-input__error-text"
      style="display:block;"
    >
      <div class="error-msg">
        {{ error }}
      </div>
    </div>

    <field-input
      v-model="signup.first_name"
      :errors="validator.first_name"
      name="first_name"
      placeholder="Name"
      :tabindex="1"
    />

    <field-input
      v-model="signup.last_name"
      :errors="validator.last_name"
      name="last_name"
      placeholder="Last name"
      :tabindex="2"
    />

    <field-input
      v-model="signup.email"
      :errors="validator.email"
      name="email"
      placeholder="Email"
      :tabindex="3"
    />

    <field-input
      v-model="signup.workspace"
      :errors="validator.workspace"
      name="workspace"
      placeholder="Workspace name"
      :tabindex="4"
    />

    <field-password
      v-model="signup.password"
      :errors="validator.password.$errors"
      name="password"
      placeholder="Password"
      :tabindex="5"
    />

    <field-password
      v-model="signup.confirm_password"
      :errors="validator.confirm_password.$errors"
      name="confirm_password"
      placeholder="Confirm password"
      :tabindex="6"
    />

    <agreement-checkbox :tabindex="7" />

    <field-button
      :is-valid="isValid"
      :disabled="disabledSignUpBtn"
      title="Sign up"
      :tabindex="8"
    />

    <div class="login__to-login">
      <span>Already have an account? </span>
      <router-link
        to="/users/login"
        class="login__login-link custom-link"
        data-tab-id="mates-runs"
        :tabindex="9"
      >
        Log In
      </router-link>
    </div>

  </form>
</template>
