<script setup lang="ts">
import { useRoute } from "vue-router"
import { userStore } from "@/store/user"
import { computed } from "vue"
import { ROUTE_NAMES } from "@/router/names.ts"

const LOGOUT = "/users/api/logout/"

const route = useRoute()
const store = userStore()
const userProfile = computed(() => store.userProfile)
</script>

<template>
  <menu v-if="userProfile" class="two-level-menu">
    <ul class="two-level-menu__list">
      <li class="two-level-menu__item">
        <button
          class="two-level-menu__button"
          onclick="this.parentElement.classList.toggle('active')"
        >
          <div class="person">
            <div class="person__avatar">
              <svg width="28" height="28" class="person__placeholder">
                <use xlink:href="#ic_person" />
              </svg>
            </div>
            <div class="person__info">
              <div v-if="userProfile.name" class="person__name">
                {{ userProfile.last_name }} {{ userProfile.first_name }}
              </div>
              <div v-else class="person__name">Not specified</div>
              <div class="person__login">{{ userProfile.email }}</div>
            </div>
          </div>
        </button>
        <div class="two-level-menu__inner">
          <ul class="two-level-menu__sub-list">
            <li class="two-level-menu__sub-item">
              <router-link
                to="/users/profile/settings/"
                class="two-level-menu__sub-button active"
              >
                <span>User settings</span>
              </router-link>
            </li>

            <template v-if="userProfile.admin">
              <li class="two-level-menu__sub-item">
                <router-link to="/users/admin/workspaces" class="two-level-menu__sub-button">
                  <span>Manage Workspaces</span>
                </router-link>
              </li>
              <li class="two-level-menu__sub-item">
                <router-link to="/users/admin/prices" class="two-level-menu__sub-button">
                  <span>Pricing</span>
                </router-link>
              </li>
              <li class="two-level-menu__sub-item">
                <router-link
                  :to="{ name: ROUTE_NAMES.ADMIN_GLOBAL_TEMPLATE_ACTION }"
                  class="two-level-menu__sub-button"
                >
                  <span>Global action templates</span>
                </router-link>
              </li>
              <li class="two-level-menu__sub-item">
                <router-link to="/users/admin/global-templates" class="two-level-menu__sub-button">
                  <span>Global skill templates</span>
                </router-link>
              </li>
            </template>

            <li class="two-level-menu__sub-item">
              <a
                :href="LOGOUT"
                class="two-level-menu__sub-button"
              >
                <span>Log out</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="two-level-menu__item">
        <button
          class="two-level-menu__button"
          onclick="this.parentElement.classList.toggle('active')"
        >
          <svg width="32" height="32">
            <use xlink:href="#ic_process" />
          </svg>
          <span class="two-level-menu__button-text">
            {{ route.params?.ws ?? "Not selected" }}
          </span>
          <svg width="24" height="24">
            <use xlink:href="#ic_chevron_down" />
          </svg>
        </button>

        <div
          v-for="workspace_item in userProfile.workspaces"
          :key="workspace_item.schema_name"
          class="two-level-menu__inner"
        >
          <ul class="two-level-menu__sub-list">
            <li class="two-level-menu__sub-item">
              <router-link
                :to="{ name: ROUTE_NAMES.SKILLS, params: { ws: workspace_item.schema_name } }"
                class="two-level-menu__sub-button active"
              >
                <span>{{ workspace_item.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </menu>
</template>
