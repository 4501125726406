<script setup lang="ts">
import { computed } from "vue"
import Multiselect from 'vue-multiselect'
import LabelForm from "@/components/actions/elements/LabelForm.vue"
import { SkillAction, SkillActionField } from "@/types/actions.ts"
import CompanyAttribute from "@/components/actions/companyBasicInfo/CompanyAttribute.vue"

const props = defineProps<{
  actionData: SkillAction
  availableFields: SkillActionField[]
}>()

const emit = defineEmits(["formEvent"])

const typeOptions = [
  "linkedin_url",
  "site_url",
]

const companyAttributes = [
  { name: "specialties", type: "string" },
  { name: "tagline", type: "string" },
  { name: "number_of_employees", type: "number" },
  { name: "linkedin_url", type: "linkedin_url" },
  { name: "location", type: "string" },
  { name: "website", type: "site_url" },
  { name: "name", type: "string" },
  { name: "domain", type: "site_url" },
  { name: "web_page_content", type: "file" },
]

const fieldOptions = computed(() => {
  return props.availableFields
    .filter(
      (field: SkillActionField) =>
        field.type === props.actionData.fields_source[0].type,
    )
    .map((field: SkillActionField) => field.name)
})

const companyAttributesOptions = computed(() =>
  companyAttributes.filter(
    (attribute: any) =>
      !props.actionData.fields_target.find(
        (field) => field.name === attribute.name,
      ),
  ),
)

const addAttribute = () => {
  emit("formEvent", "add-attribute")
}
const deleteAttribute = (index: number) => {
  emit("formEvent", "delete-attribute", index)
}

</script>

<template>
  <div class="action-form settings-block">
    <label-form label="linekdin/website url" description="" id="company-info-input-source-type">
      <multiselect v-model="props.actionData.fields_source[0].type" :options="typeOptions"></multiselect>
    </label-form>
    
    <label-form label="url" description="" id="company-info-input-source-name">
      <multiselect v-model="props.actionData.fields_source[0].name" :options="fieldOptions"></multiselect>
    </label-form>

    <label-form
      label="Attributes"
      description="Please select which attributes you need for company info"
    />

    <label-form label="" description="" id="company-attribute-list" class="test">
      <div
        v-for="(field, index) in props.actionData.fields_target"
        :key="field.name"
        :class="'company-attribute-list-item-' + index"
      >
        <company-attribute
          :attribute="field"
          :options="companyAttributesOptions"
          class="company-attribute"
        />
        <div
          v-if="props.actionData.fields_target.length > 1"
          class="settings-block__control-btn secondary-button"
          @click="deleteAttribute(index)"
        >
          <span>Delete</span>
        </div>
      </div>
      <br />
      <div
        class="settings-block__control-btn secondary-button"
        @click="addAttribute()"
      >
        <span>Add attribute</span>
      </div>
    </label-form>
  </div>
</template>

<style scoped>
  .company-attribute {
    display: inline-block;
    width: 400px;
  }
</style>