/**
 * Action management
 */

import { FilterOperators, SkillAction as SkillAction, SkillActionTemplate, TestRunActionResult } from "@/types/actions"
import { FieldsHubSpot, TypeEntityHubSpot } from "@/types/hubspot"
import { Paginator, ResponseApi, ResponseApiMessage } from "@/types/api.ts"
import { AxiosError, AxiosInstance } from "axios"
import { SkillAvailableActions } from "@/types/skill.ts"
import { notify } from "@kyvg/vue3-notification"

export class SkillActionRepository {
  constructor(private axios: AxiosInstance) {
  }

  async create(
    ws: string,
    skillId: string,
    skillAction: SkillAction,
  ): Promise<ResponseApi<ResponseApiMessage>> {
    let type = "success"
    let text = "The action has been successfully updated"

    try {
      const result = await this.axios.post(
        `/ws/${ws}/skills/api/${skillId}/actions/create`,
        skillAction,
      )

      return result.data
    } catch (error: unknown) {
      const result = error as AxiosError<ResponseApi<null>>
      type = "error"
      text = result?.response?.data.message?.toString() ?? ""

      throw error
    } finally {
      notify({
        type: type,
        text: text,
        title: "",
      })
    }
  }

  async edit(
    ws: string,
    skillId: string,
    skillAction: SkillAction,
  ): Promise<ResponseApi<ResponseApiMessage>> {
    let type = "success"
    let text = "The action was successfully created"

    try {
      const result = await this.axios.post(
        `/ws/${ws}/skills/api/${skillId}/actions/edit`,
        skillAction,
      )

      return result.data
    } catch (error: unknown) {
      const result = error as AxiosError<ResponseApi<null>>
      type = "error"
      text = result?.response?.data.message?.toString() ?? ""

      throw error
    } finally {
      notify({
        type: type,
        text: text,
        title: "",
      })
    }
  }

  async get(
    ws: string,
    skillId: string,
    actionId: string,
  ): Promise<SkillAction> {
    const res = await this.axios.get(
      `/ws/${ws}/skills/api/${skillId}/actions/${actionId}`,
    )
    return res.data.data
  }

  async filterOperators(ws: string): Promise<FilterOperators> {
    const res = await this.axios.get(`/ws/${ws}/skills/api/filter_operators`)
    return res.data.data.filter_operators
  }

  async fieldsHubSpot(
    ws: string,
    type: TypeEntityHubSpot = TypeEntityHubSpot.COMPANY,
  ): Promise<FieldsHubSpot> {
    const res = await this.axios.get(
      `/ws/${ws}/integrations/hubspot/properties/company?type=${type}`,
    )
    return res.data.results
  }

  /**
   * Test run of the action
   */
  async runTest(
    ws: string,
    actionConfig: SkillAction,
    inputData: string,
  ): Promise<TestRunActionResult> {
    const data = {
      action_config: actionConfig,
      input_data: inputData,
    }
    const res = await this.axios.post(
      `/ws/${ws}/skills/api/actions/run-test`,
      data,
    )

    return res.data
  }

  async listByTypeAction(
    ws: string,
    actionType: string,
    page = 1,
    perPage = 20,
    search: string = "",
  ): Promise<ResponseApi<Paginator<SkillActionTemplate>>> {
    const params: any = {
      page: page,
      per_page: perPage,
      type_action: actionType,
      search: search,
    }

    const query = (new URLSearchParams(params)).toString()
    const res = await this.axios.get(`/ws/${ws}/skills/api/list/actions?${query}`)

    return res.data
  }

  async listAvailableActions(ws: string): Promise<SkillAvailableActions[]> {
    const result = await this.axios.get(`/ws/${ws}/skills/api/actions/available/list`)

    return result.data.data
  }

  async saveActions(
    ws: string,
    skillId: string,
    actions: SkillAction[] | null,
  ): Promise<ResponseApi<ResponseApiMessage>> {
    const result = await this.axios.post(
      `/ws/${ws}/skills/api/${skillId}/actions/group/save`,
      {
        actions: actions,
      },
    )

    return result.data
  }
}
