<script setup lang="ts">

import { onMounted, ref } from "vue"
import IconCheck from "@/components/icons/IconCheck.vue"
import useUser from "@/compositions/useUser.ts"
import IconPlanLightning from "@/components/icons/IconPlanLightning.vue"
import IconPlanBlock from "@/components/icons/IconPlanBlock.vue"
import { Plan } from "@/types/settings.ts"
import SimpleLoader from "@/components/shared/SimpleLoader.vue"

const { currentWorkspace, user } = useUser()
const loader = ref(true)
const clientReferenceId = ref<string | null>(null)
const currentPrice = ref<{
  id: number,
  link: string,
  price: number,
} | null>(null)

const props = defineProps<{
  plan: Plan,
  index: string | number,
  currentSettings: any
}>()

onMounted(() => {
  clientReferenceId.value = `${currentWorkspace.value?.id}-${user.value.id}`

  if (props.plan.links) {
    currentPrice.value = props.plan.links[0]
  }

  setTimeout(() => {
    loader.value = false
  }, 10000)
})

</script>

<template>
  <div class="plan">
    <div>
      <div class="title">
        <div class="icon-wrap">
          <div class="icon">
            <template v-if="props.plan.icon === 'IconPlanLightning'">
              <icon-plan-lightning />
            </template>
            <template v-else-if="props.plan.icon === 'IconPlanBlock'">
              <icon-plan-block />
            </template>
          </div>
        </div>

        <div>
          {{ props.plan.name }}
        </div>

        <div
          v-if="props.plan.type === 'enterprise'"
          class="price"
        >
          <span class="big">
            Lets`s talk
          </span>
        </div>
        <div
          v-else
          class="price"
        >
          <span class="big">
            ${{ currentPrice?.price }}
          </span>
          <span class="small">
            /month
          </span>
        </div>

        <div class="prefix">
          {{ props.plan.description }}
        </div>
      </div>

      <div v-if="props.plan.links">
        <select
          v-if="props.plan.links"
          v-model="currentPrice"
          class="custom-select text-style"
        >
          <option
            v-for="(price, indexPrice) in props.plan.links"
            :key="`plan-price-${indexPrice}-${props.plan.name}`"
            :value="price"
          >
            {{ price.id }}
          </option>
        </select>
      </div>
      <div v-if="props.plan.input">
        <input
          class="custom-select text-style"
          type="text"
          :placeholder="props.plan.input.value"
          disabled
        >
      </div>
    </div>

    <div
      class="list"
      :class="{ 'latest--bold': props.plan.type !== 'base' }"
    >
      <div
        v-for="(list, indexPrice) in props.plan.list"
        :key="`plan-price-item-${indexPrice}-${props.plan.name}`"
        class="item"
      >
        <div>
          <icon-check />
        </div>
        <div>
          {{ list }}
        </div>
      </div>
    </div>

    <div v-if="!props.plan.btn">
      <div
        v-for="(price, indexPrice) in props.plan.links"
        :key="`plan-price-btn-${indexPrice}-${props.plan.name}`"
      >
        <div
          v-show="price.id === currentPrice?.id"
          class="wrap-btn"
        >
          <stripe-buy-button
            :buy-button-id="price.link"
            :publishable-key=props.currentSettings.publishableKey
            :client-reference-id="clientReferenceId"
          >
          </stripe-buy-button>
          <button class="talk-btn btn">
            <span v-if="!loader">Get started</span>
            <simple-loader v-else />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="talk-btn-wrap">
      <a
        :href="props.plan.btn.link"
        target="_blank"
        class="talk-btn"
      >
        {{ props.plan.btn.name }}
      </a>
    </div>
  </div>
</template>

<style scoped>
.plan {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 400px;
  background: #F9FAFB;
  border-radius: 8px;
  padding: 2rem;
}

.title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;

  .icon-wrap {
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;

    .icon {
      background: white;
      position: absolute;
      top: -55px;
      width: 40px;
      padding: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #EAECF0;
      border-radius: 8px;
    }
  }

  .price {
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;

    .big {
      font-size: 48px;
    }

    .small {
      font-size: 15px;
    }
  }

  .prefix {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0.5rem 0;
    color: #475467;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.latest--bold {
  .item:last-child {
    font-weight: 600;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.talk-btn-wrap {
  height: 48px;
}

.talk-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 44px;
  backface-visibility: hidden;
  border: 0;
  cursor: pointer;
  outline: 2px solid transparent;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-decoration: none;
  transform: translateZ(0);
  background-color: rgb(48, 132, 233);
  color: rgb(255, 255, 255);
  border-radius: 6px;
  font-weight: 600;
}

.wrap-btn {
  position: relative;
}

.btn {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
