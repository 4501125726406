<script setup lang="ts">

const props = withDefaults(defineProps<{
  width?: string,
  height?: string,
}>(), {
  width: "143",
  height: "61",
})

</script>

<template>
  <svg :width="props.width"
       :height="props.height"
       :viewBox='`0 0 ${props.width} ${props.height}`'
       xmlns="http://www.w3.org/2000/svg"
  >
    <g id="COLLEXTR">
      <path id="Vector" d="M55.8617 60.0361V0H60.6713V48.715V60.0361H55.8617Z" fill="url(#paint0_linear_2_17372)"
            fill-opacity="0.35" />
      <path id="Vector_2" d="M47.4376 60.0361V0H52.2472V48.715V60.0361H47.4376Z" fill="url(#paint1_linear_2_17372)"
            fill-opacity="0.35" />
      <path id="Vector_3"
            d="M143 40.4091H137.834L133.826 34.6198H133.589H129.403V40.4091H124.593V19.627H133.589C139.14 19.627 142.614 22.5068 142.614 27.1679C142.614 30.2852 141.041 32.5712 138.339 33.7291L143 40.4091ZM137.745 27.1679C137.745 24.8819 136.261 23.5459 133.321 23.5459H129.403V30.7899H133.321C136.261 30.7899 137.745 29.4242 137.745 27.1679Z"
            fill="black" />
      <path id="Vector_4" d="M110.545 40.4091V23.5459H103.895V19.627H122.005V23.5459H115.355V40.4091H110.545Z"
            fill="black" />
      <path id="Vector_5"
            d="M102.646 40.4091H97.0938L92.0764 33.1947L87.1481 40.4091H81.626L89.3154 29.8399L82.0119 19.627H87.4747L92.2545 26.3663L96.9454 19.627H102.141L94.8968 29.6617L102.646 40.4091Z"
            fill="black" />
      <path id="Vector_6"
            d="M69.0658 36.5495H80.3772V40.4091H64.2859V19.627H79.9913V23.4865H69.0658V27.9992H78.7147V31.7399H69.0658V36.5495Z"
            fill="black" />
      <path id="Vector_7" d="M55.8616 40.7657V19.271H60.6712V36.7124V40.7657H55.8616Z"
            fill="url(#paint2_linear_2_17372)" />
      <path id="Vector_8" d="M47.4376 40.7657V19.271H52.2471V36.7124V40.7657H47.4376Z"
            fill="url(#paint3_linear_2_17372)" />
      <path id="Vector_9"
            d="M32.4523 40.7656C25.8911 40.7656 21.0815 36.1936 21.0815 30.0183C21.0815 23.8431 25.8911 19.271 32.4523 19.271C38.9838 19.271 43.823 23.8134 43.823 30.0183C43.823 36.2233 38.9838 40.7656 32.4523 40.7656ZM32.4523 36.6686C36.1633 36.6686 38.9541 33.9669 38.9541 30.0183C38.9541 26.0697 36.1633 23.368 32.4523 23.368C28.7412 23.368 25.9504 26.0697 25.9504 30.0183C25.9504 33.9669 28.7412 36.6686 32.4523 36.6686Z"
            fill="black" />
      <path id="Vector_10"
            d="M11.252 40.7656C4.80957 40.7656 0 36.2826 0 30.0183C0 23.754 4.80957 19.271 11.2817 19.271C14.874 19.271 17.8726 20.5773 19.8321 22.9524L16.7444 25.8025C15.3491 24.1993 13.5974 23.368 11.5192 23.368C7.63 23.368 4.86895 26.0994 4.86895 30.0183C4.86895 33.9372 7.63 36.6686 11.5192 36.6686C13.5974 36.6686 15.3491 35.8373 16.7444 34.2044L19.8321 37.0545C17.8726 39.4593 14.874 40.7656 11.252 40.7656Z"
            fill="black" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2_17372" x1="58.2665" y1="0" x2="58.2665" y2="60.0361"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#5654ED" stop-opacity="0" />
        <stop offset="0.515625" stop-color="#5457EC" />
        <stop offset="1" stop-color="#5556ED" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_2_17372" x1="49.8424" y1="0" x2="49.8424" y2="60.0361"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#5654ED" stop-opacity="0" />
        <stop offset="0.515625" stop-color="#5457EC" />
        <stop offset="1" stop-color="#5556ED" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint2_linear_2_17372" x1="58.2664" y1="19.271" x2="58.2664" y2="40.7657"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#752BEF" />
        <stop offset="1" stop-color="#3084E9" />
      </linearGradient>
      <linearGradient id="paint3_linear_2_17372" x1="49.8423" y1="19.271" x2="49.8423" y2="40.7657"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#752BEF" />
        <stop offset="1" stop-color="#3084E9" />
      </linearGradient>
    </defs>
  </svg>

</template>