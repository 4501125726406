<script setup lang="ts">
const props = defineProps<{
  label: {
    title: string,
    description: string,
  },
  type?: string
  placeholder?: string,
  errors?: any
}>()
const modelValue = defineModel<string>()
</script>

<template>
  <div class="settings-block__row">
    <div class="settings-block__col settings-block__col--left">
      <div class="settings-block__attr sub-title">
        {{ props.label.title }}
      </div>
      <div class="settings-block__hint hint-text">
        {{ props.label.description }}
      </div>
    </div>
    <div class="settings-block__col settings-block__col--right">
      <div class="settings-block__row">
        <div class="settings-block__col settings-block__col--right">
          <div
            :class="{'error': props.errors?.$errors?.length }"
            class="settings-block__input-wrap validated-input"
          >
            <input
              v-model="modelValue"
              :type="props.type ?? 'text'"
              :placeholder="props.placeholder"
              required
              class="custom-input custom-input--small"
            />
            <div class="validated-input__error-text">
              <div
                v-for="error of props.errors?.$errors"
                :key="error.$uid"
                class="validated-input__error-text"
              >
                <div class="error-msg">
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

