<script setup lang="ts">
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { formatDate } from "@/compositions/useApp.ts"
import { container } from "@/services/container.js"
import { Skill } from "@/types/skill"
import SkillStatus from "@/components/skills/SkillStatus.vue"
import IconChevronDown from "@/components/icons/IconChevronDown.vue"
import IconSettings from "@/components/icons/IconSettings.vue"
import { openModal } from "jenesius-vue-modal"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import AddSkillGlobalTemplate from "@/components/modals/AddSkillGlobalTemplate.vue"
import { ROUTE_NAMES } from "@/router/names.ts"

const route = useRoute()
const router = useRouter()

const props = defineProps<{ skill: Skill }>()
const skillId = route.params.skillId as string
const ws = route.params.ws as string

const clickPublished = async () => {
  props.skill.published = !props.skill.published
  await container.skillRepository.setPublication(
    ws,
    skillId,
    props.skill.published,
  )
}

const showManagerList = ref(false)

const clickManager = () => {
  showManagerList.value = !showManagerList.value
}

const deleteSkill = async () => {
  const title = `Delete the skill «${props.skill.name}»?`
  const modal: any = await openModal(DeleteModal, { title: title })

  modal.on("confirm", async () => {
    const result = await container.skillRepository.deleteSkill(ws, skillId)

    if (result.status === 'ok') {
      await router.push({
        name: ROUTE_NAMES.SKILLS,
        params: { ws: ws },
      })
    }
  })
}

const addGlobalTemplate = async () => {
  await openModal(AddSkillGlobalTemplate, {skill: props.skill})
}
</script>

<template>
  <div class="skills__header skills__header--border">
    <div class="skills__info">
      <div class="skills__title-box">
        <h2 class="skills__title js-mate-name">
          {{ props.skill.name }}
        </h2>
        <div
          class="skills__title-switch"
          style="display: inline-flex; text-decoration: none"
        >
          <label
            class="custom-checkbox custom-checkbox--switch"
            title="Unpublish"
          >
            <input
              type="checkbox"
              class="custom-checkbox__input"
              tabindex="0"
              :checked="props.skill.published"
              @click="clickPublished"
            />
            <span class="custom-checkbox__text"></span>
          </label>
        </div>
      </div>

      <!--
        Status label
        it has several states:
        status-label--error  -  Error
        status-label--in-progress  -  In progress
        status-label--waiting  -  Waiting for user
        status-label--waiting  -  Paused
        status-label--completed  -  Completed
        status-label--unpublish  -  Unpublish
      -->

      <div
        v-if="!props.skill.published"
        class="skills__status status-label status-label--unpublish"
      >
        Unpublished
      </div>
      <template v-else-if="props.skill.lastJob">
        <skill-status :status="props.skill.lastJob.status" />
      </template>
      <p class="skills__description">
        {{ props.skill.description }}
      </p>

      <div class="skills__last-action">
        last updated on: {{ formatDate(skill.updatedAt) }}
      </div>
    </div>

    <div class="skills__action js-context-menu">
      <button
        class="skills__manager secondary-button js-context-menu__main-btn"
        @click="clickManager()"
      >
        <icon-settings width="16" height="16" />
        <span>Manage</span>
        <icon-chevron-down />
      </button>

      <div v-if="showManagerList" class="js-context-menu__list">
        <button
          class="js-context-menu__item"
          @click="clickPublished"
        >
          <span v-if="props.skill.published">
            Unpublished
          </span>
          <span v-else>
            Published
          </span>
        </button>

        <button
          class="js-context-menu__item js-delete-mate"
          @click="deleteSkill"
        >
          Delete Skills
        </button>

        <button
          class="js-context-menu__item js-delete-mate"
          @click="addGlobalTemplate"
        >
          Save as global template
        </button>

        <!--
        <button class="js-context-menu__item">Unpublish</button>
        <button class="js-context-menu__item">Delete Skill</button>
        -->
      </div>
    </div>
  </div>
</template>
<style>
.js-context-menu__list {
  z-index: 7;
  top: calc(100% + 8px);
  opacity: 1;
  visibility: visible;
}
</style>
