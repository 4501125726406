<script setup lang="ts">

import { useRoute } from "vue-router"
import { computed, onMounted } from "vue"
import BaseNav from "@/components/BaseNav.vue"
import IconWelcome from "@/components/icons/IconWelcome.vue"
import { ROUTE_NAMES } from "@/router/names.ts"

const route = useRoute()
const ws = computed(() => {
  return route.params?.ws.toString() ?? ""
})

const navItems = [
  {
    link: `/ws/${ws.value}/skills`,
    label: "Skills",
  },
  {
    link: null,
    label: "Welcome",
  },
]

onMounted(async () => {
})

</script>

<template>
  <main>
    <base-nav :items="navItems" icon="mates" />
    <div class="welcome">

      <div>
        <div>
          <b>
            🌟 Getting Started is a Breeze! 🌟
          </b>
        </div>
        <div>
          <b>

            To unlock the full potential of Collextr, we’ve crafted a streamlined video that will guide you through
            creating
            your first digital skill with ease.
          </b>
        </div>
      </div>

      <div class="img">
        <icon-welcome />
      </div>

      <div>
        <div>
          <b>

            🔗 Connect Your HubSpot:
          </b>
        </div>
        <div>
          <b>
            Seamlessly integrate your HubSpot account following the demo to start optimizing your processes immediately.
          </b>
        </div>
      </div>

      <div>
        <div>
          <b>
            🙋 Need a Helping Hand?
          </b>
        </div>
        <div>
          <b>
            Our friendly support team is just a chat away, ready to assist you with any questions or guidance you may
            need.
          </b>
        </div>
      </div>


      <router-link
        class="settings-block__control-btn primary-button button"
        :to="{ name: ROUTE_NAMES.SKILL_CREATE}"
      >
        <span>I’m ready to setup Skills</span>
      </router-link>
    </div>
  </main>
</template>

<style scoped>
.welcome {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    margin: 0.5rem 0;
  }

  .button {
    margin: 2rem 0;
  }
}
</style>