<script setup lang="ts">
import { onMounted, ref, shallowRef } from "vue"
import { useRoute } from "vue-router"
import BaseNav from "@/components/BaseNav.vue"
import CTable from "@/components/elements/CTable.vue"
import IconPlus from "@/components/icons/IconPlus.vue"
import { Modal, openModal, promptModal } from "jenesius-vue-modal"
import InviteUserWorkspaceModal from "@/components/modals/InviteUserWorkspaceModal.vue"
import { container } from "@/services/container.ts"
import ConfirmModal from "@/components/modals/ConfirmModal.vue"
import iconDelete from "@/components/icons/IconDelete.vue"
import { UserInvited } from "@/types/workspace.ts"

const route = useRoute()
const ws = route.params.ws as string

const navItems = [
  {
    link: `/ws/${ws}/settings`,
    label: "Manage Workspace",
  },
  {
    link: null,
    label: "Users",
  },
]

const data = ref<UserInvited[]>([])

const columns = [
  { key: "user_name", label: "User name" },
  { key: "status", label: "Status" },
  { key: "role", label: "Role" },
  { key: "email", label: "User Email" },
]

onMounted(async () => {
  await loadData()
})

const deleteInvite = async (row: object) => {
  const data = row as {
    id: string
    user_name: string
  }

  const title = `Delete user ${data.user_name}?`

  let isDelete = (await promptModal(ConfirmModal, {
    title: title,
    buttonConfirmationText: "Delete",
    classCSSButtonConfirmation: "cancel-button",
    classCSSButtonRejection: "secondary-button",
    iconComponent: shallowRef(iconDelete),
  })) as boolean

  if (isDelete) {
    await container.workspaceRepository.kickUserInvite(ws, data.id)
    await loadData()
  }
}

const sendInvite = async () => {
  const modal: Modal = await openModal(InviteUserWorkspaceModal)
  modal.on("confirm", async (email: string) => {
    const result = await container.workspaceRepository.sendUserInvite(ws, email)

    if (result.status === 'ok') {
      await loadData()
    }
  })
}

const loadData = async () => {
  data.value = await container.workspaceRepository.getUsersInvited(ws)
}

</script>

<template>
  <div>
    <base-nav :items="navItems" icon="settings" />

    <h2>USERS</h2>
    <div class="settings-block__controls">
      <button
        id="workspace-users-btn-invite"
        class="settings-block__control-btn primary-button"
        @click="sendInvite"
      >
        <icon-plus />
        <span>Invite new user</span>
      </button>
    </div>

    <div class="wrap-table">
      <c-table
        :columns="columns"
        :items="data"
        :delete-handler="deleteInvite"
      />
    </div>
  </div>
</template>
<style scoped>
.wrap-table {
  margin-top: 2rem;
}
</style>