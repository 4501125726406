<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted, ref } from "vue"

const route = useRoute()
const title = ref("Email confirmation error")
const text = ref("The email confirmation link is invalid or expired.")

onMounted(async () => {
  if (route.name === "users-email-confirmation-successfully") {
    title.value = "The email has been confirmed"
    text.value = "Your email address has been successfully verified. <br><br> Thank you for registering!"
  }
})

</script>

<template>
  <div class="email__body">
    <div class="email__form">

      <h1 class="email__title main-title">
        {{ title }}
      </h1>

      <div class="email__field">
        <div
          class="email__field-text"
          v-html="text"
        >
        </div>
      </div>

      <div class="email__to-login">
        <span>Already have an account? </span>
        <router-link
          to="/users/login"
          class="login__login-link custom-link"
          data-tab-id="mates-runs"
        >
          Log In
        </router-link>
      </div>

    </div>
  </div>
</template>

<style scoped>
.email__field-text {
  display: block;
  color: black
}
</style>