<script setup lang="ts">
import { computed } from "vue"
import LabelForm from "@/components/actions/elements/LabelForm.vue"
import { SkillAction, SkillActionField } from "@/types/actions.ts"
import CInputText from "@/components/elements/CInputText.vue"
import InputMultiselect from "@/components/actions/elements/InputMultiselect.vue"

const props = defineProps<{
  actionData: SkillAction
  availableFields: SkillActionField[]
}>()

const fieldOptions = computed(() => {
  return props.availableFields
    .filter(
      (field: SkillActionField) => {
        return field.type === props.actionData.fields_source[0].type
      },
    )
    .map((field: SkillActionField) => field.name)
})

</script>

<template>
  <div class="action-form settings-block">
    <label-form
      id="company-crawler-web-page-url"
      label="web page url"
      description="Select the Field that contains the page to crawl"
    >
      <input-multiselect
        v-model="props.actionData.fields_source[0].name"
        :options="fieldOptions"
        :required="true"
        :allow-empty="false"
        :preselect-first="true"
      />
    </label-form>

    <c-input-text
      v-model="props.actionData.fields_target[0].name"
      label="save to"
      description="Type the filed name to save the web page"
      :required="true"
    />

  </div>
</template>
