import { UserProfile } from "@/types/user"
import { defineStore } from "pinia"
import { workspaceDefault } from "@/types/workspace.ts"

interface UserState {
  isAuthorized: boolean
  userProfile: UserProfile
}

export const userStore = defineStore("user", {
  state: (): UserState => {
    return {
      isAuthorized: false,
      userProfile: {
        id: 0,
        name: "",
        email: "",
        admin: false,
        last_name: "Not specified",
        first_name: "",
        is_authorized: false,
        workspaces: [
          workspaceDefault,
        ],
      },
    }
  },
  getters: {
    getUserProfile: (state) => state.userProfile,
  },
})
