<script setup lang="ts">

import { container } from "@/services/container.ts"
import { ref } from "vue"
import SimpleLoader from "@/components/shared/SimpleLoader.vue"
import { useRoute } from "vue-router"

const loadingIntegrations = ref(false)
const route = useRoute()
const ws = route.params.ws as string

const props = defineProps<{
  text?: string,
  type?: string,
}>()

const linkToBillingStripe = async () => {
  try {
    loadingIntegrations.value = true

    const result = await container.integrationRepository.getStripeManagementBillingCustomer(ws)

    if (result.status === "ok") {
      const url = result.data.url
      window.open(url, "_blank")
    }
  } finally {
    loadingIntegrations.value = false
  }
}

</script>

<template>
  <template v-if="props.type === 'link'">
    <a
      v-if="loadingIntegrations"
      href="#"
      class="loader_wrap"
      style="display: unset"
    >
      <simple-loader  />
    </a>
    <a
      v-else
      href="#"
      @click="linkToBillingStripe"
    >
      {{ props.text ?? "Manage Billing Details" }}
    </a>
  </template>
  <template v-else>
    <button
      v-if="loadingIntegrations"
      class="skills__control-btn primary-button loader_wrap"
    >
      <simple-loader />
    </button>
    <button
      v-else
      class="skills__control-btn primary-button"
      @click="linkToBillingStripe"
    >
      {{ props.text ?? "Manage Billing Details" }}
    </button>
  </template>
</template>

<style scoped>
.loader_wrap {
  display: flex;
  justify-content: center;
  width: 250px;
}
</style>