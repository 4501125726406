<script setup lang="ts">

import { computed } from "vue"
import { getStatus } from "@/utils/subscription.ts"
import moment from "moment/moment"
import useUser from "@/compositions/useUser.ts"

const { currentWorkspace } = useUser()

const getStatusActivated = computed(() => {
  return getStatus(currentWorkspace.value)
})

const datePlanRenewal = computed(() => {
  if (!currentWorkspace.value?.date_became_churned) {
    return "-"
  }

  return moment(currentWorkspace.value?.date_became_churned).format("DD.MM.YYYY")
})

const dateBulkMinutes = computed(() => {
  if (!currentWorkspace.value?.expiration_date_additional_operations) {
    return "-"
  }

  return moment(currentWorkspace.value?.expiration_date_additional_operations).format("DD.MM.YYYY")
})

const bulkMinutes = computed(() => {
  if (currentWorkspace.value?.number_additional_operations === 0) {
    return 0
  }

  return `${currentWorkspace.value?.number_additional_operations} Operations, ${currentWorkspace.value?.number_additional_operations_available} available`
})

</script>
<template>
  <div class="subscription">
    <div>
      <span>Status:</span>
      {{ getStatusActivated }}
    </div>
    <div>
      <span>Plan:</span>
      {{ currentWorkspace?.plan }},
      {{ currentWorkspace?.rows_included_in_plan }} Operations,
      {{ currentWorkspace?.rows_consumed_within_plan_in_current_period }} available
    </div>
    <div>
      <span>Plan Renewal:</span>
      {{ datePlanRenewal }}
    </div>
    <div>
      <span>Bulk Minutes:</span>
      {{ bulkMinutes }}
    </div>
    <div>
      <span>Bulk Minutes available until:</span>
      {{ dateBulkMinutes }}
    </div>
  </div>
</template>

<style scoped>
.subscription {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 3rem 0 2rem 0;

  div span {
    font-weight: bold;
  }
}
</style>