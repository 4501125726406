<script setup lang="ts">

import { RouteParamsRaw } from "vue-router"

const props = defineProps<{
  isActive: boolean
  routeName: string
  routeParams: RouteParamsRaw
  title: string
}>()

</script>

<template>
  <li class="two-level-menu__sub-item">
    <router-link
      :class="{ 'active': isActive }"
      :to="{name: props.routeName, params: props.routeParams}"
      class="two-level-menu__sub-button "
    >
      <span>{{ props.title }}</span>
    </router-link>
  </li>
</template>

<style scoped>

</style>