import { defineStore } from "pinia"
import { Skill } from "@/types/skill"

interface SkillState {
  skill: Skill | null
}

export const skillStore = defineStore("skill", {
  state: (): SkillState => {
    return {
      skill: null,
    }
  },
  getters: {
    getSkill: (state) => state.skill,
  },
})
