<script setup lang="ts">
import IconSettings from "@/components/icons/IconSettings.vue"
import IconMates from "@/components/icons/IconMates.vue"
import IconChevronRight from "@/components/icons/IconChevronRight.vue"

type itemType = {
  link: string | null | undefined
  label: string | null | undefined
}

const props = defineProps<{
  items: itemType[],
  icon: string
}>()

const showChevron = (index: number) => {
  return index < props.items.length - 1
}

const getLink = (item: itemType) => {
  return item.link ? item.link : undefined
}

</script>

<template>
  <nav aria-label="Breadcrumb" class="breadcrumbs skills__breadcrumbs">
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item">
        <a
          href=""
          class="breadcrumbs__link"
        >
          <icon-mates v-if="props.icon === 'mates'"/>
          <icon-settings v-if="props.icon === 'settings'"/>
        </a>
        <icon-chevron-right />
      </li>

      <li
        v-for="(item, index) in props.items"
        :key="`breadcrumbs__item_${index}_${item.link}`"
        class="breadcrumbs__item"
      >
        <a
          :href="getLink(item)"
          :class="{bold: !getLink(item)}"
          class="breadcrumbs__link"
        >
          {{ item.label }}
        </a>

        <icon-chevron-right v-if="showChevron(index)" />
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.bold {
  font-weight: bold;
}
</style>