<script setup lang="ts">

const modelValue = defineModel<boolean>()

</script>

<template>
  <div class="checkbox">
    <div class="checkbox__title">Overwrite</div>
    <div class="checkbox__body">
      <input
        v-model="modelValue"
        style=""
        type="checkbox"
      >
    </div>
  </div>
</template>

<style scoped>
.checkbox {
  .checkbox__title {
    margin-bottom: 0.5rem;
  }

  .checkbox__body {
    width: 43px;
    height: 43px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;

    input {
      width: 23px
    }
  }
}
</style>