import { createApp } from "vue"
import { createPinia } from "pinia"
import * as Sentry from "@sentry/vue"
import "./style.css"
import "vue-multiselect/dist/vue-multiselect.css"
import App from "@/App.vue"
import router from "@/router"
import { initComponents } from "@/components/actions"
import Notifications from '@kyvg/vue3-notification'

const pinia = createPinia()

const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://6c560904e1dd240e7c26bd31cac6cc33@sentry.collextr.com/3",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(pinia).use(router).use(initComponents).use(Notifications).mount("#app")
