import { UserItem } from "@/types/user"


export interface Workspace {
  id: number
  name: string
  schema_name: string
  created_by_id: number
  created_at: string
  created_by?: UserItem
  plan?: string,
  status?: string,
  company_url?: string,
  date_became_grace: string | null,
  date_became_churned: string | null,
  is_allowed_period_now: boolean | null,
  rows_included_in_plan: number | null,
  rows_consumed_within_plan_in_current_period: number | null,
  number_additional_operations: number,
  number_additional_operations_available: number,
  expiration_date_additional_operations: string | null
  subscription?: {
    id: number
    stripe_id: string
    customer: {
      customer_name: string
      email: string
      canceled_at: string | null
      id: string
    }
  }
}

export const workspaceDefault: Workspace = {
  id: 0,
  name: "",
  schema_name: "ws",
  created_by_id: 0,
  created_at: "",
  date_became_grace: null,
  date_became_churned: null,
  is_allowed_period_now: false,
  rows_included_in_plan: null,
  rows_consumed_within_plan_in_current_period: null,
  number_additional_operations: 0,
  number_additional_operations_available: 0,
  expiration_date_additional_operations: null,
}

export interface UserInvited {
  email: string
  id: null | string
  role: string
  status: string
  user_name: string
}

export enum WorkspaceStatusSubscription {
  NEW = "NEW",
  FREE_PLAN = "FREE_PLAN",
  PAYING_CUSTOMER = "PAYING_CUSTOMER",
  GRACE_PERIOD = "GRACE_PERIOD",
  CHURNED_CUSTOMER = "CHURNED_CUSTOMER",
  GRACE_PERIOD_OVER = "GRACE_PERIOD_OVER",
}
