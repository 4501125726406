<script setup lang="ts">
import { ref } from "vue"
import { closeModal } from "jenesius-vue-modal"

const CONFIRMATION_MESSAGE_DELETE = "Delete"

const props = defineProps<{
  title: string
}>()

const emit = defineEmits(["confirm", "cancel"])

const currentInputText = ref("")
const canDelete = ref(false)

const valueInput = () => {
  canDelete.value = currentInputText.value === CONFIRMATION_MESSAGE_DELETE;
}
const clickDelete = () => {
  closeModal()
  emit("confirm")
}

const clickCancel = () => {
  closeModal()
  emit("cancel")
}
</script>

<template>
  <div class="custom-pop-up__content custom-alert__content">
    <button class="custom-pop-up__close js-custom-alert-close" @click="clickCancel"></button>
    <div class="custom-alert__body">
      <div class="custom-alert__icon js-custom-alert-icon">
        <svg width="24" height="24">
          <use xlink:href="#ic_delete"></use>
        </svg>
      </div>
      <div class="custom-alert__message">{{ props.title }}</div>
      <div class="custom-alert__check-block">
        <div class="custom-alert__check-text">
          Type «Delete» to delete
        </div>
        <div class="validated-input">
          <input
            v-model="currentInputText"
            :placeholder="CONFIRMATION_MESSAGE_DELETE"
            id="delete-modal-input"
            class="custom-alert__check-input custom-input"
            @input="valueInput()"
          />
        </div>
      </div>
    </div>
    <div class="custom-alert__footer js-custom-alert-footer">
      <button
        class="custom-alert__cansel secondary-button js-custom-alert-cancel"
        type="button"
        @click="clickCancel"
      >
        Cancel
      </button>
      <button
        class="custom-alert__confirm primary-button js-custom-alert-confirm"
        id="delete-modal-button-delete"
        type="button"
        :disabled="!canDelete"
        @click="clickDelete"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<style scoped></style>
