import { Token, TOKEN_KIND_LIST, TOKEN_SPACE } from "@/components/actions/filter/condition/token"

/**
 * Lexer for condition formula
 */
export class Lexer {
  pos: number = 0
  inputStr: string
  tokens: Token[]

  constructor(inputStr: string) {
    this.inputStr = inputStr
    this.tokens = []
  }

  public analysis() {
    let index = 0
    while (true) {
      const con = this.nextToken()
      if (!con) {
        break
      }
      index += 1
      if (index > 100) {
        break
      }
    }
  }

  private nextToken(): boolean {
    if (this.pos >= this.inputStr.length) {
      return false
    }

    for (const tokenType of TOKEN_KIND_LIST) {
      const inputStr = this.inputStr.substring(this.pos)
      const regex = "^" + tokenType.regex
      const match = inputStr.match(regex)
      if (match === null) {
        continue
      }
      const tokenStr = match[0]
      if (tokenStr.length > 0) {
        const token: Token = {
          kind: tokenType,
          text: tokenStr,
          pos: this.pos,
        }
        this.pos = this.pos + tokenStr.length
        if (token.kind !== TOKEN_SPACE) {
          this.tokens.push(token)
        }

        return true
      }

    }

    return false
  }
}
