<script setup lang="ts">
import { onMounted, ref, toRaw } from "vue"
import IconDragMove from "@/components/icons/IconDragMove.vue"
import { useRoute } from "vue-router"
import { SkillAction } from "@/types/actions.ts"
import { container } from "@/services/container.ts"
import { container as WidgetContainerModal } from "jenesius-vue-modal"
import { SkillTemplate } from "@/types/skill"
import BaseNav from "@/components/BaseNav.vue"

const route = useRoute()

const templateId = Number(route.params.templateId)

const actions = ref<SkillAction[]>([])

const data = ref<SkillTemplate | null>(null)

const navItems = [
  {
    link: "/users/admin/global-templates",
    label: "Global Skills templates",
  },
  {
    link: null,
    label: "",
  },
]

onMounted(async () => {
  data.value =
    await container.skillAdminRepository.getGlobalTemplate(templateId)

  navItems[1].label = data.value.name

  const currentActionSkill = <SkillAction[]>(data?.value.actions ?? [])
  const actionsRaw = toRaw(currentActionSkill)

  actions.value = structuredClone(actionsRaw)
})
</script>

<template>
  <main v-if="data">
    <base-nav :items="navItems" icon="settings" />
    <div class="skills__body">
      <div class="skills__block">
        <section class="skills__card-list">
          <article
            v-for="element of actions"
            class="skills__action-card action-card"
          >
            <div class="action-card__header">
              <h3 class="action-card__title">
                <span class="action-card__title-icon">
                  <icon-drag-move />
                </span>
                <span>{{ element.type }}</span>
              </h3>
            </div>

            <div class="action-card__body">
              <div class="action-card__sub-title">
                <router-link
                  :to="{
                    name: 'global-template-action',
                    params: {
                        templateId: templateId,
                        actionId: element.id,
                    }
                  }"
                  class="action-card__control-btn icon-button"
                >
                  <span class="sub-title">
                    {{ element.title || element.name }}
                  </span>
                </router-link>
                <span v-if="element.copilot" class="action-card__label">
                  Co-pilot
                </span>
              </div>
              <div class="action-card__text">
                {{ element.description }}
              </div>
              <div class="action-card__error">
                <!--  error detail -->
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>

    <widget-container-modal />
  </main>
</template>

<style scoped>
.skills__body {
  margin-top: 30px;
}
</style>
