<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useDebounceFn } from "@vueuse/core"
import CTabs from "@/components/elements/CTabs.vue"
import CTable from "@/components/elements/CTable.vue"
import { Paginator } from "@/types/api"
import { Skill } from "@/types/skill"
import PagePaginator from "@/components/PagePaginator.vue"
import { container } from "@/services/container"
import { closeModal } from "jenesius-vue-modal"

const TIMEOUT_SEARCH = 500

const emit = defineEmits(["confirm", "cancel"])

const route = useRoute()

const ws = route.params.ws as string

const tabs = [
  {
    value: "global",
    label: "Global Templates",
  },
  {
    value: "my",
    label: "My Skills",
  },
]

const columns = [
  { key: "name", label: "Skill name" },
  { key: "description", label: "Description" },
]

const activeTab = ref("my")

const searchText = ref("")

const page = ref(1)
let perPage = ref(5)
const data = ref<Paginator<Skill> | null>(null)

const tableData = computed(() => {
  if (!data.value) {
    return []
  } else {
    return data.value.items.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
    }))
  }
})

const getSkills = async () => {
  const activeGlobal = activeTab.value === "global"
  const activeMy = activeTab.value === "my"
  const activeSearch = searchText.value.length > 0

  if (activeGlobal) {
    if (activeSearch) {
      data.value = await container.skillRepository.searchGlobalTemplates(
        ws,
        searchText.value,
        page.value,
        perPage.value,
      )
    } else {
      data.value = await container.skillRepository.listGlobalTemplates(
        ws,
        page.value,
        perPage.value,
      )
    }
  }
  if (activeMy) {
    if (activeSearch) {
      data.value = await container.skillRepository.search(
        ws,
        searchText.value,
        page.value,
        perPage.value,
      )
    } else {
      data.value = await container.skillRepository.list(
        ws,
        page.value,
        perPage.value,
      )
    }
  }
}

watch(activeTab, async () => {
  await getSkills()
})

const onSetTab = (value: string) => {
  activeTab.value = value
}

const onSearch = useDebounceFn(() => {
  getSkills()
}, TIMEOUT_SEARCH)

const changePage = async (value: { pageNumber: number }) => {
  page.value = value.pageNumber
  getSkills()
}

const click = (item: { id: string }) => {
  closeModal()
  const activeGlobal = activeTab.value === "global"
  let skillTemplate  
  if (activeGlobal) {
    skillTemplate = container.skillRepository.getGlobal(ws, item.id)
  } else {
    skillTemplate = container.skillRepository.get(ws, item.id)
  }
  
  emit("confirm", skillTemplate)
}

onMounted(async () => {
  await getSkills()
})
</script>

<template>
  <div class="custom-pop-up__content add-action-form" id="new-action-modal">
    <div class="add-action-form__header">
      <div>
        <c-tabs :items="tabs" :active="activeTab" @click="onSetTab" />
        <input
          v-model="searchText"
          type="text"
          placeholder="Start Typing to search"
          class="search-input__input"
          @input="onSearch"
        />
      </div>
    </div>
    <div v-if="data" class="custom-alert__body">
      <c-table
        :items="tableData"
        :columns="columns"
        class="skill-template-table"
        @click="click"
      />

      <template v-if="data.num_pages > 1">
        <page-paginator
          :page="page"
          :per-page="perPage"
          :page-range="data.page_range"
          :count="data.count"
          :isNotHardPageReload="true"
          @changePage="changePage"
        />
      </template>
    </div>
  </div>
</template>

<style>
.search-input__input {
  padding: 7px 7px 7px 14px;
  font-size: 16px;
  height: 56px;
  width: 100vw;
  max-width: 443px;
}
</style>
