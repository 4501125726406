import useProjectSettings from "@/compositions/useProjectSettings.ts"
import { UserProfile } from "@/types/user.ts"
import { ComputedRef } from "vue"
import { Workspace } from "@/types/workspace.ts"

export const initHubspotTracking = async () => {
  const { isProd, isDev } = useProjectSettings()

  let url = null

  if (isProd()) {
    url = "//js-eu1.hs-scripts.com/143563230.js"
  } else if (isDev()) {
    url = "//js-eu1.hs-scripts.com/144415014.js"
  }

  if (document.querySelector("#hs-script-loader") || !url) {
    return
  }

  const script = document.createElement("script")
  script.src = url
  script.id = "hs-script-loader"
  document.body.appendChild(script)
}

export const initHubspotForm = async (
  user: ComputedRef<UserProfile>,
  currentWorkspace: ComputedRef<Workspace | undefined>,
) => {
  const { isProd, isDev } = useProjectSettings()

  let data = null

  if (isProd()) {
    data = {
      region: "eu1",
      portalId: "143563230",
      formId: "883a091c-05c4-4324-a414-a81a58150d7e",
    }

  } else if (isDev()) {
    data = {
      region: "eu1",
      portalId: "144415014",
      formId: "ec7c937e-a0a9-4b3d-8b63-e4b63992e8f2",
    }
  }

  if (!data) {
    return
  }

  document.querySelectorAll(".hbspt-form").forEach(el => {
    el.remove()
  })

  // @ts-ignore
  const result = hbspt.forms.create(data) as { instanceId: string }

  const idTimerSearchFormWrap = setInterval(() => {
    const form_wrap = window.document.querySelector(`#hbspt-form-${result.instanceId}`) as HTMLDivElement

    if (form_wrap) {
      form_wrap.style.display = "none"
      clearTimeout(idTimerSearchFormWrap)

      const idTimerSearchForm = setInterval(() => {
        const form = form_wrap.querySelector("form") as HTMLFormElement

        if (form) {
          clearTimeout(idTimerSearchForm)

          const email = form.querySelector(`input[name="email"]`) as HTMLInputElement
          if (email) {
            email.value = user.value.email
            email.dispatchEvent(new Event("input", { bubbles: true }))
          }

          const firstname = form.querySelector(`input[name="firstname"]`) as HTMLInputElement
          if (firstname) {
            firstname.value = user.value.first_name
            firstname.dispatchEvent(new Event("input", { bubbles: true }))
          }

          const lastname = form.querySelector(`input[name="lastname"]`) as HTMLInputElement
          if (lastname) {
            lastname.value = user.value.last_name
            lastname.dispatchEvent(new Event("input", { bubbles: true }))
          }

          const workspace_name = form.querySelector(`input[name="workspace_name"]`) as HTMLInputElement
          if (workspace_name && currentWorkspace) {
            workspace_name.value = currentWorkspace.value?.name ?? ""
            workspace_name.dispatchEvent(new Event("input", { bubbles: true }))
          }

          const workspace_id = form.querySelector(`input[name="workspace_id"]`) as HTMLInputElement
          if (workspace_id && currentWorkspace) {
            workspace_id.value = currentWorkspace.value?.id.toString() ?? ""
            workspace_id.dispatchEvent(new Event("input", { bubbles: true }))
          }

          const workspace_website_url = form.querySelector(`input[name="workspace_website_url"]`) as HTMLInputElement
          if (workspace_website_url && currentWorkspace) {
            workspace_website_url.value = currentWorkspace.value?.company_url ?? ""
            workspace_website_url.dispatchEvent(new Event("input", { bubbles: true }))
          }

          console.log([
            firstname.value,
            lastname.value,
            workspace_name.value,
            workspace_id.value,
          ])

          form.submit()
        }

      }, 100)
    }
  }, 100)
}
