<script setup lang="ts">
import { useRoute, useRouter } from "vue-router"
import { container } from "@/services/container.ts"
import { SkillJobItem } from "@/types/jobs.ts"
import { Paginator } from "@/types/api.ts"
import { onMounted, ref } from "vue"
import useSkill from "@/compositions/useSkill.ts"
import SkillNav from "@/components/skills/SkillNav.vue"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import JobCreateModal from "@/components/modals/JobCreateModal.vue"
import { closeModal, openModal } from "jenesius-vue-modal"
import PagePaginator from "@/components/PagePaginator.vue"

const route = useRoute()
const router = useRouter()
const { skill, initSkill } = useSkill()

const ws = route.params.ws as string
const skillId = route.params.skillId as string

const DEFAULT_PER_PAGE_COUNT = 10
const DEFAULT_PAGE = 1

let page = Number(route.query.page || DEFAULT_PAGE)
let perPage = Number(route.query.per_page || DEFAULT_PER_PAGE_COUNT)

const isLoading = ref(false)
const dataJobs = ref<Paginator<SkillJobItem> | null>(null)

const getJobs = async () => {
  dataJobs.value = await container.skillJobRepository.jobs(
    ws,
    skillId,
    page,
    perPage,
  )
}

const goJobView = (event: any, jobId: string) => {
  const offsetParent = event.target.parentElement.offsetParent
  if (offsetParent && offsetParent.className !== "runs-table__td--btn") {
    router.push({
      name: "job-view",
      params: { ws: ws, skillId: skillId, jobId: jobId },
    })
  }
}

const jobDelete = async (jobId: string, runName: string) => {
  const title = `Delete the run «${runName}»?`
  const modal: any = await openModal(DeleteModal, { title: title })
  modal.on("confirm", async () => {
    await container.skillJobRepository.delete(ws, skillId, jobId)
    await getJobs()
  })
}

const updateStatusesJobs = () => {
  setTimeout(async () => {
    const activeJobs = dataJobs.value?.items.filter((item) =>
      ["RUNNING", "CREATED"].includes(item.status),
    )
    if (activeJobs && activeJobs?.length > 0) {
      await getJobs()
    }
  }, 5000)
}

const jobCreateModal = async () => {
  const errorBackend = ref<string>('')
  const modal: any = await openModal(JobCreateModal, { errorBackend: errorBackend, isLoading: isLoading })
  modal.on("confirm", async (data: string) => {
    try {
      errorBackend.value = ''
      isLoading.value = true

      const result = await container.skillJobRepository.create(ws, skillId, data)

      if (result.status === 'ok') {
        await closeModal()
        await getJobs()
        updateStatusesJobs()
      } else {
        errorBackend.value = <string>result.data.message
      }

    } finally {
      isLoading.value = false
    }
  })
}

onMounted(async () => {
  await initSkill()
  await getJobs()
})
</script>

<template>
  <main v-if="skill">
    <skill-nav :skill="skill" type="actions" />
    <skill-header :skill="skill" />
    <div class="skills__body">
      <skill-tabs :skill="skill" type="runs">
        <button
          class="skills__control-btn primary-button"
          type="submit"
          :disabled="!skill.published"
          @click="jobCreateModal"
        >
          <svg width="16" height="16">
            <use xlink:href="#ic_plus"></use>
          </svg>
          <span>Initiate new Run</span>
        </button>
      </skill-tabs>
      <div id="mates-runs" class="skills__block">
        <section
          v-if="dataJobs"
          class="skills__runs runs-table js-runs-table"
          data-delete-action="/delete-run"
        >
          <table class="runs-table__table">
            <thead>
              <tr>
                <th>Run name</th>
                <th>Date</th>
                <th>Status</th>
                <th>Steps</th>
                <th>Last action result</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="job of dataJobs.items"
                :key="job.id"
                @click="goJobView($event, job.id)"
                data-href="/test"
                data-deleted-url="/test2"
              >
                <td>
                  {{ job.process_name }}
                </td>
                <td>
                  {{ job.created_at }}
                </td>
                <td>
                  <div
                    v-if="job.status === 'RUNNING'"
                    class="status-label status-label--in-progress"
                  >
                    In progress
                  </div>
                  <div
                    v-else-if="job.status === 'FAULTED'"
                    class="status-label status-label--error"
                  >
                    Error
                  </div>
                  <div
                    v-else-if="job.status === 'WAITING'"
                    class="status-label status-label--waiting"
                  >
                    Waiting for user
                  </div>
                  <div
                    v-else-if="job.status === 'STOPPED'"
                    class="status-label status-label--waiting"
                  >
                    Paused
                  </div>
                  <div
                    v-else-if="job.status === 'COMPLETE'"
                    class="status-label status-label--completed"
                  >
                    Completed
                  </div>
                  <div
                    v-else-if="job.status === 'EMPTY'"
                    class="status-label status-label--error"
                  >
                    Error
                  </div>
                  <div
                    v-if="job.number_warnings > 0"
                    class="status-label status-label--waiting"
                  >
                    {{ job.number_warnings }}
                  </div>
                </td>
                <td>
                  {{ job.actions_status }}
                </td>
                <td>
                  <div
                    v-if="
                      job.last_action !== null &&
                      (job.status == 'COMPLETE' ||
                        job.status == 'STOPPED' ||
                        job.status == 'WAITING')
                    "
                    class="runs-table__link-wrap"
                  >
                    <a
                      :href="`/ws/${ws}/skills/result/download/${job.last_action.id}`"
                      class="runs-table__link custom-link js-runs-table-not-open-run"
                    >
                      {{ job.process_name }}_result.csv
                    </a>

                    <a
                      :href="`/ws/${ws}/skills/jobs/detail/${job.last_action.id}`"
                      class="runs-table__prev-link icon-button js-runs-table-not-open-run"
                      target="_blank"
                    >
                      <svg width="24" height="24">
                        <use xlink:href="#ic_prev"></use>
                      </svg>
                    </a>
                  </div>
                  <template v-if="job.status == 'EMPTY'">
                    No data after filtration
                  </template>
                </td>

                <td class="runs-table__td--btn">
                  <button
                    class="runs-table__del-row icon-button"
                    @click="jobDelete(job.id, job.process_name)"
                  >
                    <svg width="24" height="24">
                      <use xlink:href="#ic_delete"></use>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <page-paginator
            :page="page"
            :perPage="perPage"
            :pageRange="dataJobs.page_range"
            :count="dataJobs.count"
          />
        </section>
      </div>
    </div>
  </main>
</template>
