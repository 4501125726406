<script setup lang="ts">

import IconPlus from "@/components/icons/IconPlus.vue"
import IconHubSpot from "@/components/icons/IconHubSpot.vue"
import IconCopilot from "@/components/icons/IconCopilot.vue"
import IconSearch from "@/components/icons/IconSearch.vue"
import { computed, onMounted, ref } from "vue"
import useSkill from "@/compositions/useSkill.ts"
import { closeModal } from "jenesius-vue-modal"

const { actionTypes, fetchActionTypes } = useSkill()

const emit = defineEmits(["confirm", "cancel"])


const tags = [
  {
    value: "all",
    title: "All",
  },
  {
    value: "generic",
    title: "Generic Actions",
  },
  {
    value: "hubspot",
    title: "HubSpot Actions",
  },
  {
    value: "company_data",
    title: "Company Data Actions",
  },
  {
    value: "contact_data",
    title: "Contact Data Actions",
  }
]

const selectTag = ref("all")

const searchInput = ref("")

const selectAction = defineModel<string>()

const listActions = computed(() => {
  let newList = actionTypes.value
  if (selectTag.value != "all") {
    newList = newList?.filter((item) => item.tags.includes(selectTag.value))
  }
  if (searchInput.value.length > 0) {
    newList = newList?.filter((item) => item.description.search(searchInput.value) >= 0)
  }

  return newList
})

onMounted(async () => {
  await fetchActionTypes()
})

const cancel = () => {
  closeModal()
  emit("cancel")
}

const add = () => {
  closeModal()
  emit("confirm", {actionType: selectAction.value})
}
</script>

<template>
  <div class="custom-pop-up__content add-action-form" id="new-action-modal">
    <div class="add-action-form__header">
      <div class="add-action-form__title page-title">
        New action
      </div>
      <div class="add-action-form__search search-input">
        <icon-search class="search-input__icon" />
        <input v-model="searchInput" type="text" class="search-input__input">
      </div>
    </div>
    <div class="add-action-form__body">
      <div class="add-action-form__filter simple-menu">
        <label v-for="tag in tags" class="simple-menu__item active" type="button">
          <input
            class="simple-menu__radio" type="radio" name="filter"
            :value="tag.value"
            v-model="selectTag"
            checked>
          <div class="simple-menu__btn">{{ tag.title  }}</div>
        </label>
      </div>

      <div class="add-action-form__list">
        <div class="add-action-form__list-inner">
          <label
            v-for="(action, index) in listActions"
            :key="`action-available_template-${index}-${action.type}`"
            class="add-action-form__item action-type-card"
          >
            <input
              v-model="selectAction"
              :value="action.type"
              class="action-type-card__radio"
              type="radio"
              name="action_type"
            >
            <div class="action-type-card__content">
              <div class="action-type-card__title-wrap">
                <div class="action-type-card__title sub-title">
                  {{ action.name }}
                </div>
                <div class="action-type-card__label">
                  Sales & Marketing
                </div>
              </div>
              <div class="action-type-card__description">
                {{ action.description }}
              </div>
              <div class="action-type-card__badges">
                <div class="action-type-card__badge badge">
                  <icon-copilot />
                  <span>Autopilot</span>
                </div>
                <div
                  v-if="action.tags.includes('hubspot')"
                  class="action-type-card__badge badge badge--hubspot"
                >
                  <icon-hub-spot width="12" height="12" />
                  <span>HubSpot</span>
                </div>
              </div>
            </div>
          </label>

        </div>
      </div>
    </div>
    <div class="add-action-form__footer">
      <button
        class="add-action-form__cansel secondary-button"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        class="add-action-form__confirm primary-button"
        @click="add"
      >
        <icon-plus />
        <span>Add action</span>
      </button>
    </div>
  </div>
</template>

<style scoped>

</style>
