<script setup lang="ts">

import { ref } from "vue"
import { ErrorObject } from "@vuelidate/core"
import IconEye from "@/components/icons/IconEye.vue"
import IconEyeSlash from "@/components/icons/IconEyeSlash.vue"

const props = defineProps<{
  name: string
  type?: string
  placeholder?: string
  errors?: ErrorObject[],
  tabindex: string | number
}>()

const modelValue = defineModel<string>()
const typeInputPassword = ref("password")

const show = () => {
  if (typeInputPassword.value === "password") {
    typeInputPassword.value = "text"
  } else {
    typeInputPassword.value = "password"
  }
}

</script>

<template>
  <div class="login__field">
    <div
      :class="{'error': props.errors?.length }"
      class="validated-input password-input"
    >
      <button
        class="password-input__btn"
        type="button"
        @click="show"
      >
        <div
          class="wrap-eye-icon"
        >
          <icon-eye
            v-show="typeInputPassword === 'text'"
          />
          <icon-eye-slash
            v-show="typeInputPassword === 'password'"
          />
        </div>
      </button>

      <input
        :id="name"
        v-model="modelValue"
        :type="typeInputPassword"
        :name="props.name"
        :placeholder="props.placeholder"
        class="password-input__input"
        :tabindex="props.tabindex"
        required
      >

      <div
        v-for="error of props.errors"
        :key="error.$uid"
        class="validated-input__error-text"
      >
        <div class="error-msg">
          {{ error.$message }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrap-eye-icon {
  display: flex;
}
</style>
