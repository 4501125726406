import { ROUTE_NAMES } from "@/router/names.ts"
import { RouteLocationNormalized, createRouter, createWebHistory } from "vue-router"
import SkillCreate from "@/views/skills/SkillCreate.vue"
import SkillEdit from "@/views/skills/SkillEdit.vue"
import ActionCreate from "@/views/skills/actions/ActionCreate.vue"
import ActionEdit from "@/views/skills/actions/ActionEdit.vue"
import JobView from "@/views/skills/jobs/JobView.vue"
import AdminWorkspaces from "@/views/users/admin/AdminWorkspaces.vue"
import Jobs from "@/views/skills/jobs/Jobs.vue"
import LogIn from "@/views/users/auth/LogIn.vue"
import SignUp from "@/views/users/auth/SignUp.vue"
import EmailConfirmation from "@/views/users/email/EmailConfirmationSent.vue"
import EmailConfirmationStatus from "@/views/users/email/EmailConfirmationStatus.vue"
import ForgotPasswordRequest from "@/views/users/auth/ForgotPasswordRequest.vue"
import ForgotPassword from "@/views/users/auth/ForgotPassword.vue"
import ForgotPasswordStatus from "@/views/users/auth/ForgotPasswordStatus.vue"
import ProfileSettings from "@/views/users/profile/ProfileSettings.vue"
import ProfileSettingsGeneral from "@/views/users/profile/ProfileSettingsGeneral.vue"
import ProfileSettingsPassword from "@/views/users/profile/ProfileSettingsPassword.vue"
import ConfigureHubSpot from "@/views/integrations/configure/ConfigureHubSpot.vue"
import WorkspaceSettingsGeneral from "@/views/users/workspace/WorkspaceSettingsGeneral.vue"
import PriceList from "@/views/admin/PriceList.vue"
import WorkspaceSettingsIntegrations from "@/views/users/workspace/WorkspaceSettingsIntegrations.vue"
import Billing from "@/views/users/billing/Billing.vue"
import BillingSkills from "@/views/users/billing/BillingSkills.vue"
import BillingActions from "@/views/users/billing/BillingActions.vue"
import JobDetail from "@/views/skills/jobs/JobDetail.vue"
import jobResult from "@/views/skills/jobs/JobResult.vue"
import Skills from "@/views/skills/Skills.vue"
import Skill from "@/views/skills/Skill.vue"
import Home from "@/views/Home.vue"
import { userStore } from "@/store/user"
import useUser from "@/compositions/useUser"
import CompaniesRequest from "@/views/users/admin/CompaniesRequest.vue"
import GlobalSettings from "@/views/users/admin/GlobalSettings.vue"
import GlobalTemplateActions from "@/views/admin/templateActions/GlobalTemplateActions.vue"
import GlobalTemplateActionEdit from "@/views/admin/templateActions/GlobalTemplateActionEdit.vue"
import WorkspaceUsers from "@/views/users/workspace/WorkspaceUsers.vue"
import SignUpInvitation from "@/views/users/auth/SignUpInvitation.vue"
import GlobalTemplates from "@/views/admin/globalTemplates/GlobalTemplates.vue"
import GlobalTemplate from "@/views/admin/globalTemplates/GlobalTemplate.vue"
import GlobalTemplateAction from "@/views/admin/globalTemplates/GlobalTemplateAction.vue"
import Welcome from "@/views/users/Welcome.vue"

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: "/",
      name: ROUTE_NAMES.HOME,
      component: Home,
    },
    {
      path: "/ws/:ws/skills/welcome",
      name: ROUTE_NAMES.WELCOME,
      component: Welcome,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills",
      name: ROUTE_NAMES.SKILLS,
      component: Skills,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId",
      name: ROUTE_NAMES.SKILL,
      component: Skill,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/create",
      name: ROUTE_NAMES.SKILL_CREATE,
      component: SkillCreate,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/edit",
      name: "skill-edit",
      component: SkillEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/actions/create",
      name: "action-create",
      component: ActionCreate,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/actions/:actionId",
      name: ROUTE_NAMES.ACTION_EDIT,
      component: ActionEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/jobs",
      name: "jobs",
      component: Jobs,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/integrations/settings/:integrationId/configure",
      name: ROUTE_NAMES.INTEGRATIONS_CONFIGURE,
      component: ConfigureHubSpot,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/jobs/:jobId",
      name: "job-view",
      component: JobView,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/jobs/:jobId/detail",
      name: "job-detail",
      component: JobDetail,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/skills/:skillId/jobs/:jobId/result/:actionJobId",
      name: "job-result",
      component: jobResult,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/settings",
      name: ROUTE_NAMES.WORKSPACE_SETTINGS_GENERAL,
      component: WorkspaceSettingsGeneral,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/integrations/settings",
      name: ROUTE_NAMES.WORKSPACE_SETTINGS_INTEGRATIONS,
      component: WorkspaceSettingsIntegrations,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/billing/:month/:skillId",
      name: "billing-actions",
      component: BillingActions,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/billing/:month",
      name: "billing-skills",
      component: BillingSkills,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/users",
      name: ROUTE_NAMES.WORKSPACE_USERS,
      component: WorkspaceUsers,
      meta: { requiresAuth: true },
    },
    {
      path: "/ws/:ws/billing",
      name: ROUTE_NAMES.WORKSPACE_USAGE_BILLING,
      component: Billing,
      meta: { requiresAuth: true },
    },
    {
      path: "/users/profile/settings",
      name: "users-profile-settings",
      component: ProfileSettings,
      meta: { requiresAuth: true },
      children: [
        {
          path: "",
          name: "users-profile-settings-general",
          component: ProfileSettingsGeneral,
        },
        {
          path: "password",
          name: "users-profile-settings-password",
          component: ProfileSettingsPassword,
        },
      ],
    },
    {
      path: "/users/admin/workspaces",
      name: "admin-workspaces",
      component: AdminWorkspaces,
      meta: { requiresAuth: true },
    },
    {
      path: "/users/admin/companies_request",
      name: "admin-company-request",
      component: CompaniesRequest,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/admin/global-settings",
      name: "admin-global-settings",
      component: GlobalSettings,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/admin/prices",
      name: "admin-prices",
      component: PriceList,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/admin/global/template/actions",
      name: ROUTE_NAMES.ADMIN_GLOBAL_TEMPLATE_ACTION,
      component: GlobalTemplateActions,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/admin/global/template/actions/:templateActionId",
      name: ROUTE_NAMES.TEMPLATE_ACTION_EDIT,
      component: GlobalTemplateActionEdit,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
     {
      path: "/users/admin/global-templates",
      name: "global-templates",
      component: GlobalTemplates,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/admin/global-templates/:templateId",
      name: "global-template",
      component: GlobalTemplate,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/admin/global-templates/:templateId/action/:actionId",
      name: "global-template-action",
      component: GlobalTemplateAction,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/users/login",
      name: "login",
      component: LogIn,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/signup",
      name: "sign-up",
      component: SignUp,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/signup/invite",
      name: "sign-up-invite",
      component: SignUpInvitation,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/forgot/password/request",
      name: "users-forgot-password-request",
      component: ForgotPasswordRequest,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/forgot/password/:uidb64/:token",
      name: "user-forgot-password-create",
      component: ForgotPassword,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/forgot/password/status",
      name: "user-forgot-password-status",
      component: ForgotPasswordStatus,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/email/confirmation/sent",
      name: "users-email-confirmation-sent",
      component: EmailConfirmation,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/email/confirmation/failed",
      name: "users-email-confirmation-failed",
      component: EmailConfirmationStatus,
      meta: { requiresAuth: false },
    },
    {
      path: "/users/email/confirmation/successfully",
      name: "users-email-confirmation-successfully",
      component: EmailConfirmationStatus,
      meta: { requiresAuth: false },
    },
  ],
})

router.beforeEach(async (to: RouteLocationNormalized) => {
  if (to.meta.requiresAdmin) {
    const store = userStore()
    const { awaitInitUser } = useUser()

    await awaitInitUser()
    if (!store.getUserProfile.admin) {
      return {path: '/'}
    }

  }
})

export default router
