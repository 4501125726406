<script setup lang="ts">
import { Skill } from "@/types/skill"
import { useRoute } from "vue-router"
import { ROUTE_NAMES } from "@/router/names.ts"
import IconChevronRight from "@/components/icons/IconChevronRight.vue"
import IconMates from "@/components/icons/IconMates.vue"

const route = useRoute()

const props = defineProps<{ skill: Skill, type: string }>()
</script>

<template>
  <nav aria-label="Breadcrumb" class="breadcrumbs skills__breadcrumbs">
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item">
        <router-link
          :to="{ name: ROUTE_NAMES.SKILLS, params: { ws: route.params.ws} }"
          class="breadcrumbs__link"
        >
          <icon-mates />
          Dashboard Skills
        </router-link>
        <icon-chevron-right />
      </li>

      <li
        v-if="['actions', 'jobs', 'settings'].includes(props.type)"
        class="breadcrumbs__item"
      >
        <span aria-current="page" class="breadcrumbs__current">
          {{ props.skill.name }}
        </span>
      </li>
    </ul>
  </nav>
</template>
