<script setup lang="ts">
import { ref, watch } from "vue"
import CInputText from "@/components/elements/CInputText.vue"
import { SkillAttribute, SkillFormData } from "@/types/skill"
import CAttributes from "@/components/elements/CAttributes.vue"

const props = defineProps<{
  skill: SkillFormData
}>()

const templateAttribute: SkillAttribute = {
  name: "",
  type: "string",
  description: "",
  sub_type: "string",
}

const attributes = ref<SkillAttribute[]>([])

if (props.skill.fieldsInput.length > 0) {
  attributes.value = props.skill.fieldsInput.map((attribute) => {
    const newAtribute = Object.assign({}, attribute)
    if (!newAtribute.sub_type) {
      newAtribute.sub_type = "string"
    }
    return newAtribute
  })
}

const deleteAtribute = (index: number) => {
  attributes.value.splice(index, 1)
}

const addAtribute = () => {
  const newItem = structuredClone(templateAttribute)
  attributes.value.push(newItem)
}

watch(attributes.value, (newAttributes) => {
  props.skill.fieldsInput = newAttributes.map((attribute) => {
    const newAtribute = Object.assign({}, attribute)
    if (newAtribute.type !== "array") {
      delete newAtribute.sub_type
    }
    return newAtribute
  })
})
</script>

<template>
  <c-input-text
    id="skill-name"
    v-model="props.skill.name"
    label="Skill name"
    description="You will see this name in the Skill list"
    placeholder="Skill name"
    :required="false"
  />
  <c-input-text
    id="skill-description"
    v-model="props.skill.description"
    label="Description"
    description="Type in a friendly description of the Skill"
    placeholder="New Skill Description Skill Usege"
    :required="false"
  />

  <div class="settings-block__row">
      <div class="settings-block__col">
          <div class="settings-block__attr sub-title">Skill Attributes</div>
          <div class="settings-block__hint hint-text">Add all attributes you need for your Skill run. Create the attribute name and choose attribute type from the list</div>
      </div>
  </div>

  <c-attributes
    v-for="[index, parameter] of attributes.entries()"
    :key="`skill-attribute=${index}`"
    :index="index"
    :attribute="parameter"
    :length-list="attributes.length - 1"
    @delete="deleteAtribute"
  />


  <div class="action-param__row action-param__row--pv0">
    <button
      id="attributes-btn-add"
      class="action-param__add-btn outline-button outline-button--wide"
      type="button"
      @click="addAtribute"
    >
      <svg width="16" height="16">
        <use xlink:href="#ic_plus"></use>
      </svg>
      <span>Add Skill attribute</span>
    </button>
  </div>
</template>
