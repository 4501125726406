import moment from "moment/moment"
import useUser from "@/compositions/useUser.ts"
import useProjectSettings from "@/compositions/useProjectSettings.ts"

export const formatDate = (value: Date | string) => {
  let valueStr = ""
  if (typeof value === "string") {
    valueStr = value
  } else {
    valueStr = String(value)
  }

  return moment(valueStr).format("DD MMMM Y, hh:mm:ss A")
}

export const replaceQueryParams = (name: string, value: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(name, value)
  const newParams = searchParams.toString()
  // TODO: update query without reload page
  window.location.search = newParams
}

export const formatTimeShort = (value: Date | string) =>
  moment(String(value)).format("DD/MM/Y hh:mm:ss")

export default function useApp() {
  const initApp = async () => {
    const { initUser } = useUser()
    const { initProjectSettings } = useProjectSettings()
    await initProjectSettings()
    await initUser()
  }

  return {
    initApp,
  }
}
