export type Operator = "AND" | "OR"

export interface Condition {
  type: string,
  params: { [key: string]: any }
}

export function comparisonCondition(leftNode: Condition, operator: Operator, rightNode: Condition): Condition {
  return {
    type: "ComparisonCondition",
    params: {
      value_1: leftNode,
      value_2: rightNode,
      operator: operator,
    },
  }
}


export function fieldValueCondition(fieldName: string): Condition {
  return {
    type: "FieldValueCondition",
    params: {
      field_name: fieldName,
    },
  }
}
