<script setup lang="ts">
import Icons from "@/components/Icons.vue"
import { container, initContainer } from "@/services/container"
import { useRoute, useRouter } from "vue-router"
import useApp from "@/compositions/useApp.ts"
import { container as WidgetContainerModal, openModal } from "jenesius-vue-modal"
import { onMounted } from "vue"
import MainMenu from "@/components/MainMenu.vue"
import IconLogoBlack from "@/components/icons/IconLogoBlack.vue"
import CNotifications from "@/components/notifications/CNotifications.vue"
import CNotificationTariffPlan from "@/components/notifications/CNotificationTariffPlan.vue"
import NewUserConfirmModal from "@/components/modals/NewUserConfirmModal.vue"
import useUser from "@/compositions/useUser.ts"
import { ROUTE_NAMES } from "@/router/names.ts"
import { initHubspotTracking } from "@/views/integrations/hubspot.ts"

const route = useRoute()
const router = useRouter()
const { initApp } = useApp()
const { initUser, currentWorkspace } = useUser()

initContainer()

const initNewUserFrom = async () => {
  const canNew = currentWorkspace?.value?.status === "NEW"

  if (canNew) {
    await router.push({
      name: ROUTE_NAMES.WELCOME,
    })

    const modal: any = await openModal(NewUserConfirmModal)

    modal.onclose = async () => {
      await initUser()

      if (currentWorkspace.value && currentWorkspace.value.company_url) {
        const result = await container.workspaceRepository.startFreePlan(
          currentWorkspace.value.schema_name,
          window.location.href,
          "Collextr"
        )
        await initUser()
        if (result.status === "ok") {
          return true
        }
      }
      return false
    }
  }
}

onMounted(async () => {
  await initApp()
  await initHubspotTracking()
  await initNewUserFrom()
})

</script>

<template>
  <icons />

  <c-notifications />

  <template v-if="route.meta?.requiresAuth">
    <main-menu />

    <section class="main__section">

      <c-notification-tariff-plan />

      <section class="main__content mates">
        <RouterView />
      </section>

      <widget-container-modal />
    </section>
  </template>

  <template v-else>
    <div class="login centered">
      <header class="login__header">
        <a href="/">
          <icon-logo-black />
        </a>
      </header>
      <div class="login__body">
        <RouterView />
      </div>
    </div>
  </template>
</template>

<style scoped>
.centered {
  display: inline-table;
  flex: 1;
}
</style>