<script setup lang="ts">

const props = defineProps<{
  class: string,
  title?: string,
  text?: string
}>()

</script>

<template>
  <div
    :class="props.class"
    class="vue-notification-template vue-notification collext-notification"
  >
    <div class="status">
      <slot>

      </slot>
    </div>

    <div class="content">
      <div class="notification-title">
        {{ props.title }}
      </div>
      <slot name="text">
        <div
          class="notification-content"
          v-text="props.text"
        />
      </slot>
    </div>
  </div>
</template>

<style>
.collext-notification,
.vue-notification-group .collext-notification {
  box-sizing: border-box;
  text-align: left;
  padding: 10px;
  margin: 0 5px 5px;
  background: #44A4FC;
  display: flex;
  color: black;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid !important;
  border-radius: 8px !important;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  min-height: 80px;

  .status {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .status-label {
      background: #187FE7 !important;
    }

    .status-label.warn {
      background: #FFCD62 !important;
    }

    .status-label.error {
      background: #963C51 !important;
    }

    .status-label.success {
      background: #3C8467 !important;
    }
  }

  .content {
    flex: 0.9;
  }
}

.vue-notification,
.vue-notification-group .vue-notification {
  background: #edf6ff !important;
  border-color: #187FE7 !important;
}

.vue-notification.warn,
.vue-notification-group .vue-notification.warn {
  background: #fffaf0 !important;
  border-color: #FFCD62 !important;
}

.vue-notification.error,
.vue-notification-group .vue-notification.error {
  background: #ffe7ec !important;
  border-color: #963C51 !important;
}

.vue-notification.success,
.vue-notification-group .vue-notification.success {
  background: #eafff7 !important;
  border-color: #3C8467 !important;
}
</style>