<script setup lang="ts">
import { computed, ref } from "vue"
import { closeModal } from "jenesius-vue-modal"
import CButton from "@/components/elements/CButton.vue"
import CTable from "@/components/elements/CTable.vue"
import { SkillTemplate } from "@/types/skill"
import { container } from "@/services/container"
import { Paginator } from "@/types/api"
import { useDebounceFn } from "@vueuse/core"
import PagePaginator from "@/components/PagePaginator.vue"

const TIMEOUT_SEARCH = 500

const props = defineProps<{
  skill: SkillTemplate
}>()

const emit = defineEmits(["confirm", "cancel"])

const clickCancel = () => {
  closeModal()
  emit("cancel")
}

const page = ref(1)
let perPage = ref(5)

const searchText = ref("")

const templatesReplace = ref<Paginator<SkillTemplate> | null>(null)

const templatesReplaceList = computed(() => {
  if (!templatesReplace.value) {
    return []
  }

  return templatesReplace.value.items.map((item) => {
    return {
      id: String(item.id),
      name: item.name,
      description: item.description,
    }
  })
})

const templatesColumns = [
  { key: "name", label: "Global template name" },
  { key: "description", label: "Description" },
]

const updateTemplatesReplace = async () => {
  templatesReplace.value = await container.skillAdminRepository.globalTemplates(
    searchText.value,
    page.value,
    perPage.value
  )
}

const changePage = async (value: { pageNumber: number }) => {
  page.value = value.pageNumber

  updateTemplatesReplace()
}

const onSearch = useDebounceFn(() => {
  updateTemplatesReplace()
}, TIMEOUT_SEARCH)

const templateReplace = async (template: SkillTemplate) => {
  if (template.id) {
    await container.skillAdminRepository.replaceGlobalTemplate(template.id, props.skill)
  }
  emit("cancel")
  await closeModal()
}

const clickSave = async () => {
  await container.skillAdminRepository.addGlobalTemplate(props.skill)
  emit("cancel")
  await closeModal()
}
</script>

<template>
  <div class="custom-pop-up__content">
    <div v-if="templatesReplace" class="custom-alert__body">
      <div class="search">
        <input
          v-model="searchText"
          type="text"
          placeholder="Start Typing to search"
          class="search-input__input"
          @input="onSearch"
        />
      </div>
      <c-table :items="templatesReplaceList"
        :columns="templatesColumns"
        @click="templateReplace"/>
      <template v-if="templatesReplace.num_pages > 1">
        <page-paginator
          :page="page"
          :per-page="perPage"
          :page-range="templatesReplace.page_range"
          :count="templatesReplace.count"
          :isNotHardPageReload="true"
          :hide-per-page="true"
          @changePage="changePage"
        />
      </template>
    </div>
    <div v-if="!templatesReplace" class="custom-alert__body">
      <div class="custom-alert__message">
        You chose to save this skill as a global template. How do you want to
        proceed?
      </div>

      <div class="custom-alert__check-block">
        <div class="custom-alert__check-text"></div>
      </div>

      <div class="custom-alert__footer">
        <c-button @click="clickCancel"> Cancel </c-button>
        <c-button type="error" @click="updateTemplatesReplace">
          Replace existing template
        </c-button>
        <c-button type="primary" @click="clickSave">
          Save as new template
        </c-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.search {
  margin-bottom: 20px;;
}
.custom-alert__body {
  width: 900px;
  height: 500px;
}
</style>
