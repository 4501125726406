import { SkillActionField } from "@/types/actions.ts"
import { SettingsIntegration } from "@/types/integration.ts"

export interface FieldsHubSpot extends Array<HubSpotField> {
}

export interface HubSpotField {
  "updated_at": string
  "created_at": string
  "archived_at": string
  "name": string
  "label": string
  "type": string
  "field_type": string
  "description": string
  "group_name": string
  "options": []
  "created_user_id": string
  "updated_user_id": string
  "display_order": number
  "calculated": boolean
  "external_options": boolean
  "archived": null
  "has_unique_value": boolean
  "hidden": boolean
  "hubspot_defined": boolean
  "modification_metadata": object
  "form_field": boolean
  "calculation_formula": string
}

export interface HubSpotActionField {
  name: string
  label: string
  type: string
  field_type: string
}

export interface Ligament {
  field_hubspot: HubSpotActionField
  field_collextr: SkillActionField
  overwrite?: boolean
}

export enum TypeEntityHubSpot {
  CONTACT = "contact",
  COMPANY = "company",
}

export interface SettingsIntegrationHubSpot extends SettingsIntegration {
  settings: {
    crm: {
      mapping: LigamentIntegrationHubSpotCRMWithMate[]
    }
  }
}

export interface LigamentIntegrationHubSpotCRMWithMate {
  skill_id?: string | null,
  availableCompany?: boolean | null,
  availableContact?: boolean | null,
}
