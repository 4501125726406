<script setup lang="ts">
// TODO: optimize component

import Mentionable from "@/components/mention/Mentionable.vue"
import { MentionItem } from "@/types/mentionable.ts"

const props = defineProps<{
  modelValue: string
  fieldsMentionable: MentionItem[]
  placeholder?: string
  id?: string
  required?: boolean
  rows?: number
  error?: string
}>()

const emit = defineEmits(["update:modelValue"])

const update = ($event: Event) => {
  emit("update:modelValue", ($event.target as HTMLInputElement).value)
}
</script>

<template>
  <mentionable
    :items="props.fieldsMentionable"
  >
    <textarea
      v-if="props.rows"
      :id="props.id"
      :placeholder="props.placeholder"
      :rows="props.rows"
      :value="props.modelValue"
      :required="props.required"
      class="custom-textarea custom-textarea--small"
      @input="update"
    />
    <input
      v-else
      :id="props.id"
      :placeholder="props.placeholder"
      :value="props.modelValue"
      :required="props.required"
      class="custom-input custom-input--small"
      @input="update"
    />
    <div class="validated-input__error-text">
      {{ props.error }}
    </div>
  </mentionable>
</template>
