import { ActionTypeUi, SkillAction } from "@/types/actions.ts"
import ActionCompanyBasicInfo from "@/components/actions/companyBasicInfo/ActionCompanyBasicInfo.vue"

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "CompanyBasicInfoAction",
    description: "",
    copilot: false,
    module: "workflow.actions.company_basic_info",
    params: {},
    ui: {},
    fields_source: [
      {
        name: "",
        type: "linkedin_url",
      },
    ],
    fields_target: [
      {
        name: "",
        type: "",
      },
    ],
  }
}

export class CompanyBasicInfo implements ActionTypeUi {
  static typeName = "CompanyBasicInfoAction"
  static component = ActionCompanyBasicInfo
  public actionData = newSkillAction()
  public availableFields = []
  newActionData() {
    this.actionData = newSkillAction()
  }
  handlerAfterLoad() {}
  handlerBeforeSave() {}
  handlerEvent(event: string, value: any) {
    if (event === "add-attribute") {
      const field = { name: "", type: "" }
      this.actionData.fields_target.push(field)
    }
    if (event === "delete-attribute") {
      this.actionData.fields_target.splice(value, 1)
    }
  }
}
