import { UserInvited, Workspace } from "@/types/workspace.ts"
import { ServerErrors } from "@vuelidate/core"
import { AxiosError, AxiosInstance } from "axios"
import { notify } from "@kyvg/vue3-notification"
import { ResponseApi, ResponseApiMessage } from "@/types/api.ts"

export class WorkspaceRepository {
  constructor(private axios: AxiosInstance) {
  }

  async get(ws: string): Promise<Workspace> {
    return (await this.axios.get(`/ws/${ws}/api/settings/general/get`)).data.data
  }

  async updateGeneralData(ws: string, data: {
    name?: string,
    company_url?: string
  }): Promise<{
    status: string, data: Workspace, message?: string, error?: ServerErrors
  }> {
    const title = "Setting workspace"
    let type = "error"
    let message = "Error update setting workspace"

    const dataRequest = {
      "name": data.name ?? null,
      "company_url": data.company_url ?? null,
    }

    try {
      const result = (await this.axios.post(
        `/ws/${ws}/api/settings/general/edit`,
        dataRequest,
      )).data

      if (result.status === "ok") {
        type = "info"
        message = result.message
      }

      return result
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async getUsersInvited(ws: string): Promise<UserInvited[]> {
    const result = (await this.axios.get(`/ws/${ws}/api/users`)).data
    return result.data
  }

  async sendUserInvite(ws: string, email: string): Promise<ResponseApi<ResponseApiMessage>> {
    const title = "Users send invite workspace"
    let type = "success"
    let message = "The invitation has been sent to the user"

    try {
      const result = await this.axios.post(
        `/ws/${ws}/api/users/send/invite`,
        { email: email },
      )
      return result.data
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const result = error as AxiosError<ResponseApi<ResponseApiMessage>>
        const messageResponse = result.response?.data.data.message.toString() ?? ""

        type = "error"
        message = messageResponse
      }

      throw error
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async kickUserInvite(ws: string, id: string): Promise<ResponseApi<ResponseApiMessage>> {
    try {
      const result = await this.axios.post(
        `/ws/${ws}/api/users/kick/invite`,
        { id: id },
      )
      return result.data
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const result = error as AxiosError<ResponseApi<ResponseApiMessage>>
        const message = result.response?.data.data.message.toString() ?? ""

        notify({
          type: "error",
          title: "Users kick invite workspace",
          text: message,
        })
      }

      throw error
    }
  }

  async startFreePlan(ws: string, url: string, title: string): Promise<ResponseApi<ResponseApiMessage>> {
    const result = await this.axios.post(
      `/ws/${ws}/api/plan/start-free`,
      { page_url: url, page_name: title },
    )
    return result.data
  }
}
