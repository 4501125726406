<script setup lang="ts">
import InputRadioParameter from "@/components/actions/elements/InputRadioParameter.vue"
import TextareaParameter from "@/components/actions/elements/TextareaParameter.vue"
import InputParameter from "@/components/actions/elements/InputParameter.vue"
import InputSelect from "@/components/actions/elements/InputSelect.vue"
import { SkillAttribute } from "@/types/skill"

const props = defineProps<{
  attribute: SkillAttribute,
  index: number,
  lengthList: number
}>()

const emit = defineEmits(["delete"])

const outputFiledType = [
  "string",
  "boolean",
  "array",
  "number",
  "linkedin_url",
  "site_url",
]

const itemTypes = [
  {value: "string", label: "String"},
  {value: "number", label: "Number"},
]


</script>

<template>
  <div class="param-list__item action-param">
    <div class="action-param__title sub-title">
      <span>Skill attributes</span>
      <button
        v-if="props.index > 0"
        class="action-param__del-btn icon-button"
        type="button"
        @click="emit('delete', props.index)"
      >
        <svg width="24" height="24">
          <use xlink:href="#ic_delete"></use>
        </svg>
      </button>
    </div>

    <input-parameter
      :id="'attribute-name-' + props.index"
      v-model="attribute.name"
      placeholder="Attribute name"
      label="Attribute name"
      :name="'filed_name_' + props.index"
      :required="true"
    />

    <InputRadioParameter
      :id="'attribute-type-' + props.index"
      v-model="attribute.type"
      label="Attribute Type"
      :name="'filed_type_' + props.index"
      :required="true"
      :options="outputFiledType.entries()"
    />

    <!-- <template v-if="attribute.type === 'enum' && props.attribute.items">
      <textarea-parameter
        :id="'items_enum_' + index"
        placeholder="items enum"
        label="Value"
        :name="'items_enum_' + index"
        :required="false"
        :model-value="getItemsEnum()"
        @update:model-value="e => setItemsEnum(e)"
      />
    </template> -->

    <input-select
      v-if="attribute.type === 'array' && props.attribute.sub_type"
      label="Item type"
      description=""
      :items="itemTypes"
      v-model="props.attribute.sub_type"
    />

    <textarea-parameter
      :id="'attribute-description-' + props.index"
      v-model="attribute.description"
      placeholder="description"
      label="Attribute Description"
      :name="'description_' + props.index"
      :required="true"
    />
  </div>
</template>
