<script setup lang="ts">
import { container } from "@/services/container"
import { onMounted, ref } from "vue"
import { Workspace } from "@/types/workspace"
import BaseNav from "@/components/BaseNav.vue"
import { useRoute } from "vue-router"
import { formatDate } from "@/compositions/useApp"
import { Paginator } from "@/types/api.ts"
import PagePaginator from "@/components/PagePaginator.vue"

const route = useRoute()
let page = Number(route.query.page || 1)
let perPage = Number(route.query.per_page || 20)

const data = ref<Paginator<Workspace> | null>(null)

const navItems = [
  {
    link: null,
    label: "Manage Workspaces",
  },
]

onMounted(async () => {
  data.value = await container.userAdminRepository.workspaces(page, perPage)
})
</script>

<template>
  <main>
    <base-nav :items="navItems" icon="settings"/>
    <h2>Manage Workspaces</h2>
    <div v-if="data">
      <table class="runs-table__table">
        <thead>
          <tr>
            <th>Workspace Name</th>
            <th>Created On</th>
            <th>Created By</th>
            <th>Creator Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tr v-for="workspace of data.items" :key="workspace.name">
          <td>{{ workspace.name }}</td>
          <td>{{ formatDate(workspace.created_at) }}</td>
          <td>
            {{ workspace.created_by?.first_name }}
            {{ workspace.created_by?.last_name }}
          </td>
          <td>{{ workspace.created_by?.email }}</td>
          <td>
            <a :href="`/ws/${workspace.name}/skills`">
              <svg width="16" height="16">
                <use xlink:href="#ic_settings"></use>
              </svg>
            </a>
          </td>
        </tr>
      </table>
      <page-paginator :page="page" :perPage="perPage" :pageRange="data.page_range" :count="data.count" />
    </div>
  </main>
</template>
