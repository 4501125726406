<script setup lang="ts">

const modelValue = defineModel<string>()

</script>

<template>
  <div>
    <div class="input__title">
      Skill attribute
    </div>
    <input
      v-model="modelValue"
      required
      placeholder="Input Skill attribute"
      class="custom-input custom-input--small"
    />
  </div>
</template>

<style scoped>
.input__title {
  margin-bottom: 0.5rem;
}
</style>