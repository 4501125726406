<script setup lang="ts">
/**
 * Form for test run of the action
 */

import { SkillAction, testedErrorMessage } from "@/types/actions.ts"
import { computed, ref } from "vue"
import { fileReadAsText, parseCsv, unParseCsv } from "@/utils/files.ts"
import { useRoute } from "vue-router"
import { container } from "@/services/container.ts"
import { closeModal } from "jenesius-vue-modal"
import InputFile from "@/components/shared/InputFile.vue"

const props = defineProps<{ actionData: SkillAction }>()

const route = useRoute()
const ws = route.params.ws as string

const numberRows = [1, 5, 10, 15, 20]

const activeNumber = ref(5)
const error = ref("")
const file = ref<string | null>(null)
const result = ref<{ property: string | number }[] | null>(null)

const activeRow = ref(0)

const uploadFile = async (event: Event) => {
  const target = event.target as HTMLInputElement
  const files: FileList | null = target.files

  if (!files) {
    return
  }

  file.value = await fileReadAsText(files[0])
}

const clickRun = async () => {
  if (file.value) {

    const metaData = parseCsv(file.value, { preview: activeNumber.value + 1 })
    const dataCSV = unParseCsv(metaData)

    file.value = null

    const testingResult = await container.skillActionRepository.runTest(
      ws,
      props.actionData,
      dataCSV,
    )

    if (testingResult.status === "error") {
      const message = testingResult.message
      error.value = `${testedErrorMessage} | ${message}`
    } else {
      result.value = testingResult.data
    }
  }
}

const uploadError = () => {
  file.value = null
}

const isFileFieldDisabled = computed(() => !file.value)

const clickCancel = () => {
  closeModal()
}
</script>

<template>
  <div class="custom-pop-up__content custom-alert__content">
    <div class="custom-alert__body">

      <input-file
        v-model:error="error"
        label="Upload the Test file"
        @upload="uploadFile"
        @upload-error="uploadError"
      />

      <div class="run-test">
        <div class="settings-block__controls">
          <b>
            How many rows you’d like to test?
          </b>
          <div class="number-rows">
            <div
              v-for="num of numberRows"
              :key="num"
              class="wrap-number"
            >
              <input
                :id="num.toString()"
                :class="{ active: num === activeNumber }"
                :value="num"
                name="number"
                type="radio"
                class="number"
                @click="activeNumber = num"
              />
              <label
                :for="num.toString()"
                class="number-text"
              >
                {{ num }}
              </label>
            </div>
          </div>
          <button
            class="settings-block__control-btn custom-alert__confirm secondary-button"
            type="button"
            @click="clickCancel"
          >
            Cancel
          </button>
          <button
            class="settings-block__control-btn custom-alert__confirm primary-button"
            type="button"
            :disabled="isFileFieldDisabled"
            @click="clickRun"
          >
            Run Test
          </button>
        </div>
      </div>

      <div v-if="result && result.length > 0" class="result">
        <div class="rows">
          <div
            v-for="(_, index) in result"
            :key="index"
            :class="{ active: index === activeRow }"
            class="row"
            @click="activeRow = index"
          >
            {{ index + 1 }}
          </div>
        </div>

        <div class="coluns">
          <table>
            <tr v-for="(item, index) in result[activeRow]" :key="index">
              <td>
                <div class="name">{{ index }}</div>
              </td>
              <td>
                <div class="value">{{ item }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-alert__content {
  max-width: 1050px;
}

.wrap-number {
  margin: 0 1rem;
  display: flex;

  .number {
  }

  .number-text {
    padding: 0 0 0 0.5rem;
  }
}

.number-rows {
  display: flex;
}

.number-rows .number {
  display: inline-block;
}

.result {
  vertical-align: top;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 30px;
}

.rows {
  width: 30px;
  margin-right: 30px;
  display: inline-block;
  vertical-align: top;
}

.rows .row {
  padding: 10px;
}

.active {
  background: #001846;
  color: #fff;
}

.coluns {
  width: 400px;
  min-height: 200px;
  display: inline-block;
  vertical-align: top;

  td {
    padding: 10px;
  }

  .name {
    color: #001846;
    font-size: 17px;
    font-weight: bold;
  }
}

.settings-block__controls {
  padding: 0;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}


.run-test {
  margin-bottom: 30px;
}
</style>
