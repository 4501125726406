<script setup lang="ts">
import { computed } from "vue"
import { closeModal } from "jenesius-vue-modal"
import Multiselect from "vue-multiselect"
import { Price } from "@/types/price"

const props = defineProps<{
  price: Price
}>()

const emit = defineEmits(["confirm", "cancel"])

const clickCancel = () => {
  closeModal()
  emit("cancel")
}

const clickSave = () => {
  closeModal()
  emit("confirm", props.price)
}

const optionsActionType = [
  "CompanyBasicInfo",
  "CompanyCrawlerAction",
  "Crawler",
  "Filter",
  "HubSpotCompanyInfo",
  "HubSpotContactInfo",
  "HubSpotCreateNote",
  "HubSpotUpdateCompanyInfo",
  "HubSpotUpdateContactInfo",
  "MinifyHtml",
  "OpenAi",
]

const optionsUnit = ["Run", "Row", "Token"]

const formIsValid = computed(
  () => props.price.action_type && props.price.unit && props.price.price,
)
</script>

<template>
  <div class="custom-pop-up__content custom-alert__content">
    <div class="custom-alert__body">
      <h2>Add action type pricing</h2>
      <div class="form-field">
        <label for="input-price-action-type">Action type</label>
        <multiselect
          id="input-price-action-type"
          v-model="props.price.action_type"
          :options="optionsActionType"
          required
        />
      </div>

      <div class="form-field">
        <label for="input-price-unit">Unit</label>
        <multiselect
          id="input-price-unit"
          v-model="props.price.unit"
          :options="optionsUnit"
          required
        />
      </div>

      <div class="form-field">
        <label for="input-price-price">Price</label>
        <input
          id="input-price-price"
          class="custom-input custom-input--small"
          type="number"
          v-model="props.price.price"
          required
        />
      </div>

      <div class="form-field">
        <label for="input-price-date">Effective date</label>
        <input
          id="input-price-date"
          class="custom-input custom-input--small"
          v-model="props.price.effective_date"
          type="date"
        />
      </div>

      <div class="form-field">
        <label for="input-price-until">Effective until</label>
        <input
          id="input-price-until"
          class="custom-input custom-input--small"
          v-model="props.price.effective_until"
          type="date"
        />
      </div>

      <div class="custom-alert__footer">
        <button class="secondary-button" type="button" @click="clickCancel">
          Cancel
        </button>
        <button
          class="primary-button"
          type="button"
          :disabled="!formIsValid"
          @click="clickSave"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-field {
  margin: 30px 0;
}
.form-field label {
  margin: 5px 0;
  display: block;
}
.custom-alert__footer {
  padding: 24px 0;
}
.custom-alert__content {
  max-width: 700px;
}
</style>
