<script setup lang="ts">
const props = defineProps<{
  label: string
  id: string
  placeholder?: string | null
  name: string
  modelValue: string
  error?: string,
  required?: boolean,
  rows?: number,
}>()

const emit = defineEmits(["update:modelValue"])
</script>

<template>
  <div class="action-param__row">
    <div class="action-param__col action-param__col--left">
      <div class="action-param__col-name text-style">{{ props.label }}</div>
    </div>
    <div class="action-param__col action-param__col--right">
      <div class="action-param__input-wrap validated-input">
        <textarea :id="props.id"
                  class="custom-textarea custom-textarea--small js-textarea-auto-height"
                  :rows="props.rows ?? 5"
                  :name="props.name"
                  :placeholder="props?.placeholder ?? ''"
                  :required="props.required"
                  :value="props.modelValue"
                  @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        />
        <div class="validated-input__error-text">{{ props?.error ?? 'Error text' }}</div>
      </div>
    </div>
  </div>
</template>
