import axios from "axios"
// @ts-ignore
import Cookies from "js-cookie"
import { SkillActionRepository } from "@/services/api/skill-action.repository"
import { UserRepository } from "@/services/api/user.repository"
import { SkillRepository } from "@/services/api/skill.repository"
import { SkillJobRepository } from "@/services/api/skill-job.repository.ts"
import { UserAdminRepository } from "@/services/api/user-admin.repository"
import { IntegrationRepository } from "@/services/api/integration.repository.ts"
import { WorkspaceRepository } from "@/services/api/workspace.repository.ts"
import { PriceRepository } from "./api/price.repository"
import { BillingRepository } from "./api/billing.repository"
import { GlobalTemplateActionRepository } from "@/services/api/global-template-action.repository.ts"
import { SkillAdminRepository } from "./api/skill-admin.repository"

interface Container {
  skillRepository: SkillRepository
  skillActionRepository: SkillActionRepository,
  skillJobRepository: SkillJobRepository,
  skillAdminRepository: SkillAdminRepository,
  userRepository: UserRepository
  userAdminRepository: UserAdminRepository,
  integrationRepository: IntegrationRepository,
  workspaceRepository: WorkspaceRepository,
  priceRepository: PriceRepository,
  billingRepository: BillingRepository,
  globalTemplateActionRepository: GlobalTemplateActionRepository,
}

export const container: Container = {} as Container

const listRouterExceptions = [
  "/users/login",
  "/users/signup",
  "/users/forgot",
  "/users/email/confirmation",
]

export function initContainer() {
  const csrfToken = Cookies.get("csrftoken")

  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    },
  })
  axiosInstance.interceptors.response.use((response) => response, (error) => {
    if ([401, 403].includes(error.response.status)) {
      let isAuthenticated = false
      listRouterExceptions.forEach(route => {
        if (window.location.pathname.includes(route)) {
          isAuthenticated = true
        }
      })

      if (!isAuthenticated) {
        window.location.pathname = "/users/login"
      }
    } else {
      throw error
    }
  })

  container.skillRepository = new SkillRepository(axiosInstance)
  container.skillActionRepository = new SkillActionRepository(axiosInstance)
  container.skillJobRepository = new SkillJobRepository(axiosInstance)
  container.skillAdminRepository = new SkillAdminRepository(axiosInstance)
  container.userRepository = new UserRepository(axiosInstance)
  container.userAdminRepository = new UserAdminRepository(axiosInstance)
  container.integrationRepository = new IntegrationRepository(axiosInstance)
  container.workspaceRepository = new WorkspaceRepository(axiosInstance)
  container.priceRepository = new PriceRepository(axiosInstance)
  container.billingRepository = new BillingRepository(axiosInstance)
  container.globalTemplateActionRepository = new GlobalTemplateActionRepository(axiosInstance)
}
