<script setup lang="ts">
import CNotificationsStatus from "@/components/notifications/CNotificationsStatus.vue"
import CNotifyTemplate from "@/components/notifications/CNotifyTemplate.vue"
import { WARNING_CLASS } from "@/types/notifications.ts"
import { ROUTE_NAMES } from "@/router/names.ts"
import useUser from "@/compositions/useUser.ts"
import { activeButPaymentFailed, isNotFreeUser } from "@/utils/subscription.ts"
import { computed } from "vue"
import { WorkspaceStatusSubscription } from "@/types/workspace.ts"
import CStripeLinkManagementBilling from "@/components/billing/CStripeLinkManagementBilling.vue"

const { currentWorkspace } = useUser()

const style = "warn"

const overdue = computed(() => {
  return isNotFreeUser(currentWorkspace.value)
})

const activeButPaymentFailedStatus = computed(() => {
  return activeButPaymentFailed(currentWorkspace.value)
})

const planCurrent = computed(() => {
  if (currentWorkspace.value?.plan) {
    return `Your plan: ${currentWorkspace.value?.plan}`
  }

  return "Your plan: Free"
})

</script>

<template>
  <div
    v-if="(!overdue || activeButPaymentFailedStatus) && currentWorkspace"
    class="vue-notification-group"
  >
    <c-notify-template :class="style">
      <div class="status">
        <c-notifications-status
          v-if="style === WARNING_CLASS"
          :status-css-class="style"
          :status-name="planCurrent"
        />
      </div>

      <template v-if="currentWorkspace" v-slot:text>
        <span
          v-if="currentWorkspace.status === WorkspaceStatusSubscription.FREE_PLAN"
        >
          Free plan is intended for getting acquainted with the system. To access all features,
          <router-link
            :to="{name: ROUTE_NAMES.WORKSPACE_USAGE_BILLING}"
          >
            choose your plan
          </router-link>
          <br>
          You have {{ currentWorkspace?.rows_consumed_within_plan_in_current_period }} out of {{ currentWorkspace?.rows_included_in_plan}} operations left
        </span>

        <span v-else-if="currentWorkspace.status === WorkspaceStatusSubscription.CHURNED_CUSTOMER">
          Your plan has been canceled. To access all features,
          <router-link
            :to="{name: ROUTE_NAMES.WORKSPACE_USAGE_BILLING}"
          >
            choose your plan
          </router-link>
          <br>
        </span>

        <span v-else-if="currentWorkspace.status === WorkspaceStatusSubscription.GRACE_PERIOD_OVER">
          The deadline for submitting the plan has expired. To access all features,
          <router-link
            :to="{name: ROUTE_NAMES.WORKSPACE_USAGE_BILLING}"
          >
            choose your plan
          </router-link>
          <br>
        </span>

        <span v-else-if="currentWorkspace.status === WorkspaceStatusSubscription.GRACE_PERIOD">
          We tried to charge you, but the payment failed. Please

          <c-stripe-link-management-billing
            text="update payment details"
            type="link"
          />

          to continue using Collextr.
        </span>
      </template>

    </c-notify-template>
  </div>
</template>

<style scoped>
.vue-notification-group {
  position: initial;
  padding: 12px 32px 12px 32px;
}
</style>