<script setup lang="ts">
import { closeModal } from "jenesius-vue-modal"
import { computed, reactive } from "vue"
import { email, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

const emit = defineEmits(["confirm", "cancel"])
const state = reactive<{
  email: string
}>({
  email: "",
})

const rules = {
  email: {
    required,
    email,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, state)

const clickCancel = () => {
  closeModal()
  emit("cancel")
}

const clickSendInvitation = () => {
  closeModal()
  emit("confirm", state.email)
}

const checkEmail = computed(() => {
  return validator.value.$errors.length > 0 || state.email.length === 0
})

</script>

<template>
  <div class="custom-pop-up__content custom-alert__content">
    <div class="custom-alert__body">
      <h2>Invite new user to workspace</h2>
      <div class="form-field">
        <label for="input-email">
          Enter new user email address
        </label>

        <div
          :class="{'error': validator.$errors.length }"
          class="validated-input"
        >
          <input
            id="input-email"
            v-model="state.email"
            class="custom-input custom-input--small"
            type="email"
            required
          />

          <div
            v-for="error of validator.email.$errors"
            :key="error.$uid"
            class="validated-input__error-text"
          >
            <div class="error-msg">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>

      <div class="custom-alert__footer">
        <button
          class="secondary-button"
          @click="clickCancel"
        >
          Cancel
        </button>
        <button
          class="primary-button"
          :disabled="checkEmail"
          @click="clickSendInvitation"
        >
          Send invitation
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.validated-input {
  margin-top: 1rem;
}
</style>
