<script setup lang="ts">
import { SkillAction, SkillActionField } from "@/types/actions.ts"
import InputMultiselect from "@/components/actions/elements/InputMultiselect.vue"
import LabelForm from "@/components/actions/elements/LabelForm.vue"

const props = defineProps<{
  actionData: SkillAction
  availableFields: SkillActionField[]
}>()

const emit = defineEmits(["formEvent"])
</script>

<template>
  <div class="action-form settings-block param-list__item">
    <label-form
      description="Select the Field that contains the ID of the Contact in Collextr"
      label="HUBSPOT CONTACT ID"
    >
      <input-multiselect
        v-model="actionData.ui.record_field_contact"
        :required="!actionData.ui.record_field_company"
        :options="props.availableFields"
        :allow-empty="true"
        :preselect-first="false"
        display-label="name"
        track-by="name"
        placeholder="Select one Skills attribute"
      />
    </label-form>

    <label-form
      description="Select the Field that contains the ID of the Company in Collextr"
      label="HUBSPOT COMPANY ID"
    >
      <input-multiselect
        v-model="actionData.ui.record_field_company"
        :required="!actionData.ui.record_field_contact"
        :options="props.availableFields"
        :allow-empty="true"
        :preselect-first="false"
        display-label="name"
        track-by="name"
        placeholder="Select one Skills attribute"
      />
    </label-form>

    <label-form
      description="Select the field from which the text for the note will be taken"
      label="NOTE CONTENT FIELD"
    >
      <input-multiselect
        v-model="actionData.ui.content_field_note"
        :required="true"
        :options="props.availableFields"
        :allow-empty="false"
        :preselect-first="true"
        display-label="name"
        track-by="name"
        placeholder="Select the field from which the text for the note will be taken"
      />
    </label-form>
  </div>
</template>
