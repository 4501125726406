<script setup lang="ts">
import { container } from "@/services/container.ts"
import { onMounted, ref, shallowRef } from "vue"
import BaseNav from "@/components/BaseNav.vue"
import { useRoute } from "vue-router"
import { Paginator } from "@/types/api.ts"
import PagePaginator from "@/components/PagePaginator.vue"
import IconSettings from "@/components/icons/IconSettings.vue"
import IconDelete from "@/components/icons/IconDelete.vue"
import { ROUTE_NAMES } from "@/router/names.ts"
import { promptModal } from "jenesius-vue-modal"
import ConfirmModal from "@/components/modals/ConfirmModal.vue"
import ActionTemplateStatus from "@/components/actions/templates/ActionTemplateStatus.vue"
import { ACTION_TYPES, RESPONSE_TYPE } from "@/types/actions.ts"
import { GlobalTemplateAction } from "@/types/templates.ts"

const route = useRoute()
let page = Number(route.query.page || 1)
let perPage = Number(route.query.per_page || 20)

const data = ref<Paginator<GlobalTemplateAction> | null>(null)

const navItems = [
  {
    link: null,
    label: "Global actions templates",
  },
]

onMounted(async () => {
  await loadData()
})

const deleteTemplate = async (templateActionId: string) => {
  const title = `Delete the action global template?`

  let isDelete = (await promptModal(ConfirmModal, {
    title: title,
    buttonConfirmationText: "Delete",
    classCSSButtonConfirmation: "cancel-button",
    classCSSButtonRejection: "secondary-button",
    iconComponent: shallowRef(IconDelete)
  })) as boolean

  if (isDelete) {
    const result = await container.globalTemplateActionRepository.delete(templateActionId)
    if (result.status === "ok") {
      await loadData()
    }
  }
}

const loadData = async () => {
  const result = await container.globalTemplateActionRepository.list(
    ACTION_TYPES.OPEN_AI_ACTION,
    page,
    perPage,
    "",
    true,
  )
  data.value = result.data
}

const isStructuredData = (responseType: string) => {
  return responseType !== RESPONSE_TYPE.TEXT
}

</script>

<template>
  <main>
    <base-nav :items="navItems" icon="settings" />

    <h2>Global actions templates</h2>

    <div v-if="data">
      <table class="runs-table__table">
        <thead>
        <tr>
          <th>Global action name</th>
          <th>Description</th>
          <th></th>
        </tr>
        </thead>
        <tr
          v-for="(template, index) of data.items"
          :key="`admin-global-template-action-${index}`"
        >
          <td>
            <action-template-status
              :is-public="template.is_public"
              :is-structured-data="isStructuredData(template.content.ui?.response_type)"
              has-public
              has-structured-data
            />

            <div>
              {{ template.content.name }}
            </div>

          </td>

          <td>
            {{ template.content.description }}
          </td>

          <td>
            <div class="wrap-actions">
              <router-link
                class="icon-button"
                :to="{name: ROUTE_NAMES.TEMPLATE_ACTION_EDIT, params: { templateActionId: template.id }}"
              >
                <icon-settings />
              </router-link>

              <button
                class="icon-button"
                @click="deleteTemplate(template.id)"
              >
                <icon-delete />
              </button>
            </div>
          </td>
        </tr>
      </table>

      <page-paginator
        :page="page" :per-page="perPage"
        :page-range="data.page_range"
        :count="data.count"
      />
    </div>
  </main>
</template>
<style scoped>
.wrap-actions {
  display: flex;
  justify-content: space-evenly;
}
</style>