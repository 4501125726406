<script setup lang="ts">
import { FilterOperators, SkillActionField } from "@/types/actions"
import { computed, onMounted, ref } from "vue"

const props = defineProps<{
  availableFields: SkillActionField[],
  conditionName: string,
  operators: FilterOperators,
  index: number,
  condition: any
}>()

const emit = defineEmits(["delete"])

const baseOperators = ["IS_EMPTY", "IS_NOT_EMPTY"]

const comparisonOperators = computed(() => {
  const fieldName = props.condition.params.value_1.params.field_name
  const selectField = props.availableFields.find(field => field.name === fieldName)
  if (selectField) {
    return props.operators[selectField.type].concat(baseOperators)
  }
  return []
})

const operatorDisplay = (operator: string) => operator.replace(new RegExp("\_", "g"), " ")

const showInput = ref(true)
const typeInput = ref<"text" | "number" | "boolean">("text")


const updateUiValues = () => {
  const fieldName = props.condition.params.value_1.params.field_name
  const field = props.availableFields.find(field => field.name === fieldName)
  if (field) {
    props.condition.params.value_1.params.field_type = field.type
    props.condition.params.value_1.params.field_sub_type = field.sub_type

    const valueIsArray = ["IN_LIST", "NOT_IN_LIST"].includes(props.condition.params.operator)

    let valueTypeName = field.type
    if (["linkedin_url", "site_url"].includes(valueTypeName)) {
      valueTypeName = "string"
    }

    if (valueIsArray) {
      valueTypeName = "array"
    }

    props.condition.params.value_2.params.type_name = valueTypeName

    if (field.type === "boolean" && props.condition.params.operator === "EQUALS") {
      typeInput.value = "boolean"
    } else {
      if (field.type === "number" && !valueIsArray) {
        typeInput.value = "number"
      } else {
        typeInput.value = "text"
      }
    }

    if (field.type === "array") {
      if (field.sub_type === "number") {
        typeInput.value = "number"
      } else {
        typeInput.value = "text"
      }
    }
  }

  showInput.value = !["IS_EMPTY", "IS_NOT_EMPTY"].includes(props.condition.params.operator);
}

onMounted(async () => {
  updateUiValues()
})
</script>

<template>
  <div class="condition-settings__row">
    <div class="condition-settings__label page-title">
      {{ props.conditionName }}
    </div>
    <div class="condition-settings__inner">
      <div class="condition-settings__param">
        <div class="condition-settings__param-name text-style">Field</div>
        <div class="condition-settings__param-field">
          <select class="custom-select text-style"
                  v-model="props.condition.params.value_1.params.field_name"
                  required
                  @change="updateUiValues()"
          >
            <option v-for="availableField of props.availableFields" :value="availableField.name"
                    :key="availableField.name">
              {{ availableField.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="condition-settings__param">
        <div class="condition-settings__param-name text-style">
          Condition
        </div>
        <div class="condition-settings__param-field">
          <select class="custom-select text-style"
                  v-model="props.condition.params.operator"
                  @change="updateUiValues()">
            <option v-for="operator of comparisonOperators"
                    :key="operator"
                    :value="operator"
                    :selected="operator === condition.params.operator"
            >
              {{ operatorDisplay(operator) }}
            </option>
          </select>
        </div>
      </div>
      <div class="condition-settings__param">
        <template v-if="showInput">
          <div class="condition-settings__param-name text-style">Value</div>
          <div class="condition-settings__param-field">
            <select v-if="typeInput === 'boolean'" class="custom-select text-style"
                    v-model="props.condition.params.value_2.params.value">
              <option key="true" value="true">True</option>
              <option key="false" value="false">False</option>
            </select>
            <input v-else
              v-model="props.condition.params.value_2.params.value"
              required
              class="custom-select text-style"
              :type="typeInput"
            />
          </div>
        </template>
      </div>
    </div>
    <button
      class="condition-settings__del-btn icon-button"
      type="button"
      @click="emit('delete', index)"
    >
      <svg width="24" height="24">
        <use xlink:href="#ic_delete"></use>
      </svg>
    </button>
  </div>
</template>
