<script setup lang="ts">

const props = defineProps<{
  icon?: string,
  type?: string
}>()

const emit = defineEmits(["click"])

</script>

<template>
  <button
    :class="{
      'icon-button': icon,
      'settings-block__control-btn secondary-button': !icon,
      'btn-color-primary': type === 'primary',
      'btn-color-error': type === 'error',
    }"
    @click.prevent="emit('click')">
    <svg v-if="props.icon === 'delete'" width="24" height="24">
      <use xlink:href="#ic_delete"></use>
    </svg>
    <svg v-if="props.icon === 'settings'" width="16" height="16">
        <use xlink:href="#ic_settings"></use>
    </svg>
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<style scoped>
.btn-color-primary {
  background-color: #001846;
  color: white;
}
.btn-color-error {
  background-color: #963C51;
  color: white;
}
</style>