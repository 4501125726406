<script setup lang="ts">
import LabelForm from "@/components/actions/elements/LabelForm.vue"
import { SkillAction, SkillActionField } from "@/types/actions.ts"
import IconPlus from "@/components/icons/IconPlus.vue"
import { computed } from "vue"
import LeaveAttribute from "@/components/actions/deleteColumns/LeaveAttribute.vue"

const props = defineProps<{
  actionData: SkillAction
  availableFields: SkillActionField[]
}>()

const emit = defineEmits(["formEvent"])

const addColumn = () => {
  emit("formEvent", "add-column")
}
const deleteColumn = (index: number) => {
  emit("formEvent", "delete-column", index)
}

const availableFieldsFiltered = computed(() => {
  return props.availableFields.filter(fieldAvailable => {
    return !props.actionData.ui.columns_leave
      .find((fieldLeave: SkillActionField) => fieldLeave.name === fieldAvailable.name)
  })
})

</script>

<template>
  <div class="action-form settings-block">
    <label-form
      label="Leave columns"
      description="Please select which columns do not need to be deleted"
    />

    <div class="columns">
      <div
        v-for="(field, index) in props.actionData.ui.columns_leave"
        :key="`leave-column-${field.name}`"
        class="wrap-column"
      >
        <div class="column">
          <leave-attribute
            v-model="props.actionData.ui.columns_leave[index]"
            :options="availableFieldsFiltered"
          />

          <div
            v-if="props.actionData.ui.columns_leave.length > 1"
            class="settings-block__control-btn secondary-button"
            @click="deleteColumn(index)"
          >
            <span>Delete</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="action-param__row action-param__row--pv0"
    >
      <button
        class="action-param__add-btn outline-button outline-button--wide"
        type="button"
        @click="addColumn()"
      >
        <icon-plus />
        <span>Add attribute</span>
      </button>
    </div>

  </div>
</template>

<style scoped>
.action-param__add-btn {
  margin: 1rem 0 5rem 0
}

.columns {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrap-column {
  display: flex;
  justify-content: flex-end;
}

.column {
  max-width: 588px;
  flex: 1;
  display: flex;
}
</style>