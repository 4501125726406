<script setup lang="ts">
import IconSave from "@/components/icons/IconSave.vue"

const props = defineProps<{
  disabled?: boolean
}>()

const emit = defineEmits(["save", "cancel"])
</script>

<template>
  <div class="settings-block__controls">
    <button
      class="settings-block__control-btn secondary-button"
      @click="emit('cancel')"
    >
      <span>Cancel</span>
    </button>
    <button
      class="settings-block__control-btn primary-button"
      :disabled="props.disabled"
      @click="emit('save')"
    >
      <icon-save />
      <span>Save</span>
    </button>
  </div>
</template>
