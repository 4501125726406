<script setup lang="ts">

enum StyleTypeInput {
  Default = "Default",
  Button = "Button",
  Cart = "Cart"
}

const props = defineProps<{
  label: string
  description: string | null
  placeholder: string | null
  modelValue: string
  name?: string | null
  options: { label: string; value: string }[]
  type?: string | null
  error?: string
}>()

const emit = defineEmits(["update:modelValue"])
</script>

<template>
  <div class="settings-block__row">
    <div class="settings-block__col settings-block__col--left">
      <div class="settings-block__attr sub-title">{{ props.label }}</div>
      <div class="settings-block__hint hint-text">{{ props.description }}</div>
    </div>
    <div class="settings-block__col settings-block__col--right">
      <div v-if="props?.type === StyleTypeInput.Button" class="button-group">
        <label
            v-for="option in options"
            :key="option.value"
            class="button-group__item"
            @click="emit('update:modelValue', option.value)"
        >
          <input
              class="button-group__radio js-add-action-form-filter"
              type="radio"
              name="pilot"
              :value="option.value"
              :checked="option.value === props.modelValue"
          />
          <div class="button-group__btn">{{ option.label }}</div>
        </label>
      </div>

      <div v-else-if="props?.type === StyleTypeInput.Cart" class="settings-block__grid">
        <label
            v-for="option in options"
            :key="option.label"
            class="settings-block__grid-item model-card"
            @click="emit('update:modelValue', option.label)"
        >
          <input
              class="model-card__input"
              name="model_type"
              type="radio"
              :value="option.label"
              :checked="option.label === props.modelValue"
          />
          <div class="model-card__info">
            <div class="model-card__title">{{ option.label }}</div>
            <div class="model-card__description">
              {{ option.value }}
            </div>
          </div>
        </label>
      </div>

      <div v-else class="settings-block__grid">
        <label
            v-for="option in options"
            :key="option.value"
            class="settings-block__grid-item settings-block__grid-item--inline custom-radio"
            @click="emit('update:modelValue', option.value)"
        >
          <input
              class="custom-radio__input"
              type="radio"
              name="response_type"
              :value="option.value"
              :checked="option.value === props.modelValue"
          />
          <div class="custom-radio__text">{{ option.label }}</div>
        </label>
      </div>
    </div>
  </div>
</template>
