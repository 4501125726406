import { AxiosInstance } from "axios"
import { Paginator, ResponseApi } from "@/types/api.ts"
import { notify } from "@kyvg/vue3-notification"
import { GlobalTemplateAction } from "@/types/templates.ts"
import { SkillAction } from "@/types/actions.ts"

export class GlobalTemplateActionRepository {
  constructor(private axios: AxiosInstance) {
  }

  async list(
    actionType: string,
    page = 1,
    perPage = 20,
    search: string = "",
    isShowAll: boolean = false,
  ): Promise<ResponseApi<Paginator<GlobalTemplateAction>>> {
    const params: Record<string, string> = {
      page: page.toString(),
      per_page: perPage.toString(),
      type_action: actionType.toString(),
      search: search.toString(),
      is_show_all: isShowAll.toString(),
    }

    const query = (new URLSearchParams(params)).toString()
    const result = await this.axios.get(`/admin/api/global/template/actions?${query}`)

    return result.data
  }

  async get(templateId: string): Promise<ResponseApi<GlobalTemplateAction>> {
    const result = await this.axios.get(`/admin/api/global/template/actions/${templateId}`)

    return result.data
  }

  async create(action: SkillAction, isPublic: boolean): Promise<ResponseApi<null>> {
    const title = "Create global template action"
    let type = "error"
    let message = "An error happened during the operation"

    try {
      const data = {
        action: action,
        is_public: isPublic,
      }
      const result = await this.axios.post(
        "/admin/api/global/template/actions/create",
        data,
      )

      if (result.data.status === "ok") {
        type = "success"
        message = "Global template was successfully saved"
      }

      return result.data
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async update(action: SkillAction, isPublic: boolean, actionTemplateId: string): Promise<ResponseApi<null>> {
    const title = "Update global template action"
    let type = "error"
    let message = "An error happened during the operation"

    try {
      const data = {
        action: action,
        is_public: isPublic,
      }
      const result = await this.axios.post(
        `/admin/api/global/template/actions/${actionTemplateId}/update`,
        data,
      )

      if (result.data.status === "ok") {
        type = "success"
        message = "Global template was successfully saved"
      }

      return result.data
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async delete(actionTemplateId: string): Promise<ResponseApi<null>> {
    const title = "Delete global template action"
    let type = "error"
    let message = "An error happened during the operation"

    try {
      const result = await this.axios.post(`/admin/api/global/template/actions/${actionTemplateId}/delete`)

      if (result.data.status === "ok") {
        type = "success"
        message = "Global template was successfully deleted"
      }

      return result.data
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }
}