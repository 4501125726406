<script setup lang="ts">

const props = defineProps<{
  disabled?: boolean
}>()

const emit = defineEmits(["cancel", "save"])

const clickCancel = () => {
  emit("cancel")
}

const clickSave = () => {
  emit("save")
}
</script>

<template>
  <div class="settings-block__controls">
    <button
      id="controls-btn-cancel"
      class="settings-block__control-btn secondary-button"
      @click.prevent="clickCancel"
    >
      <span>Cancel</span>
    </button>
    <button
      id="controls-btn-save"
      class="settings-block__control-btn primary-button"
      :disabled="props.disabled"
      @click="clickSave"
    >
      <svg width="16" height="16">
        <use xlink:href="#ic_save"></use>
      </svg>
      <span>Save</span>
    </button>
  </div>
</template>
