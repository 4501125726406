<script setup lang="ts">
import { container } from "@/services/container"
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import IconHubSpot from "@/components/icons/IconHubSpot.vue"
import BaseNav from "@/components/BaseNav.vue"
import { Integration, IntegrationStatus, IntegrationType } from "@/types/integration.ts"
// @ts-ignore
import { ClipLoader } from "vue3-spinner"

const ROUTE_NAMES = {
  INTEGRATION_CONFIGURE: "integrations-configure",
}

const route = useRoute()
const ws = computed(() => route.params.ws.toString())

const navItems = [
  {
    link: `/ws/${ws.value}/settings`,
    label: "Manage Workspaces",
  },
  {
    link: null,
    label: "Integrations",
  },
]

const loadingIntegrations = ref(false)


const integrations = ref<Integration[] | null>(null)

onMounted(async () => {
  loadingIntegrations.value = true
  try {
    integrations.value = await container.integrationRepository.getIntegrations(ws.value)
  } finally {
    loadingIntegrations.value = false
  }
})

const disconnect = async (integrationId: string) => {
  loadingIntegrations.value = true
  try {
    const result = await container.integrationRepository.disconnectIntegrations(
      ws.value,
      integrationId,
    )

    if (result.status === "ok") {
      integrations.value = await container.integrationRepository.getIntegrations(ws.value)
    }
  } finally {
    loadingIntegrations.value = false
  }
}

</script>

<template>
  <div class="skills__body">
    <base-nav :items="navItems" icon="settings"/>

    <h2>Available Integrations</h2>

    <div class="skills__block">
      <div v-if="loadingIntegrations" class="loading-integrations">
        <ClipLoader
          color="#001846"
          size="100px" />
      </div>

      <div
        v-if="!loadingIntegrations"
        v-for="(integration, index) in integrations"
        :key="`integration_${index}_${integration.id}`"
        class="skill-card integration"
      >
        <div class="header">
          <div class="icon">
            <icon-hub-spot v-if="integration.type === IntegrationType.HUBSPOT" />
          </div>
          <div class="wrap-title">
            <div
              :class="{
                'status-label--completed': integration.status === IntegrationStatus.CONNECTED,
                'status-label--error': integration.status === IntegrationStatus.DISCONNECTED
              }"
              class="skills__status status-label"
            >
              {{ integration.status }}
            </div>
            <div class="title">
              <b>{{ integration.type }}</b>
            </div>

            <span
              v-if="integration.info"
              class="sub-title"
            >
              ID: {{ integration.info?.id }} - {{ integration.info?.user }}
            </span>
            <span
              v-else
              class="sub-title"
            >
              Bi-directional integration with HubSpot CRM
            </span>
          </div>
        </div>

        <div v-if=" integration.status === IntegrationStatus.CONNECTED">
          <router-link
            :to="{'name': ROUTE_NAMES.INTEGRATION_CONFIGURE, params: {ws: ws, integrationId: integration?.id}}"
            class="skills__control-btn primary-button"
          >
            <span>Configure</span>
          </router-link>

          <button
            class="skills__control-btn primary-button"
            @click="disconnect(integration?.id)"
          >
            Disconnect
          </button>
        </div>
        <div v-else>
          <a
            :href="integration?.auth_url"
            class="mates__control-btn primary-button"
          >
            <span>Connect</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.integration {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    .icon {
      margin-right: 15px;
    }

    .wrap-title {
      text-transform: capitalize;

      .title {
        margin: 3px 0
      }

      .sub-title {
        color: #bebdbf;
        font-size: 12px;
        font-weight: normal;
        text-transform: none;
      }
    }
  }
}
.loading-integrations {
  margin-top: 100px;
}
</style>