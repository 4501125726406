<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount } from "vue"

defineProps<{
  currentLineExecuted?: string | number | null,
  numberLines?: string | number | null,
}>()

const idSetInterval = ref<number|null>(null)

const emit = defineEmits(["tick"])

const timer = () => {
  idSetInterval.value = setInterval(() => {
    emit("tick")
  }, 1000)
}

onBeforeUnmount(() => {
  if (idSetInterval.value) {
    clearInterval(idSetInterval.value)
    idSetInterval.value = null
  }
})

onMounted(() => {
  timer()
})

</script>

<template>
  <div
    style="width: 250px;"
  >
    <progress
      :value="currentLineExecuted ?? 0"
      :max="numberLines ?? 0"
    >
    </progress>
    <div>
      {{ currentLineExecuted ?? 0 }}
      rows out of
      {{ numberLines ?? 0 }}
    </div>
  </div>
</template>
