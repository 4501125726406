<script setup lang="ts">

import useUser from "@/compositions/useUser.ts"
import { computed } from "vue"
import useProjectSettings from "@/compositions/useProjectSettings.ts"

const { currentWorkspace } = useUser()
const { productStripeSettings } = useProjectSettings()

const linkToBuyAdditionalOperations = async () => {
  const email = currentWorkspace.value?.subscription?.customer.email
  const clientReferenceId = `${currentWorkspace.value?.id}`
  const url = `${productStripeSettings.value?.linkToBuyAdditionalOperations}?prefilled_email=${email}&client_reference_id=${clientReferenceId}`

  window.open(url, "_blank")
}

const cannot = computed(() => {
  return !currentWorkspace.value?.is_allowed_period_now
})

</script>

<template>
  <button
    class="skills__control-btn primary-button"
    :disabled="cannot"
    @click="linkToBuyAdditionalOperations"
  >
    Buy Additional Operations
  </button>
</template>
