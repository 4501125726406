import { Price } from "@/types/price"
import { AxiosInstance } from "axios"

export class PriceRepository {
  constructor(private axios: AxiosInstance) {}

  async prices(): Promise<Price[]> {
    const res = await this.axios.get("/admin/api/prices")
    return res.data.data
  }

  async create(price: Price): Promise<Price> {
    const res = await this.axios.post("/admin/api/prices/create", price)
    return res.data.data
  }

  async edit(price: Price): Promise<Price> {
    const res = await this.axios.post(`/admin/api/prices/${price.id}`, price)
    return res.data.data
  }

  async delete(priceId: number): Promise<void> {
    await this.axios.post(`/admin/api/prices/${priceId}/delete`)
  }
}