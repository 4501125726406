import { Workspace } from "@/types/workspace"
import { Paginator } from "@/types/api.ts"
import { AxiosInstance } from "axios"
import { notify } from "@kyvg/vue3-notification"
import ApiToken from "@/types/admin.ts"

export class UserAdminRepository {
  constructor(private axios: AxiosInstance) {
  }

  async workspaces(page = 1, perPage = 20): Promise<Paginator<Workspace>> {
    const params: Record<string, string> = {
      page: page.toString(),
      per_page: perPage.toString(),
    }
    const query = (new URLSearchParams(params)).toString()
    const res = await this.axios.get("/users/api/admin/workspaces?" + query)
    return res.data.data
  }

  async companiesRequest(linked_url: string, url: string): Promise<{
    status: string,
    data: object
  }> {
    const data = {
      linked_url: linked_url,
      url: url,
    }

    const res = await this.axios.post(
      "/users/api/admin/companies_request",
      data,
    )

    return res.data
  }

  async getGlobalSettings(): Promise<{
    status: string,
    data: {
      settings: {
        content: object
      },
      api_tokens: ApiToken[]
    }
  }> {
    const res = await this.axios.get("/users/api/admin/global-settings")

    return res.data
  }

  async updateGlobalSettings(settings: string): Promise<{
    status: string,
    data: object,
    message: string
  }> {
    const data = {
      settings: settings,
    }

    const res = await this.axios.post(
      "/users/api/admin/global-settings/update",
      data,
    )

    if (res.data.status === "ok") {
      notify({
        type: "success",
        title: "Update global settings",
        text: "The settings have been successfully updated",
      })
    }

    return res.data
  }
}
