import { defineStore } from "pinia"
import { ProjectSettings } from "@/types/settings.ts"

export const projectSettingsStore = defineStore("projectSettings", {
  state: (): ProjectSettings => {
    return {
      env: null,
      productStripeSettings: null,
    }
  },
  getters: {
    getProjectSettings: (state) => state,
  },
})
