<script setup lang="ts">
import CInputText from "@/components/elements/CInputText.vue"
import { computed, onMounted } from "vue"
import { SkillAction, SkillActionField } from "@/types/actions"
import ActionFilterCondition from "@/components/actions/filter/ActionFilterCondition.vue"
import { conditionNameList } from "@/components/actions/filter/filter"
import useSkill from "@/compositions/useSkill"

const props = defineProps<{ actionData: SkillAction, availableFields: SkillActionField[] }>()

const emit = defineEmits(["formEvent"])

const { operators, fetchOperators } = useSkill()

const conditionList = computed(() => {
  if (props.actionData.ui.conditionAnd === undefined) {
    return null
  }
  return props.actionData.ui.conditionAnd.params.values
})

const addCondition = () => {
  emit("formEvent", 'add-condition')
}

const deleteCondition = (index: number) => {
  props.actionData.ui.conditionAnd.params.values.splice(index, 1)
  emit("formEvent", 'delete-condition', index)
}

onMounted(async () => {
  await fetchOperators()
})
</script>

<template>
  <div v-if="conditionList && operators" class="action-form">
    <c-input-text
      v-model="actionData.ui.conditionFormula"
      label="Condition formula"
      description="Description"
      placeholder="Condition formula"
    />

    <div class="condition-settings">
      <div class="condition-settings__content">
        <action-filter-condition
          v-for="[index, condition] of conditionList.entries()"
          :key="index"
          :index="index"
          :condition="condition"
          :available-fields="availableFields"
          :operators="operators"
          :condition-name="conditionNameList[index]"
          @delete="deleteCondition"
        />
        <button
          class="condition-settings__add-btn outline-button outline-button--wide"
          type="button"
          @click="addCondition()"
        >
          <svg width="16" height="16">
            <use xlink:href="#ic_plus"></use>
          </svg>
          <span>Add Condition</span>
        </button>
      </div>
    </div>
  </div>
</template>
