<script setup lang="ts">
import { reactive, ref, toRaw } from "vue"
import { email, required } from "@vuelidate/validators"
import useVuelidate, { ServerErrors } from "@vuelidate/core"
import { container } from "@/services/container.ts"
import { useRouter } from "vue-router"
import FieldInput from "@/components/auth/FieldInput.vue"
import FieldButton from "@/components/auth/FieldButton.vue"

const router = useRouter()
const $externalResults = ref<ServerErrors>({})

const state = reactive({
  email: '',
})

const rules = {
  email: {
    required,
    email,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, state, { $externalResults })

const submit = async () => {
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    return
  }

  const result = await container.userRepository.forgotRequest(toRaw(state))

  if (result?.status === "ok") {
    sessionStorage.setItem("forgot_email", state.email)
    await router.push({ name: "user-forgot-password-status", query: { type: "send" } })
  } else {
    $externalResults.value.email = result.data.message as ServerErrors
  }
}

</script>

<template>
  <form
    class="login__form"
    action=""
    method="post"
    @submit.prevent="submit"
  >
    <h1 class="login__title main-title">Reset your password</h1>

    <div class="message">
      Please enter the email address you'd like your password reset information sent to:
    </div>

    <field-input
      v-model="state.email"
      :errors="validator.email"
      name="email"
      placeholder="Email"
    />

    <field-button
      title="Reset your password"
    />
  </form>
</template>

<style scoped>
.message {
  text-align: center;
  font-weight: bold;
  margin: 2rem 0;
}
</style>
