import { SYMBOL_TRIGGER } from "@/types/mentionable.ts"

const properties = [
  "boxSizing",
  "width",
  "height",
  "overflowX",
  "overflowY",
  "borderTopWidth",
  "borderRightWidth",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderStyle",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
  "fontStyle",
  "fontVariant",
  "fontWeight",
  "fontStretch",
  "fontSize",
  "fontSizeAdjust",
  "lineHeight",
  "fontFamily",
  "textAlign",
  "textTransform",
  "textIndent",
  "textDecoration",
  "letterSpacing",
  "wordSpacing",
  "tabSize",
]

const NODE_INPUT = "INPUT"
const LINE_HEIGHT = "lineHeight"

/**
 * Calculation of the cursor position in the text field
 * @param element HTML element
 * @param position Index of cursor in text field
 * @param documentMock
 */
export function getCaretCoordinates(
  element: HTMLInputElement | HTMLTextAreaElement,
  position: number,
  documentMock: Document | null = null,
) {
  const document = documentMock ?? window.document
  const div = window.document.createElement("div")

  div.id = "input-textarea-caret-position-mirror-div"
  document.body.appendChild(div)

  const style: CSSStyleDeclaration = div.style
  const computed = getComputedStyle(element)
  const isInput = element.nodeName === NODE_INPUT

  style.whiteSpace = "pre-wrap"
  style.wordWrap = "break-word"
  style.position = "absolute"
  style.visibility = "hidden"
  style.overflow = "hidden"

  properties.forEach(function(prop: string) {
    // @todo Does not type styles, methods for getting properties returns a different result
    // @ts-ignore
    style[prop] = <CSSStyleDeclaration>computed[prop]
    if (isInput && prop === LINE_HEIGHT) {
      style.lineHeight = computed.height
    }
  })

  div.textContent = element.value.substring(0, position)

  if (isInput && div.textContent) {
    div.textContent = div.textContent.replace(/\s/g, "\u00a0")
  }

  const span = document.createElement("span")

  span.textContent = element.value.substring(position) || "."
  div.appendChild(span as Node)

  const coordinates = {
    top: span.offsetTop + parseInt(computed["borderTopWidth"]),
    left: span.offsetLeft + parseInt(computed["borderLeftWidth"]),
    height: parseInt(computed["lineHeight"]),
  }

  div.remove()
  return coordinates
}

export function getTextRelativeNearestTriggerCharacter(
  textInput: string,
  caretIndex: number,
  keyIndex: number,
) {
  const isNotValidTrigger = keyIndex === -1
    || textInput[keyIndex - 1] === SYMBOL_TRIGGER
    || textInput[keyIndex + 1] === SYMBOL_TRIGGER

  if (isNotValidTrigger) {
    return null
  }

  const text = textInput.substring(keyIndex + 1, caretIndex)
  if (!/[\s#]/.test(text)) {
    return text
  }

  return null
}