<script setup lang="ts">
import SettingsField from "@/components/settings/SettingsField.vue"
import SettingsControls from "@/components/settings/SettingsControls.vue"
import { container } from "@/services/container"
import useUser from "@/compositions/useUser.ts"
import { ref } from "vue";

const { user, validator, $externalResults, initUser } = useUser()

const disabledSaveBtn = ref(false)

const cancel = () => {
}
const save = async () => {
  disabledSaveBtn.value = true
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    disabledSaveBtn.value = false
    return
  }

  try {
    const result = await container.userRepository.updateGeneralData({
      "last_name": user.value.last_name,
      "first_name": user.value.first_name,
    })

    if (result.status === "ok") {
      await initUser()
    } else {
      Object.assign($externalResults, result.data.message)
    }
  } finally {
    disabledSaveBtn.value = false
  }
}

</script>

<template>
  <div>
    <h2>General Data</h2>

    <div class="skills__block settings-block">
      <settings-field
        v-model="user.first_name"
        :errors="validator.first_name"
        :label="{title: 'First Name', description: 'Your first name'}"
      />

      <settings-field
        v-model="user.last_name"
        :errors="validator.last_name"
        :label="{title: 'LAST NAME', description: 'Your last name'}"
      />

      <settings-controls
        :disabled="disabledSaveBtn"
        @cancel="cancel"
        @save="save"
      />
    </div>
  </div>
</template>
