import { UserProfile, Login, SignUp, ResponseError, SignUpInvitation } from "@/types/user"
import { AxiosInstance } from "axios"
import { notify } from "@kyvg/vue3-notification"
import { ResponseApi, ResponseApiMessage } from "@/types/api.ts"
import { ProjectSettings } from "@/types/settings.ts"

export class UserRepository {
  constructor(private axios: AxiosInstance) {
  }

  async projectSettings(): Promise<ProjectSettings> {
    const response = await this.axios.get(`/users/api/project/settings`)
    return response.data.data
  }

  async profile(): Promise<UserProfile & ResponseError> {
    try {
      const data = await this.axios.get(`/users/api/user_profile`)
      return data.data.data
    } catch (error: any) {
      return error.response?.data ?? null
    }
  }

  async login(data: Login): Promise<Login & ResponseError> {
    return (await this.axios.post("/users/api/login", data)).data
  }

  async signup(data: SignUp): Promise<SignUp & ResponseError> {
    return (await this.axios.post("/users/api/signup", data)).data
  }

  async signupInvitation(data: SignUpInvitation): Promise<ResponseApi<{ email: string } | ResponseApiMessage>> {
    return (await this.axios.post(`/users/api/signup/invite`, data)).data
  }

  async forgotRequest(data: { email: string }): Promise<ResponseApi<ResponseApiMessage>> {
    const result = await this.axios.post("/users/api/forgot/password/request", data)
    return result.data
  }

  async forgotReset(
    uid: string,
    token: string,
    data: { password: string, confirm_password: string },
  ): Promise<ResponseApi<ResponseApiMessage>> {
    const result = await this.axios.post(
      "/users/api/forgot/password/reset",
      {
        "uidb64": uid,
        "token": token,
        "data": data,
      },
    )

    return result.data
  }

  async updateGeneralData(data: {
    last_name: string,
    first_name: string,
  }): Promise<ResponseApi<ResponseApiMessage>> {
    const title = "Setting user"
    let type = "error"
    let message = "Error update setting user"

    try {
      const result = await this.axios.post(
        "/users/api/profile/settings/general/edit",
        {
          "last_name": data.last_name,
          "first_name": data.first_name,
        },
      )

      type = "info"
      message = result.data.data.message

      return result.data
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }

  async updatePasswordData(data: {
    current_password: string,
    new_password: string,
    confirm_password: string,
  }): Promise<ResponseApi<ResponseApiMessage>> {
    const title = "Setting user"
    let type = "error"
    let message = "Error update password user"

    try {
      const result = await this.axios.post(
        "/users/api/profile/settings/password/edit",
        {
          "current_password": data.current_password,
          "confirm_password": data.confirm_password,
          "new_password": data.new_password,
        },
      )

      if (result.data.status === "ok") {
        type = "success"
        message = "Password change successfully"
      }

      return result.data
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }
}
