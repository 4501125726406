<script setup lang="ts">
import { useRoute } from "vue-router"
import ActionFormBase from "@/components/actions/ActionFormBase.vue"
import { onMounted, ref, toRaw } from "vue"
import { container } from "@/services/container.ts"
import { ActionTypeUi } from "@/types/actions.ts"
import { getActionByType, getActionClass } from "@/components/actions"
import { SkillTemplate } from "@/types/skill"
import BaseNav from "@/components/BaseNav.vue"

const route = useRoute()

const templateId = Number(route.params.templateId)
const actionId = route.params.actionId as string

const data = ref<SkillTemplate | null>(null)

const actionComponent = ref<ActionTypeUi | null>(null)
const actionClass = ref<any | null>(null)

const navItems = [
  {
    link: "/users/admin/global-templates",
    label: "Global Skills templates",
  },
  {
    link: `/users/admin/global-templates/${templateId}`,
    label: "",
  },
  {
    link: null,
    label: "",
  },
]

async function getAction() {
  if (data.value && data.value.actions) {
    let action = data.value.actions.find((action) => action.id === actionId)
    if (action) {
      action = toRaw(action)

      navItems[2].label = action.name

      actionClass.value = getActionClass(action.type)
      
      console.log('action', action)
      
      actionComponent.value = getActionByType(action.type, [], action)
    }
  }
}

onMounted(async () => {
  data.value =
    await container.skillAdminRepository.getGlobalTemplate(templateId)

  navItems[1].label = data.value.name

  await getAction()
})

const getComponentName = (type: string) => {
  return type + "Component"
}
</script>

<template>
  <main v-if="data && actionComponent">
    <base-nav :items="navItems" icon="settings" />
    <div class="skills__body">
      <form class="skills__block settings-block">
        <fieldset :disabled="true">
          <action-form-base :action-data="actionComponent?.actionData" />
          <component
            :is="getComponentName(actionComponent?.actionData.type)"
            :action-data="actionComponent?.actionData"
            :available-fields="[]"
            @form-event="
              (event: string, value: any) =>
                actionComponent?.handlerEvent(event, value)
            "
          />
        </fieldset>
      </form>
    </div>
  </main>
</template>

<style scoped>
.skills__body {
  margin-top: 30px;
}
</style>