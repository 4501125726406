<script setup lang="ts">

import CStripePricingTable from "@/components/billing/CPricingTable.vue"
import IconStripe from "@/components/icons/IconStripe.vue"
import CStripeLinkManagementBilling from "@/components/billing/CStripeLinkManagementBilling.vue"
import { computed, onMounted, ref } from "vue"
import { isNotFreeUser } from "@/utils/subscription.ts"
import useUser from "@/compositions/useUser.ts"
import { container } from "@/services/container.ts"
import BillingDetails from "@/components/billing/BillingDetails.vue"
import { useRoute } from "vue-router"
// @ts-ignore
import { ClipLoader } from "vue3-spinner"
import CStripeLinkBuyAdditionalOperations from "@/components/billing/CStripeLinkBuyAdditionalOperations.vue"
import { WorkspaceStatusSubscription } from "@/types/workspace.ts"

const { interviewInitUser, currentWorkspace } = useUser()
const route = useRoute()
const ws = route.params.ws as string
const showPlan = ref(true)
const loadingIntegrations = ref(false)

onMounted(async () => {
  try {
    loadingIntegrations.value = true

    await interviewInitUser()
    const result = await container.integrationRepository.getStripeCustomer(ws)

    showPlan.value = !!result.data?.customer?.deleted
    showPlan.value = false
  } catch {
    showPlan.value = false
  } finally {
    loadingIntegrations.value = false
  }
})

const isNotFreeUserStatus = computed(() => {
  if (showPlan.value) {
    return false
  }

  return isNotFreeUser(currentWorkspace.value)
})

</script>
<template>
  <template v-if="loadingIntegrations">
    <clip-loader
      color="#001846"
      size="100px"
    />
  </template>
  <template v-else>
    <div v-if="currentWorkspace?.status !== WorkspaceStatusSubscription.FREE_PLAN">
      <billing-details />
    </div>

    <div
      v-if="!isNotFreeUserStatus"
      class="skill-card"
    >
      <c-stripe-pricing-table />
    </div>
    <div v-else>
      <div class="wrap-card">
        <div class="skill-card integration">
          <div class="header">
            <div class="icon">
              <icon-stripe />
            </div>
            <div class="wrap-title">
              <div class="title">
                <b> WE USE STRIPE FOR SECURE BILLING</b>
              </div>
              <span class="sub-title">To change your billing details, please click the button to the right</span>
            </div>
          </div>

          <div>
            <c-stripe-link-management-billing />
          </div>
        </div>
        <div class="skill-card integration">
          <div class="header">
            <div class="icon">
              <icon-stripe />
            </div>
            <div class="wrap-title">
              <div class="title">
                <b>YOU CAN PURCHASE MORE OPERATIONS THAT WILL BE AVAILABLE FOR A YEAR</b>
              </div>
              <span class="sub-title">Please click the button to the right</span>
            </div>
          </div>

          <div>
            <c-stripe-link-buy-additional-operations />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style scoped>
.wrap-card {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.integration {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    .icon {
      margin-right: 15px;
    }

    .wrap-title {
      text-transform: capitalize;

      .title {
        margin: 3px 0
      }

      .sub-title {
        color: #bebdbf;
        font-size: 12px;
        font-weight: normal;
        text-transform: none;
      }
    }
  }
}
</style>