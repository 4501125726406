<script setup lang="ts">
import { ref, watch } from "vue"
import { fileReadAsText } from "@/utils/files.ts"
import { closeModal } from "jenesius-vue-modal"
import InputFile from "@/components/shared/InputFile.vue"

const emit = defineEmits(["confirm", "cancel"])

const props = defineProps<{
  errorBackend: string,
  isLoading: boolean,
}>()

const file = ref<string | null>(null)
const error = ref("")

watch(() => props.errorBackend, () => {
  error.value = props.errorBackend
})

const uploadFile = async (event: Event) => {
  const target = event.target as HTMLInputElement
  const files: FileList | null = target.files;

  if (!files) {
    return
  }

  file.value = await fileReadAsText(files[0])
}

const uploadError = () => {
  file.value = null
}

const clickCancel = () => {
  closeModal()
  emit("cancel")
}

const clickRun = () => {
  emit("confirm", file.value)
}
</script>

<template>
  <div class="custom-pop-up__content custom-alert__content">
    <div class="custom-alert__body">

      <input-file
        v-model:error="error"
        label="Select the file"
        @upload="uploadFile"
        @upload-error="uploadError"
      />

      <div class="custom-alert__footer">
        <button
          class="custom-alert__cansel secondary-button js-custom-alert-cancel"
          type="button"
          @click="clickCancel"
        >
          Cancel
        </button>
        <button
          class="custom-alert__confirm primary-button js-custom-alert-confirm"
          type="button"
          :disabled="file === null || isLoading"
          @click="clickRun"
        >
          Run
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-alert__footer {
  padding: 24px 0;
}
.custom-alert__content {
  max-width: 700px;
}
</style>