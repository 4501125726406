<script setup lang="ts">
import { reactive, ref, toRaw } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { container } from "@/services/container.ts"
import { useRoute, useRouter } from "vue-router"
import FieldPassword from "@/components/auth/FieldPassword.vue"
import FieldButton from "@/components/auth/FieldButton.vue"
import { passwordMatchErrorText } from "@/types/auth.ts"

const router = useRouter()
const route = useRoute()

const state = reactive({
  password: "",
  confirm_password: "",
})
const globalError = ref<string[]>()

const sameAsPassword = () => {
  return {
    $message: passwordMatchErrorText,
    $validator: () => state.password === state.confirm_password,
  }
}

const rules = {
  password: {
    required,
    $autoDirty: true,
  },
  confirm_password: {
    sameAs: sameAsPassword(),
    required,
    $autoDirty: true,
  },
}

const validator = useVuelidate(rules, state)

const submit = async () => {
  const isFormCorrect = await validator.value.$validate()

  if (!isFormCorrect) {
    return
  }

  const result = await container.userRepository.forgotReset(
    route.params.uidb64.toString(),
    route.params.token.toString(),
    toRaw(state),
  )

  if (result?.status === "ok") {
    await router.push({ name: "login" })
  } else {
    globalError.value = result.data.message as string[]
  }
}

</script>

<template>
  <form
    class="login__form"
    method="post"
    @submit.prevent="submit"
  >
    <h1 class="login__title main-title">Reset your password</h1>

    <div
      v-for="(error, index) of globalError"
      :key="`error_${error}_${index}`"
      class="validated-input__error-text"
      style="display:block;"
    >
      <div class="error-msg">
        {{ error }}
      </div>
    </div>

    <field-password
      v-model="state.password"
      :errors="validator.password.$errors"
      name="password"
      placeholder="Password"
      :tabindex="1"
    />

    <field-password
      v-model="state.confirm_password"
      :errors="validator.confirm_password.$errors"
      name="confirm_password"
      placeholder="Confirm password"
      :tabindex="2"
    />

    <field-button
      title="Reset your password"
    />

    <div class="login__to-login">
      <router-link
        to="/users/forgot/password/request"
        class="login__login-link custom-link"
        data-tab-id="mates-runs"
      >
        Forgot password?
      </router-link>
    </div>
  </form>
</template>
