import { ActionTypeUi, SkillAction, SkillActionField } from "@/types/actions"
import { Ligament } from "@/types/hubspot"
import { toRaw } from "vue"
import ActionFormUpdateContactHubSpot from "@/components/actions/hubspot/ActionFormUpdateContactHubSpot.vue"

const templateLigament = () => {
  return {
    field_hubspot: {
      name: "",
      label: "",
      type: "",
      field_type: "",
    },
    field_collextr: {
      name: "",
      type: "",
    },
  }
}

const newSkillAction = (): SkillAction => {
  return {
    id: "",
    name: "",
    type: "HubSpotUpdateContactInfoAction",
    description: "",
    copilot: false,
    module: "workflow.actions.hubspot_update_contact_info",
    params: {
      mapping: [
        {
          field_hubspot: {
            name: "",
            label: "",
            type: "",
            field_type: "",
          },
          field_collextr: {
            name: "",
            type: "",
          },
        },
      ],
      record_field: {
        type: "",
        name: "",
      },
    },
    ui: {
      mapping: [templateLigament()],
      record_field: {
        type: "",
        name: "",
      },
    },
    fields_source: [],
    fields_target: [],
  }
}

export class HubspotUpdateContactInfo implements ActionTypeUi {
  public actionData: SkillAction = newSkillAction()
  public availableFields: SkillActionField[] = []
  public static typeName = "HubSpotUpdateContactInfoAction"
  public static component = ActionFormUpdateContactHubSpot

  public newActionData() {
    this.actionData = newSkillAction()
  }

  public handlerAfterLoad() {}

  public handlerBeforeSave() {
    const recordField = this.actionData.ui.record_field
    const fieldsSource: SkillActionField[] = [
      {
        name: recordField.name,
        type: recordField.type,
      },
    ]

    if (recordField.name === "") {
      throw new Error(
        "HubSpot Update Contact Info Action fields are not selected",
      )
    }

    const mapping = this.actionData.ui.mapping.map(
      (ligamentProxy: Ligament): Ligament => {
        const ligament = toRaw(ligamentProxy)

        if (
          ligament.field_hubspot.name === "" ||
          ligament.field_collextr.name === ""
        ) {
          throw new Error(
            "HubSpot Update Contact Info Action fields are not selected",
          )
        }

        fieldsSource.push(ligament.field_collextr)

        return {
          field_hubspot: ligament.field_hubspot,
          field_collextr: ligament.field_collextr,
          overwrite: ligament.overwrite,
        }
      },
    )

    this.actionData.params.record_field = recordField
    this.actionData.params.mapping = mapping
    this.actionData.fields_source = fieldsSource
  }

  public handlerEvent(event: string, value: any) {
    if (event === "add-ligament") {
      this.addLigament()
    }
    if (event === "delete-ligament") {
      this.deleteLigament(value)
    }
  }

  private addLigament() {
    this.actionData.ui.mapping.push(templateLigament())
  }

  private deleteLigament(index: number) {
    this.actionData.ui.mapping.splice(index, 1)
  }
}
