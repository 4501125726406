<script setup lang="ts">
import { useRoute } from "vue-router"
import { onMounted, reactive } from "vue"
import useSkill from "@/compositions/useSkill.ts"
import SkillHeader from "@/components/skills/SkillHeader.vue"
import SkillNav from "@/components/skills/SkillNav.vue"
import SkillTabs from "@/components/skills/SkillTabs.vue"
import { container } from "@/services/container.ts"
import { ActionJob, ActionResult, SkillJobItem } from "@/types/jobs.ts"

const route = useRoute()
const { skill, initSkill } = useSkill()

const context = reactive({
  params: {
    ws: route.params.ws as string,
    skillJob: null as SkillJobItem | null,
    actionJob: null as ActionJob | null,
    table: null as ActionResult | null,
    skillId: route.params.skillId as string,
    jobId: route.params.jobId as string,
    actionJobId: route.params.actionJobId as string,
  },
})


async function getJobsResult() {
  const data = await container.skillJobRepository.getResult(
    context.params.ws,
    context.params.skillId,
    context.params.jobId,
    context.params.actionJobId,
  )

  context.params.skillJob = data.skillJob
  context.params.actionJob = data.actionJob
  context.params.table = data.table

}

async function init() {
  await initSkill()
  await getJobsResult()
}

onMounted(async () => {
  await init()
})
</script>

<template>
  <main v-if="skill">
    <skill-nav :skill="skill" type="actions" />
    <skill-header :skill="skill" />
    <div v-if="context.params.skillJob" class="skills__body">
      <skill-tabs :skill="skill" type="action" :action-name="`${context.params.skillJob.process_name} - result`" />

      <div class="row">
        <div class="col-12">
          <h1>Skill job {{ context.params.skillJob.number }}</h1>
        </div>
      </div>

      <div class="col-12">
        <h2>Result {{ skill.name }} - task {{ context.params.skillJob.number }}</h2>

        <div>action: {{ context.params.actionJob?.name }}</div>

        <div>
          <a
            :href="`/ws/${context.params.ws}/skills/result/download/${context.params.actionJobId}`"
          >
            download csv
          </a>
        </div>
      </div>

      <section
        v-if="context.params.table"
        class="jobs-about__table jobs-table"
      >
        <h2>Data:</h2>
        <table class="jobs-table__table">
          <thead>
          <tr>
            <th
              v-for="(_, index) in context.params.table[0]"
              :key="`job_detail_action_result-${index}-header`"
            >
              {{ index }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(row, indexTr) in context.params.table"
            :key="`job_detail_action_result-${indexTr}-body-tr`"
          >
            <td
              v-for="(column, indexTd) in row"
              :key="`job_detail_action_result-${indexTd}-body-td`"
            >
              {{ column }}
            </td>
          </tr>

          </tbody>
        </table>
      </section>
    </div>
  </main>
</template>

<style scoped>
.jobs-table {
  overflow: auto;
}

tr {
  vertical-align: top;

  pre {
    max-width: 800px;
  }
}


pre {
  max-height: 500px;
  overflow: auto;
}
</style>