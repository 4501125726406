<script setup lang="ts">

import { ACTION_TYPES, ActionTypeModeForm, SkillAction, SkillActionTemplate } from "@/types/actions.ts"
import { onMounted, ref, toRaw } from "vue"
import { container } from "@/services/container.ts"
import { useRoute } from "vue-router"
import { closeModal, promptModal } from "jenesius-vue-modal"
import ActionTemplatesForm from "@/components/actions/templates/ActionTemplatesForm.vue"
import ConfirmModal from "@/components/modals/ConfirmModal.vue"
import { Paginator } from "@/types/api.ts"
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "@/utils/pagination.ts"
import ActionTemplate from "@/components/actions/templates/ActionTemplate.vue"
import { GlobalTemplateAction } from "@/types/templates.ts"

const PREFIX_ACTION_NAME = "Copy_"
const MESSAGE_CONFIRM_REPLACE_ACTION = "Update current action by template?"

const data = ref<Paginator<SkillActionTemplate | GlobalTemplateAction> | null>(null)
const route = useRoute()
const ws = route.params.ws as string
const tab = ref(ACTION_TYPES.OPEN_AI_ACTION)

const props = defineProps<{
  actionData: SkillAction,
  actionFormMode: ActionTypeModeForm
}>()

onMounted(async () => {
  await switchingTab(ACTION_TYPES.OPEN_AI_ACTION)
})

const useTemplate = async (action: SkillActionTemplate) => {
  let result = true

  if (props.actionFormMode === ActionTypeModeForm.ACTION_EDIT) {
    result = (await promptModal(ConfirmModal, {
      title: MESSAGE_CONFIRM_REPLACE_ACTION,
    })) as boolean
  }

  if (result) {
    const rawAction = toRaw((action))
    const actionClone = structuredClone(rawAction)
    delete actionClone.skill
    delete actionClone.position

    actionClone.name = `${PREFIX_ACTION_NAME}${action.name}`
    actionClone.id = props.actionData.id

    Object.assign(props.actionData, actionClone)
    await closeModal()
  }
}

const loadData = async (
  searchText: string,
  page: number,
  perPage: number,
) => {
  data.value = null
  let result = null
  if (tab.value === ACTION_TYPES.OPEN_AI_ACTION) {
    result = await container.skillActionRepository.listByTypeAction(
      ws,
      ACTION_TYPES.OPEN_AI_ACTION,
      page,
      perPage,
      searchText,
    )
  } else {
    result = await container.globalTemplateActionRepository.list(
      ACTION_TYPES.OPEN_AI_ACTION,
      page,
      perPage,
      searchText,
    )
  }
  data.value = result?.data
}

const switchingTab = async (currentTab: string) => {
  tab.value = currentTab
  await loadData("", DEFAULT_PAGE, DEFAULT_PER_PAGE)
}

</script>

<template>
  <action-templates-form
    :tab="tab"
    :paginate="{
      count: data?.count,
      page_range: data?.page_range,
    }"
    @switching-tab="switchingTab"
    @update-data="loadData"
  >
    <template v-slot:body>
      <div v-if="data" class="add-action-form__list template-list">
        <template v-if="tab === ACTION_TYPES.OPEN_AI_ACTION">
          <action-template
            v-for="(action, index) in data?.items as SkillActionTemplate[]"
            :key="`action-template-${index}-${action.id}`"
            :action="action"
            @use-template="useTemplate"
          />
        </template>
        <template v-else>
          <action-template
            v-for="(template, index) in data?.items as GlobalTemplateAction[]"
            :key="`action-template-${index}-${template.id}`"
            :action="template.content"
            :template="template"
            @use-template="useTemplate"
          />
        </template>
      </div>
    </template>
  </action-templates-form>
</template>

<style scoped>
.template-list {
  flex: 1 1 auto;
  height: calc(100% + 2px * 2);
  margin: -2px -10px -2px -2px;
  overflow-y: auto;
}
</style>
