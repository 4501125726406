import {
  Token,
  TOKEN_AND,
  TOKEN_FIELD,
  TOKEN_KIND_OPERATORS,
  TOKEN_LPAR,
  TOKEN_OR,
  TOKEN_RPAR,
  TokenKind,
} from "@/components/actions/filter/condition/token"
import {
  comparisonCondition,
  Condition,
  Operator,
} from "@/components/actions/filter/condition/condition"


function tokenToOperator(token: Token): Operator {
  if (token.kind == TOKEN_AND) {
    return "AND"
  }
  if (token.kind == TOKEN_OR) {
    return "OR"
  }
  throw new Error(`token ${token.kind} not set`)
}

/**
 * Parser for condition formula
 */
export class Parser {
  private readonly tokens: Token[]
  // private line: number = 0
  private pos: number = 0
  filterStr: string
  listCondition: {key: string, value: Condition}[]

  constructor(tokens: Token[], filterStr: string, listCondition: {key: string, value: Condition}[] = []) {
    this.tokens = tokens
    this.filterStr = filterStr
    this.listCondition = listCondition
  }

  private match(expected: TokenKind): Token | null {
    return this.matchAll([expected])
  }

  private matchAll(expected: TokenKind[]): Token | null {
    if (this.pos < this.tokens.length) {
      const currentToken = this.tokens[this.pos]
      for (const tokenKind of expected) {
        if (tokenKind.name === currentToken.kind.name) {
          this.pos += 1
          return currentToken
        }
      }
    }

    return null
  }

  private require(tokenKind: TokenKind): Token {
    return this.requireAll([tokenKind])
  }

  private requireAll(expected: TokenKind[]): Token {
    const token = this.matchAll(expected)

    if (token === null) {
      throw new Error(`At position ${this.pos} expected ${expected[0].name}`)
    }

    return token
  }

  private parseValue(): Condition {
    const field = this.match(TOKEN_FIELD)
    if (field) {
      
      const conditionKey = field.text
      const condition = this.listCondition.find(condition => condition.key === conditionKey)
      if (!condition) {
        throw new Error(`Conditions named ${conditionKey} do not exist`)
      }
      return condition.value
    }

    throw new Error(`Field expected for ${this.pos} positions`)
  }

  private parseParentheses(): Condition {
    if (this.match(TOKEN_LPAR) !== null) {
      const node = this.parseFormula()
      this.require(TOKEN_RPAR)
      return node
    } else {
      return this.parseValue()
    }
  }

  public parseFormula(): Condition {
    let leftNode = this.parseParentheses()

    let tokenOperator = this.matchAll(TOKEN_KIND_OPERATORS)

    if (tokenOperator) {
      while (tokenOperator !== null) {
        const right_node = this.parseParentheses()
        const operator = tokenToOperator(tokenOperator)
        leftNode = comparisonCondition(leftNode, operator, right_node)

        tokenOperator = this.matchAll(TOKEN_KIND_OPERATORS)
      }
    }

    return leftNode
  }
}
