<script setup lang="ts">
import { Skill } from "@/types/skill"
import { useRoute } from "vue-router"
import { ROUTE_NAMES } from "@/router/names.ts"

const route = useRoute()
const ws = route.params.ws as string
const skillId = route.params.skillId as string

const props = defineProps<{
  skill: Skill
  type: string
  actionName?: string | null
}>()

const skillEditUrl = `/ws/${ws}/skills/${skillId}/edit`
</script>

<template>
  <div class="skills__tabs">
    <div class="tabs">
      <router-link
        :to="{ name: ROUTE_NAMES.SKILL, params: { ws, skillId } }"
        :class="{ active: props.type === 'skill' }"
        class="tabs__item"
      >
        Actions
      </router-link>

      <router-link
        :to="{ name: 'jobs', params: { ws, skillId } }"
        :class="{ active: props.type === 'runs' }"
        class="tabs__item"
        data-tab-id="skills-runs"
        >Jobs</router-link>
      <router-link :to="skillEditUrl"
        :class="{ active: props.type === 'settings' }"
        class="tabs__item"
        data-tab-id="skills-settings"
        >Settings</router-link>

      <a
        v-if="props.type === 'action'"
        class="tabs__item active"
        data-tab-id="skills-settings"
      >
        {{ props.actionName }}
      </a>

      <!--
          <a href="#" class="tabs__item active">Actions</a>
          <a href="#" class="tabs__item">Runs</a>
          <a href="#" class="tabs__item">Settings</a>
      -->
    </div>

    <div class="skills__controls">
      <slot />
    </div>
  </div>
</template>
