import { SettingsIntegrationHubSpot } from "@/types/hubspot.ts"
import { AxiosInstance } from "axios"
import { notify } from "@kyvg/vue3-notification"
import { Integration, SettingsIntegration } from "@/types/integration.ts"

export class IntegrationRepository {
  constructor(private axios: AxiosInstance) {
  }

  async getSettings(ws: string, integrationId: string): Promise<SettingsIntegrationHubSpot> {
    const res = await this.axios.get(`/ws/${ws}/integrations/api/${integrationId}/settings`)
    return res.data.data
  }

  async setSettings<T extends SettingsIntegration>(data: T, ws: string, integrationId: string): Promise<T> {
    const title = "Integration setting"
    let type = "error"
    let message = "Error saving settings"

    try {
      const result = await this.axios.post(
        `/ws/${ws}/integrations/api/${integrationId}/settings/update`,
        data,
      )

      if (result.data.status === "ok") {
        type = "info"
        message = "The settings are saved"
      }

      return result.data
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }

  }

  async getIntegrations(ws: string): Promise<Integration[]> {
    const res = await this.axios.get(`/ws/${ws}/integrations/api/settings`)
    return res.data.data
  }

  async disconnectIntegrations(ws: string, integrationId: string): Promise<{ status: string }> {
    const res = await this.axios.post(`/ws/${ws}/integrations/api/${integrationId}/settings/disconnect`)
    return res.data
  }

  async getStripeManagementBillingCustomer(ws: string): Promise<{ status: string, data: { url: string } }> {
    const res = await this.axios.post(`/ws/${ws}/integrations/api/stripe/billing/management`)
    return res.data
  }

  async getStripeCustomer(ws: string): Promise<{ status: string, data: { customer: { deleted: boolean } } }> {
    const res = await this.axios.post(`/ws/${ws}/integrations/api/stripe/customer`)
    return res.data
  }
}
