import { closeModal, openModal, promptModal } from "jenesius-vue-modal"
import ConfirmModal from "@/components/modals/ConfirmModal.vue"
import ActionGlobalTemplates from "@/components/modals/ActionGlobalTemplates.vue"
import { container } from "@/services/container.ts"
import { SkillAction } from "@/types/actions.ts"

const CONFIRM_REPLACE = "replace"

export const saveGlobalTemplate = async (actionData: SkillAction) => {
  const templateName = actionData.name
  const resultSaveGlobalPrompt = await confirmSaveGlobalPrompt()

  if (!resultSaveGlobalPrompt) {
    return null
  }

  if (resultSaveGlobalPrompt === CONFIRM_REPLACE) {
    await replaceGlobalPrompt(actionData)
  } else {
    const isPublic = await confirmPublicTemplate(templateName)

    if (isPublic === null) {
      return null
    }

    const result = await container.globalTemplateActionRepository.create(
      actionData,
      isPublic,
    )

    return result
  }
}

export const updateGlobalTemplate = async (actionData: SkillAction, templateActionId: string) => {
  const templateName = actionData.name
  const resultSaveGlobalPrompt = await confirmSaveGlobalPrompt(true)

  if (!resultSaveGlobalPrompt) {
    return null
  }

  if (resultSaveGlobalPrompt === CONFIRM_REPLACE) {
    await replaceGlobalPrompt(actionData)
  } else {
    const isPublic = await confirmPublicTemplate(templateName)

    if (isPublic === null) {
      return null
    }

    const result = await container.globalTemplateActionRepository.update(
      actionData,
      isPublic,
      templateActionId,
    )

    return result
  }
}

export const confirmPublicTemplate = async (templateName: string): Promise<boolean | null> => {
  const resultMakePublicPrompt = await promptModal(ConfirmModal, {
    title: `Do you want to make the Global Action Template ${templateName} available for all tenants?`,
    buttonConfirmationText: "Yes",
    buttonRejectionText: "Keep private",
    classCSSButtonRejection: "secondary-button",
    iconComponent: null,
  })

  if (resultMakePublicPrompt === null) {
    return null
  }

  let isPublic = false
  if (resultMakePublicPrompt) {
    isPublic = true
  }

  return isPublic
}

const confirmSaveGlobalPrompt = async (isUpdate = false) => {
  const textBtnConfirm = isUpdate ? "Update the current template" : "Save as new template"

  const resultSaveGlobalPrompt = await promptModal(ConfirmModal, {
    width: "764px",
    title: "You chose to save this action as a global template. How do you want to proceed?",
    buttonConfirmationText: textBtnConfirm,
    buttonRejectionText: "Cancel",
    classCSSButtonRejection: "secondary-button",
    customButtonText: "Replace existing template",
    customStatus: CONFIRM_REPLACE,
    iconComponent: null,
  })

  return resultSaveGlobalPrompt
}

const replaceGlobalPrompt = async (actionData: SkillAction) => {
  const actionGlobalTemplates = await openModal(ActionGlobalTemplates, {})
  actionGlobalTemplates.on("confirm", async (data) => {
    await container.globalTemplateActionRepository.update(
      actionData,
      data.isPublic,
      data.template.id,
    )

    await closeModal()
  })
}