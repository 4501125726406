<script setup lang="ts">
import { onMounted, ref } from "vue"
import { openModal } from "jenesius-vue-modal"
import { useRouter } from "vue-router"
import { container } from "@/services/container"
import BaseNav from "@/components/BaseNav.vue"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import { userStore } from "@/store/user"
import { SkillTemplate } from "@/types/skill"
import { Paginator } from "@/types/api"
import CTable from "@/components/elements/CTable.vue"
import CButton from "@/components/elements/CButton.vue"

const store = userStore()
const router = useRouter()

const navItems = [
  {
    link: null,
    label: "Global skill templates",
  },
]

const page = ref(1)
let perPage = ref(20)

const searchText = ref("")

const data = ref<Paginator<SkillTemplate> | null>(null)

const templatesColumns = [
  { key: "name", label: "Global template name" },
  { key: "description", label: "Description" },
  { key: "edit", label: "" },
  { key: "delete", label: "" },
]

const fetchTemplates = async () => {
  if (!store.getUserProfile.admin) {
    //TODO: remove hard reload
    window.location.href = "/"
  }

  data.value = await container.skillAdminRepository.globalTemplates(
    searchText.value,
    page.value,
    perPage.value,
  )
}

const viewTemplate = (item: SkillTemplate) => {
  router.push({name: "global-template", params: {templateId: item.id}})
}

const deleteGlobalTemplate = async (item: SkillTemplate) => {
  const title = `Delete the template «${item.name}»?`
  const modal = await openModal(DeleteModal, { title: title })
  modal.on("confirm", async () => {
    if (item.id) {
      await container.skillAdminRepository.deleteGlobalTemplate(item.id)
    }
    await fetchTemplates()
  })
}

onMounted(async () => {
  await fetchTemplates()
})
</script>

<template>
  <base-nav :items="navItems" icon="settings" />
  <h2>Global skill templates</h2>

  <div v-if="data">
    <c-table :items="data.items" :columns="templatesColumns">
      <template #cell(edit)="{item}">
        <c-button
          icon="settings"
          @click="viewTemplate(item as SkillTemplate)"
        />
      </template>
      <template #cell(delete)="{item}">
        <c-button
          icon="delete"
          @click="deleteGlobalTemplate(item as SkillTemplate)"
        />
      </template>
    </c-table>
  </div>
</template>

<style>
.price-buttons {
  text-align: right;
  margin-bottom: 10px;
}
</style>
