<script setup lang="ts">
const props = defineProps<{
  label: string
  id: string
  name: string
  modelValue: string
  options: [] | IterableIterator<[number, string]>
  error?: string,
  required?: boolean,
  rows?: number,
}>()

const emit = defineEmits(["update:modelValue"])
</script>

<template>
  <div class="action-param__row">
    <div class="action-param__col action-param__col--left">
      <div class="action-param__col-name text-style">{{ props.label }}</div>
    </div>
    <div class="action-param__col action-param__col--right">
      <div class="action-param__grid">
        <label
          v-for="[key, type] of props.options"
          :key="key"
          class="action-param__grid-item action-param__grid-item--inline custom-radio"
        >
          <input
            :id="props.id + '-' + type"
            class="custom-radio__input"
            type="radio"
            :checked="type === props.modelValue"
            :name="props.name"
            :required="props.required"
            :value="props.modelValue"
            @input="emit('update:modelValue', type)"
          >
          <span
            class="custom-radio__text"
            style="text-transform: capitalize"
          >
            {{ type }}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>
