import { Skill, SkillFormData } from "@/types/skill"
import { SkillActionFields } from "@/types/actions"
import { Paginator, ResponseApi, ResponseApiMessage } from "@/types/api"
import { AxiosInstance } from "axios"
import { notify } from "@kyvg/vue3-notification"

export class SkillRepository {
  constructor(private axios: AxiosInstance) {
  }

  async create(ws: string, skill: SkillFormData): Promise<Skill> {
    const res = await this.axios.post(`/ws/${ws}/skills/api/create`, skill)
    return res.data.data
  }

  async edit(ws: string, skill: SkillFormData): Promise<Skill> {
    const res = await this.axios.post(`/ws/${ws}/skills/api/${skill.id}/edit`, skill)
    return res.data.data
  }

  async get(ws: string, skillId: string): Promise<Skill> {
    const res = await this.axios.get(`/ws/${ws}/skills/api/${skillId}`)
    return res.data.data
  }

  async list(
    ws: string,
    page = 1,
    perPage = 20,
    withLastJob = false,
  ): Promise<Paginator<Skill>> {
    const params: Record<string, string> = {
      page: page.toString(),
      per_page: perPage.toString(),
      with_last_job: withLastJob.toString(),
    }

    const query = (new URLSearchParams(params)).toString()
    const res = await this.axios.get(`/ws/${ws}/skills/api/list?${query}`)

    return res.data.data
  }

  async search(
    ws: string,
    search: string,
    page = 1,
    perPage = 20,
    withLastJob = false,
  ): Promise<Paginator<Skill>> {
    const params: Record<string, string> = {
      search: search,
      page: page.toString(),
      per_page: perPage.toString(),
      with_last_job: withLastJob.toString(),
    }

    const query = (new URLSearchParams(params)).toString()
    const res = await this.axios.get(`/ws/${ws}/skills/api/search?${query}`)

    return res.data.data
  }

  async getGlobal(ws: string, skillId: string): Promise<Skill> {
    const res = await this.axios.get(`/ws/${ws}/skills/api/${skillId}/global`)
    return res.data.data
  }

  async listGlobalTemplates(
    ws: string,
    page = 1,
    perPage = 20
  ): Promise<Paginator<Skill>> {
    const params: Record<string, string> = {
      page: page.toString(),
      per_page: perPage.toString(),
    }

    const query = (new URLSearchParams(params)).toString()
    const res = await this.axios.get(`/ws/${ws}/skills/api/global_list?${query}`)

    return res.data.data
  }

  async searchGlobalTemplates(
    ws: string,
    search: string,
    page = 1,
    perPage = 20
  ): Promise<Paginator<Skill>> {
    const params: Record<string, string> = {
      search: search,
      page: page.toString(),
      per_page: perPage.toString(),
    }

    const query = (new URLSearchParams(params)).toString()
    const res = await this.axios.get(`/ws/${ws}/skills/api/global_search?${query}`)

    return res.data.data
  }


  async setPublication(
    ws: string,
    skillId: string,
    published: boolean,
  ): Promise<void> {
    const title = "Publication skill"
    let type = "error"
    let message = "Publication error"

    try {
      const data = {
        published: published,
      }
      await this.axios.post(`/ws/${ws}/skills/api/${skillId}/set_publication`, data)

      message = "Skill is not published"
      type = 'info'
      if (published) {
        message = "Skill is published"
      }
    } finally {
      notify({
        type: type,
        title: title,
        text: message,
      })
    }
  }


  async fields(ws: string, skillId: string): Promise<SkillActionFields> {
    const res = await this.axios.get(`/ws/${ws}/skills/api/${skillId}/fields`)
    return res.data.data
  }

  async deleteSkill(
    ws: string,
    skillId: string,
  ): Promise<ResponseApi<ResponseApiMessage>> {
    const result = await this.axios.post(`/ws/${ws}/skills/api/${skillId}/delete`)

    return result.data
  }
}
