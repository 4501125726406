<script setup lang="ts">
import { computed, onMounted } from "vue"
import InputRadio from "@/components/actions/elements/InputRadio.vue"
import InputParameter from "@/components/actions/elements/InputParameter.vue"
import { RESPONSE_TYPE, SkillAction, SkillActionField } from "@/types/actions.ts"
import ActionOpenAIParameter from "@/components/actions/openAI/ActionOpenAIParameter.vue"
import CInputTextMention from "@/components/elements/CInputTextMention.vue"
import InputMultiselect from "@/components/actions/elements/InputMultiselect.vue"
import LabelForm from "@/components/actions/elements/LabelForm.vue"

const props = defineProps<{ actionData: SkillAction, availableFields: SkillActionField[] }>()

const emit = defineEmits(["formEvent"])

const modelsGPT = [
  "gpt-3.5-turbo-0613",
  "gpt-4-1106-preview",
  "gpt-4-turbo-2024-04-09",
]

const responseTypeOptions = [
  { label: RESPONSE_TYPE.TEXT, value: RESPONSE_TYPE.TEXT },
  { label: RESPONSE_TYPE.STRUCTURED_DATA, value: RESPONSE_TYPE.STRUCTURED_DATA },
]


onMounted(() => {
  if (props.actionData.params.response_json) {
    props.actionData.ui.response_type = RESPONSE_TYPE.STRUCTURED_DATA
  }
})

const availableFieldsMentionable = computed(() => {
  return props.availableFields.map(field => {
    return {
      label: field.name,
      value: field.type,
    }
  })
})


const deleteActionOutputParameter = (index: number) => {
  emit("formEvent", "delete-action-output-parameter", index)
}
const addActionParameter = () => {
  emit("formEvent", "add-action-parameter")
}
const changeResponseType = () => {
  emit("formEvent", "change-response-type")
}

</script>

<template>
  <div class="action-form settings-block">
    <label-form
      label="System message"
      description="Description"
    >
      <c-input-text-mention
        v-model="props.actionData.params.system"
        label="system message"
        description="Description"
        placeholder="system message"
        :rows="3"
        :fields-mentionable="availableFieldsMentionable"
      />
    </label-form>

    <label-form
      label="Model"
      description="Description"
    >
      <input-multiselect
        v-model="props.actionData.params.model"
        :options="modelsGPT"
        :preselect-first="true"
        placeholder="Model"
      />
    </label-form>

    <input-radio
        v-model="props.actionData.ui.response_type"
        label="RESPONSE TYPE"
        description="Description"
        placeholder="RESPONSE TYPE"
        type="Default"
        name="response_type"
        :required="true"
        :options="responseTypeOptions"
        @update:modelValue="changeResponseType"
    />

    <label-form
      label="prompt"
      description="Description"
    >
      <c-input-text-mention
        v-model="props.actionData.params.prompt"
        placeholder="message #field_name"
        :required="true"
        :rows="3"
        :fields-mentionable="availableFieldsMentionable"
      />
    </label-form>

    <label-form
      v-if="props.actionData.ui.response_type === RESPONSE_TYPE.STRUCTURED_DATA"
      label="Description of parameters"
      description=""
    >
      <c-input-text-mention
        v-model="props.actionData.ui.description_parameters"
        placeholder=""
        :required="true"
        :rows="3"
        :fields-mentionable="availableFieldsMentionable"
      />
    </label-form>

    <div
        v-if="props.actionData.ui.response_type === 'Text'"
        class="param-list"
    >
      <div class="param-list__item action-param">
        <div class="action-param__title sub-title">
          <span>ACTION output Parameter</span>
        </div>
        <input-parameter
            v-model="props.actionData.ui.output_field_name"
            placeholder="parameter name"
            label="Output Field name"
            name="field_name_0"
            :required="true"
        />
      </div>
    </div>

    <div
        v-else-if="props.actionData.ui.response_type === RESPONSE_TYPE.STRUCTURED_DATA"
        class="param-list"
    >
      <action-open-a-i-parameter
          v-for="[index, parameter] of props.actionData.ui.parameters.entries()"
          :index="index"
          :parameter="parameter"
          :length-list="props.actionData.ui.parameters.length - 1"
          :fields-mentionable="availableFieldsMentionable"
          @delete="deleteActionOutputParameter"
          @add="addActionParameter"
      />
    </div>
  </div>
</template>
